import {MetricService} from '../../services/metric.service';

declare const google: any;

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ElementRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import {IContacts, IPaymentMethod} from '../../types/Entities';
import { prop, path } from 'ramda';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'gpay-button',
  templateUrl: './gpay_button.component.html',
  styleUrls: ['./gpay_button.component.less'],
})
export class GpayButtonComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input('data') data: IPaymentMethod;
  @Input() disabled = false;
  @Input() visitors: {[uuid: string]: any};
  @Input() isGarage = false;
  @ViewChild('container', { static: false }) container: ElementRef;
  public supported = false;
  public acsUrl: string;
  public acsMethod: string;
  private paymentMethodData;
  private paymentDetails: any = {};
  private paymentOptions: any = {};
  private session: PaymentRequest;

  constructor(
    private cart: CartService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private metric: MetricService) {}

  ngAfterViewInit(): void {
    try {
      this.makePaymentRequest();
      const pr = new PaymentRequest([this.paymentMethodData], this.paymentDetails, this.paymentOptions);
      pr.canMakePayment().then((s) => {
        if (true) {
          this.supported = true;

          const paymentsClient: google.payments.api.PaymentsClient = new google.payments.api.PaymentsClient({
            environment: this.paymentMethodData.data.environment
          });
          const button = paymentsClient.createButton({
            onClick: () => this.buttonClick(),
            buttonType: 'long',
            buttonColor: this.isGarage ? 'black' : 'white'
          });
          this.renderer.appendChild(this.container.nativeElement, button);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    delete this.session;
  }

  ngOnChanges(changes: SimpleChanges): void {}

  private makePaymentRequest(): any {
    if (this.data) {
      const settings: {[key: string]: any} = prop('params', this.data.ext_provider_data);

      const currencyCode: string = path(['currency', 'alias'], this.data.ext_provider_data)
              || this.cart.cart_items.map(({ currency }) => (currency || '').toLocaleUpperCase()).filter((x) => /\S/.test(x))[0];

      const googlePaymentsConfiguration = {
        environment: 'TEST',
        apiVersion: 2,
        apiVersionMinor: 0,
        ...settings.options,
        merchantInfo: {
          // A merchant ID is available after approval by Google.
          merchantId: settings.merchant.id || undefined,
          merchantName: settings.merchant.name,
        },
        transactionInfo: {
          countryCode: 'RU',
          totalPriceStatus: 'FINAL',
          totalPrice: (this.cart.total_cost * 0.01).toFixed(2),
          currencyCode: currencyCode,
        },
      };

      this.paymentMethodData = {
        supportedMethods: 'https://google.com/pay',
        data: googlePaymentsConfiguration,
      };

      this.paymentDetails = {
        total: {
          label: 'LASTICK.RU',
          amount: {
            currency: currencyCode,
            value: (this.cart.total_cost * 0.01).toFixed(2),
          },
        }
      };

      if (!this.cart.contacts.phone || this.cart.contacts.phone === '') {
        this.paymentOptions.requestPayerPhone = true;
      }
      if (!this.cart.contacts.email || this.cart.contacts.email === '') {
        this.paymentOptions.requestPayerEmail = true;
      }
    } else {
      return undefined;
    }
  }


  public buttonClick() {
    if (this.disabled) return;

    this.makePaymentRequest();

    try {
      console.log('try payment session', {
        paymentMethodData: [this.paymentMethodData],
        paymentDetails: this.paymentDetails,
        paymentOptions: this.paymentOptions,
      });

      this.session = new PaymentRequest(
        [this.paymentMethodData],
        this.paymentDetails,
        this.paymentOptions
      );
      console.log('payment request', this.session);


      console.log('try session show');
      this.session
        .show()
        .then((response) => {
          console.log('session.show', { response });

          if (response.payerPhone) {
            this.cart.contacts.phone = response.payerPhone;
          }
          if (response.payerEmail) {
            this.cart.contacts.email = response.payerEmail;
          }

          const paymentToken = JSON.parse(
            path(['details', 'paymentMethodData', 'tokenizationData', 'token'], response) || null
          );
          console.log(`PAYMENT TOKEN = `, paymentToken);

          const checkoutData: IContacts = {
            contacts: this.cart.contacts,
            payment_method: this.data.uuid,
            payment_token: paymentToken,
          };
          if (this.visitors) {
            checkoutData.visitors = this.visitors;
          }

          this.cart
            .checkout(checkoutData)
            .then((success) => {
              const complete_state = success && success.order && success.payment ? 'success' : 'fail';
              return Promise.all([
                Promise.resolve(success),
                response.complete(complete_state)
              ]);
            })
            .then(([ { payment } ]) => {
              const redirectType: string = path(['redirect', 'type'], payment);
              if ('acs' === redirectType) {
                this.acsUrl = path(['redirect', 'acsUrl'], payment);
                this.acsMethod = path(['redirect', 'acsMethod'], payment);
                const currency = this.cart.getCurrency().toUpperCase();
                this.metric.trackFb('InitiateCheckout', {
                    value: this.cart.total_cost / 100, currency: currency === 'RUR' ? 'RUB' : currency
                });
                const form = document.getElementById('acsForm') as HTMLFormElement;
                if (form) {
                  const acsData = path(['redirect', 'acsParam'], payment);
                  for (const acsField of Object.keys(acsData)) {
                    form.innerHTML += `<input type="hidden" name="${acsField}" value="${acsData[acsField]}" />`;
                  }

                  // нужно узнать как правильно дождаться окончания изменений в dom
                  setTimeout(() => form.submit(), 1500);
                }
              } else { // redirectType === url
                this.cart.moveToPay(payment.redirect.value);
              }
            })
            .catch((e) => {
              console.error('PAYMENT REQUEST SYNC CART FAILED:', e);
              response.complete('fail');
            });
        })
        .catch((err) => {
          console.error('PAYMENT REQUEST REJECTED:', err);
        });

    } catch (err) {
      console.log('catch payment session', { error: err });
      try {
        if (this.session) {
          this.session.abort();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
}
