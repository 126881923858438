import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less'],
})
export class LoaderComponent implements OnInit {
  show: boolean = false;
  subsCounter = 0;

  constructor(public global: GlobalService) {}

  ngOnInit() {
    Promise.resolve(null).then(() => {
      this.global.showHideLoader.subscribe((e: boolean) => {
        if (e) {
          this.subsCounter++;
        } else {
          if (this.subsCounter !== 0) {
            this.subsCounter--;
          }
        }

        if (!e && this.subsCounter > 0) {
          return;
        }
        this.show = e;
      });
    });
  }
}
