import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CartComponent} from '../../components/cart/cart.component';
import {CartPopupContainerComponent} from '../../components/cart-popup-container/cart-popup-container.component';
import {CartPopupComponent} from '../../components/cart-popup/cart-popup.component';
import {CartService} from '../../services/cart.service';
import {ButtonCartComponent} from '../../components/button-cart/button-cart.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {ApplepayButtonComponent} from '../../components/payment_method/applepay_button.component';
import {GpayButtonComponent} from '../../components/payment_method/gpay_button.component';
import {FormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';

const declarations = [
  CartComponent,
  CartPopupContainerComponent,
  CartPopupComponent,
  ButtonCartComponent,
  ApplepayButtonComponent,
  GpayButtonComponent
];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule, RouterModule, TranslateModule, SharedModule, FormsModule, NgxMaskModule.forRoot()],
  entryComponents: [CartPopupComponent],
})
export class CartModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CartModule,
      providers: [CartService],
    };
  }
}
