import { Injectable } from '@angular/core';
import { ITag } from '../types/ITag';
import { format } from 'date-fns';
import { LoggerService } from './logger.service';
import { ReplaySubject } from 'rxjs';
import { IConditions } from '../types/IConditions';

export interface IStateData {
  name?: 'index' | 'search' | 'tag';
  tags?: Array<ITag>;
  activeTag?: ITag;
  conditions?: IConditions;
  searchChanged?: boolean;
  focusSearch?: boolean;
  scroll?: number;
  dt?: string;
  data?: any;
  page?: number;
}

export interface IState {
  current?: IStateData;
  prev?: IStateData;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  currentKey: any;
  public readonly sections = {};
  public readonly states = {};
  public readonly statesStorage = {};
  public readonly initialState = {
    current: {
      name: 'index',
      tags: [],
      activeTag: {},
      conditions: {
        _lookup: '',
        tags: [],
      },
    },
    prev: { name: 'index', activeTag: {} },
  };
  public _state: IState;
  search: boolean;
  dateFormat = 'YYYY-MM-DD';
  conditions: any;
  tag: string;
  stateUpdate$ = new ReplaySubject<IState>();

  constructor(private logger: LoggerService) {}

  getActiveTag(key?: string): ITag | any {
    if (!this.states[key || this.currentKey]) {
      return {};
    }
    return this.states[key || this.currentKey].current.activeTag || {};
    // return this._state.current.tags.find((t) => t.uuid ===this._state.current.conditions.tags[0]) || {};
  }

  init(key, tags?: Array<ITag>, state?: IStateData) {
    if (this.states[key]) {
      this.currentKey = key;
      this._state = this.states[key];
      return;
    }
    const initialState: IState = {
      current: {
        name: 'index',
        dt: format(new Date(), this.dateFormat),
        tags: tags,
        activeTag: tags[0],
        conditions: {
          _lookup: '',
          tags: [],
        },
      },
      prev: { name: 'index', activeTag: tags[0] },
    };
    this.currentKey = key;
    this.states[key] = this.states[key] || state || initialState;
    this._state = this.states[key];
    this.logger.l('nav init', this.states);
  }

  setState(newState: IStateData, changePrev = true) {
    const _state = this.states[this.currentKey];
    if (changePrev) {
      _state.prev = { ..._state.current };
    }
    _state.current = { ..._state.current, ...newState };
    this.statesStorage[_state.current.name] = _state.current;
    this.stateUpdate$.next(_state);
    this.logger.l('nav setState', _state);
    this.logger.l('nav setState storage', this.statesStorage);
  }

  getState(key?: string): IState {
    return this.states[key || this.currentKey] || this.initialState;
  }

  setTag(tag: ITag) {
    const newState: IStateData = {
      // tags: [...this._state.current.tags],
      activeTag: tag,
      conditions: { ...this._state.current.conditions },
    };
    if (tag) {
      const existed = newState.conditions.tags.findIndex(uuid => uuid === tag.uuid);
      switch (tag.type) {
        case 'system':
        case '_api':
        case '_custom':
          // newState.tags = [tag];
          if (tag.type === 'system') {
            newState.conditions.tags = newState.tags.map(t => t.uuid);
          }
          break;
        default:
          if (existed < 0) {
            newState.conditions.tags.push(tag.uuid);
          } else {
            newState.conditions.tags.splice(existed, 1);
          }
          // newState.conditions.tags = newState.tags.map((t) => t.uuid);
          break;
      }
      newState.name = tag._index ? 'index' : 'tag';
    } else {
      newState.conditions.tags = [];
    }
    this.setState(newState);
  }

  setTags(tags: ITag[]) {}

  searchToggle(show: boolean, conditions?: IConditions) {
    let newState: IStateData = {};
    const currentState = this._state.current;

    switch (show) {
      case true:
        newState.name = 'search';
        newState.focusSearch = true;
        newState.conditions = { ...this._state.current.conditions, tags: [] };
        break;
      case false:
        newState = { ...this._state.prev, searchChanged: currentState.searchChanged };
        newState.conditions._lookup = '';
        break;
    }
    this.setState(newState);
  }

  submit(text, tags?: ITag[]) {
    let newState: IStateData = {
      searchChanged: true,
      conditions: { ...this._state.current.conditions, _lookup: text },
    };
    this.setState(newState, false);
  }

  endSubmit(chaged) {
    // this.searchSubmitting = false;
    this.setState({ searchChanged: chaged }, false);
  }
}
