import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CartService } from '../../../services/cart.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { TCurrency } from '../../../types/Entities';
import {MetricService} from '../../../services/metric.service';

@Component({
  selector: 'app-hall-schema-sidebar',
  template: `
    <div class="cart-items">
      <div class="sidebar-bg" *ngIf="!items || items.length === 0">
        <div class="hall-sidebar__empty">
          <img src="/assets/img/ticket-placeholder@2x.png" /><br />
          <span>{{ 'HallSchema.SELECT_PLACE_HINT' | translate }}</span>
        </div>
      </div>
      <perfect-scrollbar>
        <app-hall-schema-seatinfo
          *ngFor="let item of items"
          [item]="item"
        ></app-hall-schema-seatinfo>
      </perfect-scrollbar>
    </div>
    <div class="cart-summary-wrapper">
      <div class="cart-summary">
        <div class="discount" *ngIf="discountAmount > 0">
          <span>{{ 'Buy.DiscountValue' | translate }}</span> {{ discountAmount }}
        </div>
        <div class="discount" *ngIf="serviceFee > 0">
          <span>{{ 'Confirm.Service' | translate }}</span>
          <span class="price-currency">{{ serviceFee | money: currency }}</span>
        </div>
        <div class="total">
          <span>{{ 'Confirm.Total2' | translate }}</span
          >&nbsp;<span class="price-currency">{{ totalAmount | money: currency }}</span>
        </div>
      </div>
    </div>
    <div class="cart-button-holder">
      <button
        class="btn btn_sz-4 btn_full-width btn_ta-c"
        (click)="goToConfirm()"
        [disabled]="cartService.total_items === 0"
      >
        <span class="regular-text">{{ 'HallSchema.CHECKOUT' | translate }}</span>
        <span class="variable-text" *ngIf="totalAmount === 0">{{
          'HallSchema.CHECKOUT' | translate
        }}</span>
        <span
          class="variable-text"
          *ngIf="totalAmount !== 0"
          [innerHTML]="
            'HallSchema.CONTINUE_TO_PAYMENT_WITH'
              | translate
                : {
                    ticketsCount: items ? items.length : 0,
                    amount:
                      '<span class=\\'price-currency\\'>' +
                      (totalAmount | money: currency) +
                      '</span>'
                  }
          "
        >
        </span>
      </button>
    </div>
    <div class="cart-button-holder">
      <button class="btn btn_sz-4 btn_full-width btn_ta-c" (click)="goToEvents()">
        {{ 'Order.ContinueShopping' | translate }}
      </button>
    </div>
  `,
  styleUrls: ['./sidebar.component.less'],
})
export class HallSchemaSidebarComponent implements OnInit, OnDestroy, OnChanges {
  @Input('schedule') schedule;

  public discountAmount = 0;
  public totalAmount = 0;
  public eDt: any;
  public items = [];
  public serviceFee = 0;
  public currency: TCurrency;
  private subscriptions: Array<Subscription>;

  constructor(
    public cartService: CartService,
    private _elemref: ElementRef,
    private router: Router,
    public global: GlobalService,
    private metric: MetricService
  ) {
    this.subscriptions = [];
    this.subscriptions.push(this.cartService.cartOutput$.subscribe(e => this.onCartChange(e)));
  }

  public goToConfirm() {
    const currency = this.cartService.getCurrency().toUpperCase();
    this.metric.trackFb('AddToCart', {
      value: this.cartService.total_cost / 100,
      currency: currency === 'RUR' ? 'RUB' : currency
    });
    this.router.navigate(['/order/activate']);
  }

  public goToEvents() {
    console.log('time to nav---------');
    this.router.navigate([this.global.routes.events || '/events']);
  }

  private onCartChange(e) {
    // this.totalAmount = this.cartService.total_cost;
    // this.totalAmount = this.cartService.event_total_cost;
    // this.items = this.cartService.cart_items;
    this.serviceFee = this.cartService.service_fee;
    // this.discountAmount = e.discount;
    // console.log('e.items', e.items);

    this.items = this.cartService.getEventItems(this.schedule.uuid);
    this.totalAmount = this.cartService.event_total_cost;
    this.currency = this.cartService.getCurrency();
  }

  ngOnInit() {
    this.eDt = this.global.currentEvent;
    if (this.schedule) {
      this.items = this.cartService.getEventItems(this.schedule.uuid);
      this.totalAmount = this.cartService.event_total_cost;
      this.currency = this.cartService.getCurrency();
    }
    // this.cartService.reloadCart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.schedule && this.schedule) {
      this.items = this.cartService.getEventItems(this.schedule.uuid);
      this.totalAmount = this.cartService.event_total_cost;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }
}
