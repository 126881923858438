import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TabsTabComponent } from './tabs-tab/tabs-tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.styl'],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabsTabComponent) tabs: QueryList<TabsTabComponent>;
  @Input('gap') gap;
  @Input('theme') theme;



  constructor() {}

  ngAfterContentInit() {
    console.log('TABS')
    const activeTabs = this.tabs.filter(tab => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab) {
    // deactivate all tabs
    this.tabs.toArray().forEach(_tab => (_tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
  }
}
