import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Observable, Subscription } from 'rxjs';
import { ITag } from '../../types/ITag';
import { TransportService } from '../../services/transport.service';

@Component({
  selector: 'app-nav-wrapper',
  templateUrl: './nav-wrapper.component.html',
  styleUrls: ['./nav-wrapper.component.less'],
})
export class NavWrapperComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id;
  @Input() tags;
  @Input() tagClick$: Observable<any>;
  @Input() searchToggle$: Observable<any>;
  @Input() searchSubmit$: Observable<any>;
  @Input() endSearchSubmit$: Observable<any>;
  @Input() searchChange$: Observable<any>;

  subscriptions: Subscription[] = [];

  constructor(private nav: NavService, private api: TransportService) {}

  ngOnInit() {
    if (this.tags) {
      this.init();
    }
    this.subscriptions.push(
      this.tagClick$.subscribe((tag: ITag) => {
        this.onTagClick(tag);
      }),
      this.searchToggle$.subscribe((show: boolean) => {
        this.onSearchToggle(show);
      }),
      this.searchSubmit$.subscribe(text => {
        this.onSearchSubmit(text);
      }),
      this.endSearchSubmit$.subscribe(text => {
        this.endSearchSubmit(text);
      }),
      this.searchChange$.subscribe(text => {
        this.onSearchChange(text);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tags && this.tags) {
      this.init();
    }
  }

  init() {
    const stateKey = this.id || location.pathname;
    this.nav.init(stateKey, this.tags);
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  onTagClick(tag) {
    this.nav.setTag(tag);
  }

  onSearchToggle(show, conditions?) {
    this.nav.searchToggle(show, conditions);
  }

  onSearchSubmit(text) {
    this.nav.submit(text);
  }

  onSearchChange(text) {
    this.onSearchSubmit(text);
  }

  endSearchSubmit(changed) {
    this.nav.endSubmit(changed);
  }
}
