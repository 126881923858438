import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExcursionPopupService } from 'src/app/services/excursion-popup.service';
import { GlobalService } from 'src/app/services/global.service';
import { IMultilangString } from '../../types/Entities';
import { InfoService } from '../../services/info.service';

interface IItem {
  id: any;
  count?: number;
  max_count: number;
  value?: number;
  date?: string;
  time?: string;
  category: IMultilangString;
  title?: IMultilangString;
  description?: IMultilangString;
  title_value?: string;
  disabled?: boolean;
  priceValueUuid?: string;
}

@Component({
  selector: 'app-plus-minus-list',
  templateUrl: './plus-minus-list.component.html',
  styleUrls: ['./plus-minus-list.component.less'],
})
export class PlusMinusListComponent implements OnInit, AfterContentInit {
  @Input('items') items: Array<IItem>;
  @Input('title') title: string;
  @Input('description') description: string;
  @Input() showInfo = false;
  @Input() infoMessages;
  @Input() soldOut;
  @Input() quantity = 0;
  @Input() choosedItems = 0;
  @Input() selectedLimits = {};
  @Input() showItemsQuantity = true;
  isGarage = false;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() increment: EventEmitter<any> = new EventEmitter();
  @Output() decrement: EventEmitter<any> = new EventEmitter();

  sum = 0;
  choosed: Array<IItem> = [];
  choosedCount = 0;


  constructor(
    public excursionPopup: ExcursionPopupService,
    public global: GlobalService,
    private info: InfoService) {}

  ngOnInit() {

    console.log('INIT', this.showItemsQuantity)

    this.choosedCount = 0;

    // this.items.forEach(item => {
    //   // this.choosedCount += item.count;
    //   console.log('itm', item)
    // })

    this.calcValues();
    this.showModal();

    this.isGarage = this.global.settings.name.ru === 'Garage';

    console.log('settings', this.global.settings)

  }

  calcValues() {
    let result = 0;
    const choosed = [];

    // console.log('WARN', this.items)
    if (!this.items) {
      return;
    }
    console.log('WARN', this.items)

    this.items.map(_item => {
      const count = _item.count || 0;

      if (count > 0) {
        choosed.push(_item);
        this.choosedCount += count;
      }

      console.log('ITM', _item)

      result += _item.value * count;
    });
    this.sum = result;
    this.choosed = choosed;
  }

  showModal() {
    if (this.showInfo) {
      console.log('SHOW INFO up', this.infoMessages);
      this.excursionPopup.show({ id: 'excursion', info: this.infoMessages });
      const Sub = this.excursionPopup.showHide$.subscribe(v => {
        if (v && !v.show) {
          Sub.unsubscribe();
        }
      });
    }
  }

  onChange(type, changedItem) {
    // console.log('CHANGE', this.items, this.choosed);
    if (this.selectedLimits[changedItem.id].quantity - this.selectedLimits[changedItem.id].selected === 0 && type === 1) {
      this.info.showError('Не осталось доступных билетов')
      return;
    }
    // this.quantity--;

    const foundIndex = this.items.findIndex(
      _item =>
        this.getCmpKey(_item.id, _item.priceValueUuid) ===
        this.getCmpKey(changedItem.id, changedItem.priceValueUuid)
    );
    let choosedFoundIndex = -1;
    if (this.choosed) {
      choosedFoundIndex = this.choosed.findIndex(
        _item =>
          this.getCmpKey(_item.id, _item.priceValueUuid) ===
          this.getCmpKey(changedItem.id, changedItem.priceValueUuid)
      );
    }
    const item = this.items[foundIndex];
    if (item.disabled) {
      return;
    }
    const currCount = item.count || 0;
    const resultCount =
      type === 1
        ? currCount >= item.max_count
          ? currCount
          : currCount + 1
        : currCount !== 0
        ? currCount - 1
        : 0;

    // item.count = resultCount;
    this.items[foundIndex].count = resultCount;
    this.sum = this.sum - (item.value * currCount - item.value * resultCount);
    this.choosedCount = this.choosedCount - (currCount - resultCount);

    if (choosedFoundIndex >= 0) {
      if (resultCount > 0) {
        this.choosed[choosedFoundIndex].count = resultCount;
      } else {
        this.choosed.splice(choosedFoundIndex, 1);
      }
    } else if (resultCount !== 0) {
      this.choosed.push(item);
    }

    const data = {
      sum: this.sum,
      choosedCount: this.choosedCount,
      choosed: this.choosed,
      item,
      items: this.items,
    };

    this.change.emit(data);

    //    if (type === 1 && item.max_count !== currCount) {
    if (type === 1) {
      this.increment.emit(data);
    } else if (type === 0 && currCount !== 0) {
      this.decrement.emit(data);
    }
  }

  private getCmpKey = (id, priceValueUuid = '') => `${id}_${priceValueUuid}`;

  ngAfterContentInit(): void {

    console.log('CONTENT READY', this.items)

  }
}
