import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BuyComponent } from './pages/buy/buy.component';
import { GiftActivateComponent } from './pages/gift-activate/gift-activate.component';
import { SpotComponent } from './pages/spot/spot.component';
import { CardActivateComponent } from './pages/card-activate/card-activate.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { GiftComponent } from './pages/gift/gift.component';
import { EventTableComponent } from './pages/event-table/event-table.component';
import { EventSchedulesComponent } from './pages/event-schedules/event-schedules.component';
import { OrderConfirmComponent } from './pages/order-confirm/order-confirm.component';
import { OrderConfirmActivateComponent } from './pages/order-confirm-activate/order-confirm-activate.component';
import { OrderCertStatusComponent } from './pages/order-cert-status/order-cert-status.component';
import { OrderStatusComponent } from './pages/order-status/order-status.component';

const routes: Routes = [
  { path: '', component: BuyComponent },
  { path: 'spot/:spot_uuid', loadChildren: './pages/spot/spot.module#SpotModule' },
  { path: 'gift/activate', component: GiftActivateComponent },
  { path: 'card/activate', component: CardActivateComponent },
  { path: 'events', loadChildren: './pages/events/events.module#EventsModule' },
  {
    path: 'events/:event_uuid/schedules/:schedule_uuid',
    loadChildren: './pages/event-map/event-map.module#EventMapModule',
  },
  { path: 'events/:event_uuid', component: EventTableComponent },
  { path: 'movies', loadChildren: './pages/movies/movies.module#MoviesModule' },
  { path: 'events/:event_uuid/schedules', component: EventSchedulesComponent },
  { path: 'order/activate', component: OrderConfirmActivateComponent },
  { path: 'order/confirm', component: OrderConfirmComponent },
  { path: 'gift', component: GiftComponent },
  {
    path: '',
    component: LayoutsComponent,
    children: [
      { path: 'result/:order_uuid/cert/:lang', component: OrderCertStatusComponent },
      { path: 'result/:order_uuid/:lang', component: OrderStatusComponent },
    ],
  },
  // otherwise redirect to home
  { path: 'rescert', component: OrderCertStatusComponent },
  { path: 'resord', component: OrderStatusComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
