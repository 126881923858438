import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { CartService } from '../../services/cart.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-popup',
  templateUrl: './cart-popup.component.html',
  styleUrls: ['./cart-popup.component.less'],
})
export class CartPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  public show = false;
  public currentPage: string;
  public mobileWidth = 650;
  public mobileHeight = 480;
  public isMobile: boolean;

  constructor(
    public global: GlobalService,
    public cart: CartService,
    private router: Router,
    private el: ElementRef,
    public cartService: CartService,
  ) {
    switch (this.router.url.replace(/\/?\?.*/, '')) {
      case '/':
        this.currentPage = 'index';
        break;
      case '/gift/activate':
        this.currentPage = 'giftActivate';
        break;
      case '/events':
        this.currentPage = 'events';
        break;
      case '/gift':
        this.currentPage = 'gift';
        break;
      case '/order/confirm':
        this.currentPage = 'orderConfirm';
        break;
      case '/order/activate':
        this.currentPage = 'orderActivate';
        break;
    }
  }

  ngOnInit() {
    this.isMobile =
      window.innerWidth <= this.mobileWidth || window.innerHeight <= this.mobileHeight;
    if (this.isMobile) {
      this.global.toggleBodyOverflow(false);
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.global.toggleBodyOverflow(true);
  }
}
