import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { prop } from 'ramda';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-hall-schema-legend',
  template: `
    <perfect-scrollbar>
      <div class="wrap">
        <div class="ev-info" *ngIf="schedule && schedule.Event">
          <div class="ev-info__row">
            <div class="ev-info__title">
              {{ schedule.Event.name | multilang }}
              <span class="ev-info__rate">{{ schedule.Event.pg_rating | rate }}</span>
            </div>
          </div>
          <div class="ev-info__row">
            <div class="ev-info__text">
              {{ schedule.begin | lDate: 'd MMMM, EEEE, HH:mm' }},
              <span *ngIf="schedule.Spot && schedule.Spot.name">{{
                schedule.Spot.name | multilang
              }}</span>
            </div>
          </div>
        </div>
        <div class="common-places">
          <div class="place-item" *ngFor="let price of prices">
            <span class="icon"
              ><span [ngStyle]="{ 'background-color': '#' + price.color }"></span
            ></span>
            <span class="text"
              ><span *ngIf="show_price_categories_names">{{
                price.PriceCategory.name | multilang
              }}</span>
              <span class="price-currency">{{
                price.amount | money: price.amount_currency
              }}</span></span
            >
          </div>
          <div class="place-item busy">
            <span class="icon"><span></span></span>
            <span class="text">{{ 'HallSchema.TAKEN_PLACE' | translate }}</span>
          </div>
        </div>
        <div class="advanced-places">
          <div class="place-item selected">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="23" viewBox="0 0 19 23">
                <path
                  fill="#FFF"
                  fill-rule="nonzero"
                  d="M14.118 5.402a4.667 4.667 0 0 1-4.68 4.678A4.688 4.688 0 0 1 4.76 5.402 4.688 4.688 0 0 1 9.439.722a4.667 4.667 0 0 1 4.679 4.68zm4.068 14.612c0 2.78-17.495 2.78-17.495 0 0-4.136 3.9-8.306 8.748-8.306 4.848 0 8.747 4.17 8.747 8.306z"
                />
              </svg>
            </span>
            <span class="text">{{ 'HallSchema.SELECTED_PLACES' | translate }}</span>
          </div>
        </div>
        <div class="info">
          <div class="service">{{ 'Company.ServiceWork' | translate }}</div>
          <div class="phone">+7 495 120-42-64</div>
          <div class="email">support@lastick.ru</div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="header">
      <div class="btn-close" (click)="close($event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24">
          <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M12.096 11.183L6.55 5.635l-.981.98 5.548 5.548-5.207 5.207.98.98 5.207-5.206 5.207 5.207.98-.98-5.206-5.208 5.548-5.547-.98-.981-5.549 5.548zm-.17 12.74c-6.59 0-11.93-5.341-11.93-11.93 0-6.59 5.34-11.93 11.93-11.93 6.589 0 11.93 5.34 11.93 11.93 0 6.589-5.341 11.93-11.93 11.93z"
          />
        </svg>
      </div>
    </div>
  `,
  styleUrls: ['./legend.component.less'],
})
export class HallSchemaLegendComponent implements OnInit, OnChanges, OnDestroy {
  @Input() schedule;
  @Input('prices') actual_prices: any;
  @Input('display') displayTrigger: Observable<void>;
  @Input('show_price_categories') show_price_categories_names: boolean | void;

  @Output() showHide: EventEmitter<boolean> = new EventEmitter<boolean>();

  public prices = [];
  private display = false;
  private subscription: Subscription;

  constructor(private _elemRef: ElementRef) {
    this.display = false;
  }

  ngOnInit() {}

  ngOnChanges(changes: any) {
    if (changes['actual_prices'] && changes['actual_prices'].currentValue) {
      this.prices = Object.values(this.actual_prices).sort((a, b) => {
        return prop('amount', a) - prop('amount', b);
      });
    }
    if (changes['displayTrigger'] && this.displayTrigger && !this.subscription) {
      this.subscription = this.displayTrigger.subscribe(() => {
        this.display = true;
        this.doDisplay();
      });
    }
  }

  private doDisplay() {
    // const onTransitionEnd = () => {
    //   this._elemRef.nativeElement.removeEventListener('transitionend', onTransitionEnd);
    //   this.showHide.emit(this.display);
    // };
    // this._elemRef.nativeElement.addEventListener('transitionend', onTransitionEnd);
    if (this.display) {
      console.log('SHOW CAT NAMES', this.show_price_categories_names, this.prices);
      this._elemRef.nativeElement.style.left = 0;
    } else {
      this._elemRef.nativeElement.style.left = '101%';
    }
    this.showHide.emit(this.display);
  }

  public close(ev) {
    this.display = false;
    this.doDisplay();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
