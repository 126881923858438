import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CartService } from '../../services/cart.service';
import {IContacts, IPaymentMethod} from '../../types/Entities';
import { Subscription } from 'rxjs';
import { prop, path } from 'ramda';
import { TransportService } from '../../services/transport.service';
import { TranslateService } from '@ngx-translate/core';
import {MetricService} from '../../services/metric.service';

@Component({
  selector: 'applepay-button',
  templateUrl: './applepay_button.component.html',
  styleUrls: ['./applepay_button.component.less'],
})
export class ApplepayButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input('data') data: IPaymentMethod;
  @Input() disabled = false;
  @Input() visitors: {[uuid: string]: any};
  private paymentMethodData;
  private paymentDetails: any = {};
  private paymentOptions: any = {};
  private session: PaymentRequest;

  constructor(
    private cart: CartService,
    private api: TransportService,
    public translate: TranslateService,
    private metric: MetricService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
    }
  }

  private makePaymentRequest(): any {
    if (this.data) {
      this.paymentMethodData = {
        data: Object.assign({}, prop('params', this.data.ext_provider_data)),
        supportedMethods: 'https://apple.com/apple-pay',
      };

      this.paymentDetails = {
        total: {
          label: 'Lastick.ru',
          amount: {
            value: (this.cart.total_cost * 0.01).toFixed(2),
            currency: path(['currency', 'alias'], this.data.ext_provider_data),
          },
        },
      };

      if (!this.cart.contacts.phone || this.cart.contacts.phone === '') {
        this.paymentOptions['requestPayerPhone'] = true;
      }

      if (!this.cart.contacts.email || this.cart.contacts.email === '') {
        this.paymentOptions['requestPayerEmail'] = true;
      }
    } else {
      return undefined;
    }
  }

  public buttonClick() {
    this.makePaymentRequest();
    try {
      console.log('try payment session', {
        paymentMethodData: [this.paymentMethodData],
        paymentDetails: this.paymentDetails,
        paymentOptions: this.paymentOptions,
      });
      this.session = new PaymentRequest(
        [this.paymentMethodData],
        this.paymentDetails,
        this.paymentOptions
      );

      this.session['onmerchantvalidation'] = event => {
        console.log('onmerchantvalidation', { event });
        const validationURL = event.validationURL;
        this.api.validateMerchant(this.data.uuid, { validationURL: validationURL })
          .toPromise()
          .then(response => {
            console.log('validateMerchant complete', { response });
            event.complete(response);
          })
          .catch(e => {
            console.log('validateMerchant error', { error: e });
            this.session.abort();
          });
      };

      console.log('try session show');
      this.session.show()
        .then(response => {
          console.log('session.show', { response });
          if (response.payerPhone) {
            this.cart.contacts.phone = response.payerPhone;
          }

          if (response.payerEmail) {
            this.cart.contacts.email = response.payerEmail;
          }
          const token = path(['details', 'token', 'paymentData'], response);

          const checkoutData: IContacts = {
            contacts: this.cart.contacts,
            payment_method: this.data.uuid,
            payment_token: token,
          };
          if (this.visitors) {
            checkoutData.visitors = this.visitors;
          }
          this.cart.checkout(checkoutData)
            .then(success => {
              response.complete('success');
              const currency = this.cart.getCurrency().toUpperCase();
              this.metric.trackFb('InitiateCheckout', {value: this.cart.total_cost / 100, currency: currency === 'RUR' ? 'RUB' : currency});
              this.cart.moveToPay(success.payment.redirect.value);
            })
            .catch(e => {
              response.complete('fail');
              // alert(JSON.stringify(e));
            });
        })
        .catch(e => console.error('REJECTED', e));
    } catch (e) {
      console.log('catch payment session', { error: e });
      try {
        if (this.session) {
          this.session.abort();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
}
