import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-plus-minus',
  templateUrl: './plus-minus.component.html',
  styleUrls: ['./plus-minus.component.less'],
})
export class PlusMinusComponent implements OnInit {
  @Input('source') source: any;
  @Input('count') count: any;
  @Input('value') value;
  @Input('max_count') max_count;
  @Input('disabled') disabled = false;
  @Input() priceCategory = null;
  @Input() hideCounter = false;

  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() increment: EventEmitter<any> = new EventEmitter();
  @Output() decrement: EventEmitter<any> = new EventEmitter();

  sum = 0;
  choosedCount = 0;

  constructor() {}

  ngOnInit() {}

  onChange(type) {
    // const foundIndex = this.items.findIndex(_item => _item.id === id);
    // const choosedFoundIndex = this.choosed.findIndex(_item => _item.id === id);
    // const item = this.items[foundIndex];
    // const currCount = item.count || 0;
    const currCount = this.count || 0;
    const resultCount =
      type === 1
        ? currCount >= this.max_count
          ? currCount
          : currCount + 1
        : currCount !== 0
        ? currCount - 1
        : 0;

    // item.count = resultCount;
    // this.items[foundIndex].count = resultCount;
    this.count = resultCount;
    this.sum = this.sum - (this.value * currCount - this.value * resultCount);
    // this.choosedCount = this.choosedCount - (currCount - resultCount);
    //
    // if (choosedFoundIndex >= 0) {
    //   if (resultCount > 0) {
    //     this.choosed[choosedFoundIndex].count = resultCount;
    //   } else {
    //     this.choosed.splice(choosedFoundIndex, 1);
    //   }
    // } else if (resultCount !== 0) {
    //   this.choosed.push(item);
    // }

    const data = {
      sum: this.sum,
      count: this.count,
      src: this.source,
      // choosedCount: this.choosedCount,
      // choosed: this.choosed,
      // item,
      // items: this.items
    };

    this.change.emit(data);

    // && this.max_count !== currCount
    if (type === 1) {
      this.increment.emit(data);
    } else if (type === 0 && currCount !== 0) {
      this.decrement.emit(data);
    }
  }
}
