import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {}

  transform(value: any): any {
    return this.sanitized.bypassSecurityTrustHtml(value ? value
      .replace(/<strong>/g, '<span style="font-weight: bold">')
      .replace(/<\/strong>/g, '</span>')
      .replace(/<em>/g, '<span style="font-style: italic">')
      .replace(/<\/em>/g, '</span>') : '');
  }

}
