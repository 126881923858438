import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-order-cert-status',
  templateUrl: './order-cert-status.component.html',
  styleUrls: ['./order-cert-status.component.less'],
})
export class OrderCertStatusComponent implements OnInit, OnDestroy, AfterViewInit {
  order: any;
  data: any;
  items: any;
  status = 'init';
  timer: any = null;
  timeout: any = null;
  successStatuses: Array<string> = ['done', 'paid', 'on_delivery'];
  finalStatuses: Array<string> = [...this.successStatuses, 'refund', 'cancel', 'outdated', 'error'];

  dragScrollWidth: number;
  isGarage = false;

  @ViewChild('wrap', { static: false }) wrap: ElementRef;

  constructor(
    private route: ActivatedRoute,
    // private router: Router,
    private transport: TransportService,
    public global: GlobalService
  ) {
    this.route.params.subscribe(data => {
      this.order = data;
    });
  }

  ngOnInit() {
    this.checkOrder();
    this.global.showHideLoader.next(true);

    this.timer = setInterval(() => {
      this.checkOrder();
    }, 2000);

    this.timeout = setTimeout(() => {
      clearInterval(this.timer);
    }, 1000 * 60 * 20);
    //  1000 * 60 * 20
    this.isGarage = this.global.settings.name.en === 'Garage' ? true : false;


  }

  ngAfterViewInit() {
    this.setDragScrollWidth();

    window.addEventListener('resize', this.setDragScrollWidth.bind(this));


  }

  ngOnDestroy() {}

  setDragScrollWidth() {
    const windowWidth = window.innerWidth;
    const wrapWidth = this.wrap.nativeElement.getBoundingClientRect().width;

    this.dragScrollWidth = wrapWidth + (windowWidth - wrapWidth) / 2;
  }

  isValidStatus() {
    return this.finalStatuses.indexOf(this.status) >= 0;
  }

  checkStatus() {
    const isValidStatus = this.isValidStatus();
    if (isValidStatus) {
      if (this.checkSuccess() && !this.items) {
        return false;
      }
      clearInterval(this.timer);
      clearTimeout(this.timeout);
      this.global.showHideLoader.next(false);
    }
    return isValidStatus;
  }

  checkSuccess() {
    return this.successStatuses.indexOf(this.status) >= 0;
  }

  checkOrder() {
    if (this.checkStatus()) return;

    this.transport
      .showcaseOrder(this.order.order_uuid)
      .toPromise()
      .then(
        order => {
          this.status = order.status;
          this.data = order;
          this.items = order.items;
          this.checkStatus();
          // console.log('Type Items', order, this.items[0])
        },
        err => {
          this.status = 'error';
          // this.status = 'done';
          this.global.showHideLoader.next(false);
          this.checkStatus();
        }
      );
  }
}
