import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IMultiLangField} from '../../types/Entities';
import {IWarningPopupShowHide, WarningPopupService} from '../../services/warning-popup.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.less']
})
export class WarningPopupComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() srcHtml: string;

  public popupData: IWarningPopupShowHide;
  public content: IMultiLangField;
  private destroy$ = new Subject();

  constructor(
    public service: WarningPopupService,
    public global: GlobalService
  ) {
  }

  ngOnInit() {
    this.service.showHide$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data && data.id === this.id) {
          this.popupData = data;
        }
        this.global.toggleBodyOverflow(!(data && data.show));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
