import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';
import {MetricService} from '../../services/metric.service';
import {StorageService} from '../../services/storage.service';
import {CartService} from '../../services/cart.service';
import {path} from 'ramda';

@Component({
  selector: 'app-cart-order-check',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.less'],
})
export class OrderStatusComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  settings = {};
  widget_settings = {};
  order: any;
  data: any;
  status = 'init';
  timer: any = null;
  timeout: any = null;
  successStatuses: Array<string> = ['paid', 'on_delivery', 'done'];
  finalStatuses: Array<string> = [
    ...this.successStatuses,
    'refund',
    'outdated',
    'cancelled',
    'error',
  ];
  private cartStorage: any;

  constructor(
    private route: ActivatedRoute,
    // private router: Router,
    private transport: TransportService,
    public global: GlobalService,
    private metric: MetricService,
    private storage: StorageService,
    private cart: CartService
  ) {
    this.route.params.subscribe(data => {
      this.order = data;
    });
  }

  ngOnInit() {
    this.cartStorage = this.cart.getCartFromStorage();
    this.checkOrder();
    this.global.showHideLoader.next(true);

    this.timer = setInterval(() => {
      this.checkOrder();
    }, 2000);

    this.timeout = setTimeout(() => {
      clearInterval(this.timer);
    }, 1000 * 60 * 20);

    this.subscriptions = [
      this.global.settings$.subscribe(settings => {
        this.settings = settings;
        this.widget_settings = settings.widget_settings;
      }),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.map(_s => _s.unsubscribe());
  }

  isValidStatus() {
    return this.finalStatuses.indexOf(this.status) >= 0;
  }

  checkStatus() {
    const isValidStatus = this.isValidStatus();
    if (isValidStatus) {
      clearInterval(this.timer);
      clearTimeout(this.timeout);
      this.global.showHideLoader.next(false);
    }
    return isValidStatus;
  }

  isSuccessStatus(): boolean {
    return this.successStatuses.indexOf(this.status) >= 0;
  }

  checkSuccess() {
    return this.successStatuses.indexOf(this.status) >= 0;
  }

  checkOrder() {
    if (this.checkStatus()) return;

    this.transport
      .getOrder(this.order.order_uuid)
      .toPromise()
      .then(
        order => {
          this.status = order.status;
          this.data = order;
          const storageCartOrder = path(['order'], this.cartStorage) || [];
          // this.checkStatus();
          if (this.checkStatus() && this.isSuccessStatus() && storageCartOrder.length) {
            const total_cost = this.cart.total_cost * 0.01;
            const tickets = path(['storage'], this.cartStorage) || [];
            const currency = this.cart.getCurrency().replace('rur', 'rub').toUpperCase();
            this.metric.ecommmerceGtagPurchase({
              transaction_id: order.number,
              affiliation: location.hostname,
              value: total_cost,
              currency
            }, {tickets});
            this.metric.ecommmerceYandexPurchase({
              transaction_id: order.number,
              affiliation: location.hostname,
              value: total_cost,
              currency
            }, {tickets});
            this.metric.trackFb('Purchase', {value: total_cost});
            this.metric.goalVK('purchase');
            this.cart.dropCart();
          }
        },
        err => {
          this.status = 'error';
          this.global.showHideLoader.next(false);
          this.checkStatus();
        }
      );
  }
}
