import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { GlobalService } from '../../services/global.service';
import { CartPopupComponent } from '../cart-popup/cart-popup.component';

@Component({
  selector: 'app-cart-popup-container',
  templateUrl: './cart-popup-container.component.html',
  styleUrls: ['./cart-popup-container.component.less'],
})
export class CartPopupContainerComponent implements OnInit, OnDestroy {
  subscriptions = [];
  show = false;

  constructor(
    public global: GlobalService,
    // private CartPopupComponent: CartPopupComponent,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewRef: ViewContainerRef,
    public cartService: CartService,
  ) {}

  ngOnInit() {
    this.subscriptions = [
      this.global.showHideCartPopup.subscribe(show => {
        this.show = show;
        this.viewRef.clear();
        if (this.show) {
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            CartPopupComponent
          );
          const componentRef = this.viewRef.createComponent(componentFactory);
        }
      }),
    ];

  }

  ngOnDestroy(): void {
    this.subscriptions.map(_s => _s.unsubscribe());
  }
}
