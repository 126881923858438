import {Component, OnInit, ViewChild} from '@angular/core';
import {WarningPopupService} from '../../services/warning-popup.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-warning-covid',
  templateUrl: './warning-covid.component.html',
  styleUrls: ['./warning-covid.component.less']
})
export class WarningCovidComponent implements OnInit {
  @ViewChild('form', {static: true}) form: NgForm;

  public md: any = {};
  public submitted = false;

  constructor(
    public popupService: WarningPopupService
  ) { }

  ngOnInit() {
    this.form.resetForm({});
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.popupService.hide({id: 'covid'});
    }
  }
}
