import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { parse } from 'date-fns';
import { type } from "os";

@Pipe({
  name: 'lDate',
  pure: false,
})
export class LDatePipe implements PipeTransform {
  private value: string;
  private lastDate: number;
  private lastLang: string;

  constructor(public translate: TranslateService) {}

  transform(dt: string | Date, pattern: string = 'mediumDate'): string {
    const currentLang = this.translate.currentLang;
    const _d = typeof dt === 'string' ?
      parse(dt.replace(/\s/, 'T')) : dt;
    if (_d.getTime() === this.lastDate && currentLang === this.lastLang) {
      return this.value;
    }
    this.value = new DatePipe(currentLang).transform(_d, pattern);
    this.lastDate = _d.getTime();
    this.lastLang = currentLang;

    return this.value;
  }
}
