import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  debug: boolean = !environment.production;

  l(...values: any) {
    return this.debug ? console.log.apply(this, arguments) : '';
  }

  e(...values: any) {
    return this.debug ? console.error.apply(this, arguments) : '';
  }

  w(...values: any) {
    return this.debug ? console.warn.apply(this, arguments) : '';
  }

  i(...values: any) {
    return this.debug ? console.info.apply(this, arguments) : '';
  }
}
