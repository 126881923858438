import { Pipe, PipeTransform } from '@angular/core';
import { ICartItem } from './types/Entities';
import { TranslateService } from '@ngx-translate/core';

export interface IPlaceMetaOpt {
  rowName: string;
  seatName: string;
  lang?: string;
}

@Pipe({
  name: 'placeMeta',
})
export class PlaceMetaPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: ICartItem, args?: IPlaceMetaOpt): string {
    const lang =
      args && args.lang ? args.lang : this.translate.currentLang || this.translate.defaultLang;
    if (value.ProductItem && value.ProductItem.Place && value.ProductItem.Place.meta) {
      const prepareStr = (str): string => {
        return str.replace(/\s/, '').toLowerCase();
      };
      const normalizeStr = (str): string => {
        return str
          .trim()
          .replace(/(^,)|(,$)/g, '')
          .trim();
      };
      const { seatName, rowName } = args;
      const meta = value.ProductItem.Place.meta;
      const { fragment, sector } = meta;
      const row = meta.row;
      const seat = meta.seat;
      const _sectorName = sector && sector.name ? sector.name : {};
      const sectorName = _sectorName[lang] || _sectorName[this.translate.defaultLang];
      const _fragmentName = fragment && fragment.name ? fragment.name : {};
      const fragmentName = _fragmentName[lang] || _fragmentName[this.translate.defaultLang];
      const sectorPlace = sectorName ? `${sectorName}` : '';
      const rowPlace = row && row.name ? `, ${row.name} ${rowName}` : '';
      const seatPlace = seat ? `, ${meta.seat} ${seatName}` : '';
      const fragmentPlace = fragmentName ? ` (${fragmentName})` : '';

      return `
        ${normalizeStr(
          `${sectorPlace}${rowPlace}${seatPlace}${
            sectorName && fragmentName && prepareStr(sectorName) === prepareStr(fragmentName)
              ? ''
              : fragmentPlace
          }`
        )}`;
    }
  }
}
