import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { is, propOr, path } from 'ramda';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../../../services/cart.service';
import { ICartItem, IPriceValue } from '../../../types/Entities';

@Component({
  selector: 'app-hall-schema-seatinfo',
  template: `
    <div class="lastick-schema_place-info" *ngIf="item">
      <div class="place-info">
        <div class="place">
          {{ row }} {{ 'HallSchema.ROW' | translate }}, {{ seat }} {{ 'HallSchema.SEAT' | translate
          }}<span>{{ sector }} {{ fragment ? '(' + fragment + ')' : '' }}</span>
        </div>
        <div class="price price-currency price-currency_fz-1">
          {{ price | money: item.PriceValue.amount_currency }}
        </div>
      </div>
      <div class="btn-close-area">
        <div class="btn-close" (click)="onRemoveClick()">
          <svg-icon src="/assets/img/close.svg"></svg-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./seatinfo.component.less'],
})
export class HallSchemaSeatinfoComponent implements OnInit, OnChanges {
  @Input('item') item: ICartItem;

  public seat: string;
  public row: string;
  public fragment: string;
  public sector: string;
  public price: number;

  constructor(private translateService: TranslateService, private cartService: CartService) {}

  ngOnChanges(changes: any) {}

  ngOnInit() {
    if (this.item) {
      const lang = this.translateService.currentLang;
      const { PriceValue, ProductItem, meta } = this.item;

      const frag = this.getTranslated(
        lang,
        propOr(ProductItem.Place.meta.fragment.name, 'name', ProductItem.Place.meta.fragment.name)
      );
      const basePrice: IPriceValue = this.item.meta._helperBasePrice;
      this.price = (basePrice || {} as IPriceValue).price || PriceValue.amount;
      this.seat = ProductItem.Place.meta.seat;
      this.row = ProductItem.Place.meta.row.name;
      this.sector = this.getTranslated(
        lang,
        propOr(ProductItem.Place.meta.sector, 'name', ProductItem.Place.meta.sector)
      );
      if (!this.sector) {
        this.sector = frag;
      }
      if (frag && frag !== this.sector) {
        this.fragment = frag;
      } else {
        this.fragment = undefined;
      }
    }
  }

  private getTranslated(lang: string, k: any) {
    if (is(Object, k)) {
      return propOr(k['ru'], lang, k);
    } else {
      return k;
    }
  }

  public onRemoveClick() {
    this.cartService.removeItem(this.item.ProductItem, this.item.PriceValue);
  }
}
