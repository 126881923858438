import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-tickets',
  templateUrl: './empty-tickets.component.html',
  styleUrls: ['./empty-tickets.component.less'],
})
export class EmptyTicketsComponent implements OnInit {
  @Input() type: 'cart' | 'common' = 'common';
  @Input() langKey = '';

  constructor() {}

  ngOnInit() {}
}
