import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tabs-tab',
  templateUrl: './tabs-tab.component.html',
  styleUrls: ['./tabs-tab.component.styl'],
})
export class TabsTabComponent {
  @Input() active = false;
  @Input('tabTitle') title: string;
}
