import {
  Component,
  OnInit,
  OnDestroy,
  DoCheck,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';
import { LoggerService } from '../../services/logger.service';
import { IInfo } from '../../types/IInfo';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../services/storage.service';
import { FormService } from '../../services/form.service';
import { ValidationService } from '../../services/validation.service';
import { InfoService } from '../../services/info.service';
import { MultilangFieldPipe } from '../../pipes/multilang/multilang_field.pipe';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.less'],
  providers: [MultilangFieldPipe]
})
export class GiftComponent implements OnInit, OnDestroy, AfterViewChecked {


  garageHeader = {ru: 'ПОКУПКА КАРТЫ GARAGE', en: 'PURCHASE A GARAGE CARD'};
  garageErrorHeader = {ru: 'Ошибка', en: 'Error'};
  garageErrorText = {ru: 'Для себя можно купить не более одной карты', en: 'For personal use you can buy only one card'};
  garageCheckout = {ru: 'Перейти к оплате', en: 'Checkout'};

  garagePlaceholderTo = {ru: 'Ваше имя', en: 'Your name'};
  garagePlaceholderEmail = {ru: 'Ваш e-mail', en: 'Your email'};
  garageError = false;

  info: IInfo;
  cert;
  giftForm: FormGroup;
  formName = 'giftForm';
  prices: any = [];
  denominationPrice = 0;
  priceCount: { [key: number]: number } = {};
  pricesRange: any = [];
  certificateNumber = 0;
  certificatePrice = 0;
  denominationNumber = 0;
  currentLang = 'ru';
  name: any = {};
  nameView: any = {};
  gift = false;
  popup = false;
  denomination = false;
  langSubscribe: any;
  customValuePattern = /^[1-9]+\d*$/;
  isGarage = true;


  constructor(
    private transport: TransportService,
    public global: GlobalService,
    private logger: LoggerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private storage: StorageService,
    public formService: FormService,
    private cdRef: ChangeDetectorRef,
    private infoService: InfoService,
    private mLang: MultilangFieldPipe
  ) {
    this.giftForm = this.formBuilder.group({
      variant: [true],
      from: [''],
      to: [''],
      message: [''],
      addressee_email: [''],
      email: ['', [Validators.required, Validators.email]],
      denominationPrice: ['', Validators.pattern(this.customValuePattern)],
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit() {

    const formValue = {
      variant: true,
      from: '',
      to: '',
      message: '',
      addressee_email: '',
      email: '',
      denominationPrice: '',
      ...this.formService.getFormValues(this.formName),
    };
    const pricesValue = this.storage.getItem('gift_prices');
    const infoValue = this.storage.getItem('gift_info');
    const denominationValue = this.storage.getItem('gift_denomination');

    console.log('INFO', infoValue, pricesValue, denominationValue);
    this.priceCount = pricesValue ? JSON.parse(pricesValue) : {};
    this.info = infoValue ? JSON.parse(infoValue) : {};
    this.currentLang = this.translate.currentLang;
    this.langSubscribe = this.translate.onLangChange.subscribe(data => {
      this.currentLang = data.lang;
    });
    this.info = { view: null, email: null, certificate: null, is_gift: true };
    this.giftForm.get('variant').valueChanges.subscribe(checked => {
      // setTimeout(() => {
      const addrEmail = this.giftForm.get('addressee_email');
      if (checked) {
        addrEmail.setValidators([Validators.email, Validators.required]);
      } else {
        addrEmail.setValidators(null);
        addrEmail.setErrors(null);
      }
      this.info.is_gift = checked;
      // }, 1);
    });
    this.giftForm
      .get('denominationPrice')
      .setValidators([Validators.min(1), Validators.max(10000000)]);
    this.route.queryParams.subscribe(params => {
      if (params.present) {
        this.gift = true;
        this.giftForm.patchValue({
          variant: true,
        });
      }
    });

    this.isGarage = this.global.settings.name.ru === 'Garage';
    this.global.showHideLoader.next(true);
    this.transport.showcaseList().subscribe(
      success => {
        this.global.showHideLoader.next(false);

        const certificate = success[0];
        this.cert = certificate;
        if (certificate) {
          const view = certificate['Views'][0];
          this.name = certificate.name;

          this.nameView = view.name;

          this.info = { ...this.info, view, certificate };

          this.prices = certificate.prices.sort((a, b) => a - b);

          if (
            certificate.prices_range &&
            (certificate.prices_range[0] || certificate.prices_range[1])
          ) {
            this.priceCount = Object.keys(this.priceCount).reduce((acc, _price) => {
              const min = certificate.prices_range[0];
              const max = certificate.prices_range[1];
              const price = parseInt(_price, 10);
              if ((max && price <= max) || (min && price >= min)) {
                acc[`${price}`] = this.priceCount[_price];
              }
              return acc;
            }, {});
            this.denomination = true;
            this.pricesRange = certificate.prices_range;
          }
          for (const index in this.prices) {
            if (this.prices.hasOwnProperty(index)) {
              const value = this.prices[index];
              this.priceCount[value] = this.priceCount[value] || 0;
              if (this.priceCount[value]) {
                this.certificateNumber += this.priceCount[value];
                this.certificatePrice += value * this.priceCount[value];
              }
            }
          }
          if (denominationValue) {
            const den = JSON.parse(denominationValue);
            Object.keys(den).map(price => {
              if (den[price] > 0) {
                this.denominationPrice += parseInt(price, 10) / 100;
                this.denominationNumber += den[price];
              }
            });
            this.giftForm.get('denominationPrice').setValue(this.denominationPrice || '');
          }
          if (this.pricesRange) {
            const maxValue = this.pricesRange[1] ? this.pricesRange[1] / 100 : 10000000;
            const minValue = this.pricesRange[0] ? this.pricesRange[0] / 100 : 1;
            this.giftForm.controls.denominationPrice.setValidators([
              Validators.min(minValue),
              Validators.max(maxValue),
            ]);
          }
          this.logger.l('certificate', certificate);
          this.logger.l('view', view);
        }
      },
      error => {
        console.log('Error Completed');
        this.global.showHideLoader.next(false);
      }
    );
    this.giftForm.setValue(formValue);
    this.giftForm.patchValue({
      variant: true,
    });
  }

  priceMinus(price) {
    if (this.priceCount[price] >= 1) {
      this.certificateNumber--;
      this.certificatePrice -= parseInt(price, 10);
      this.priceCount[price]--;
    }
  }

  pricePlus(price) {
    // ===== Вот тут делаем проверку на количество

    if (!this.giftForm.get('variant').value) {
      if (this.certificateNumber < 1) {
        this.certificateNumber++;
        this.certificatePrice += parseInt(price, 10);
        this.priceCount[price]++;
      } else {
        this.infoService.showError(this.mLang.transform(this.garageErrorText));
      }
    } else {
      this.certificateNumber++;
      this.certificatePrice += parseInt(price, 10);
      this.priceCount[price]++;
    }

  }

  denominationMinus() {
    if (this.denominationPrice && this.denominationNumber >= 1) {
      this.denominationNumber--;
    }
  }

  denominationPlus() {
    if (this.denominationPrice) {
      this.denominationNumber++;
    }
  }

  checkDenomination() {
    if (this.pricesRange && this.pricesRange[0] && this.giftForm.value.denominationPrice) {
      if (this.giftForm.value.denominationPrice < this.pricesRange[0] / 100) {
        this.denominationPrice = this.pricesRange[0] / 100;
        this.giftForm.get('denominationPrice').setValue(this.denominationPrice);
      }
    }
  }

  denominationChange(val) {
    if (this.denominationPrice === val) {
      return;
    }
    this.denominationPrice = val;
    if (!this.denominationNumber && this.denominationPrice) {
      this.denominationNumber = 1;
    } else if (!this.denominationPrice) {
      this.denominationNumber = 0;
    }
    // if (this.denominationPrice) {
    //   if (this.pricesRange[0] && this.denominationPrice < this.pricesRange[0] / 100) {
    //     this.denominationPrice = this.pricesRange[0] / 100;
    //   }
    //   if (this.pricesRange[1] && this.denominationPrice > this.pricesRange[1] / 100) {
    //     this.denominationPrice = this.pricesRange[1] / 100;
    //   }
    // } else {
    //   this.denominationNumber = 0;
    // }
  }

  popupOpen() {
    this.popup = true;
  }

  onChange() {
    this.gift = !this.gift;
  }

  onSubmit() {
    if (!this.checkCorrectCart()) {
      this.infoService.showError(this.mLang.transform(this.garageErrorText));
      return;
    }
    this.checkCorrectCart();
    this.formService.markFormGroupTouched(this.giftForm);

    if (this.giftForm.invalid || (!this.certificateNumber && !this.denominationNumber)) {
      return;
    }
    this.info = { ...this.info, ...this.giftForm.value };
    this.storage.setItem(
      'gift_denomination',
      JSON.stringify({ [this.denominationPrice * 100]: this.denominationNumber })
    );
    this.storage.setItem('gift_prices', JSON.stringify(this.priceCount));
    this.storage.setItem('gift_info', JSON.stringify(this.info));
    console.log('INFO', this.info, this.priceCount);
    this.formService.saveFormValues(this.formName, this.giftForm.value);
    this.router.navigate(['/order/confirm']);
    this.logger.l('info', this.info);
  }

  ngOnDestroy() {
    this.langSubscribe.unsubscribe();
  }

  checkCorrectCart(): boolean {
    if (!this.giftForm.get('variant').value) {

      // const idx = Object.keys(this.priceCount).findIndex(key => this.priceCount[key] > 1);
      // console.log('LOG', this.certificateNumber, !(this.certificateNumber > 1))
      return !(this.certificateNumber > 1);

    } else {
      return true;
    }
  }

  enter() {
    // console.log('mouse enter');
    this.popup = true;
    // this.garageError = true;
  }
}
