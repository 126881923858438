import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger.service';
import { ValidationService } from '../../services/validation.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { IContacts, IPaymentMethod } from '../../types/Entities';
import { path } from 'ramda';
import { FormService } from '../../services/form.service';
import { MetricService } from '../../services/metric.service';
import { WarningPopupService } from '../../services/warning-popup.service';
import { BehaviorSubject } from 'rxjs';
import { isValid, parse } from 'date-fns';
import { InfoService } from '../../services/info.service';
import { MultilangFieldPipe } from '../../pipes/multilang/multilang_field.pipe';

@Component({
  selector: 'app-order-confirm-activate',
  templateUrl: './order-confirm-activate.component.html',
  styleUrls: ['./order-confirm-activate.component.less'],
  providers: [MultilangFieldPipe]
})
export class OrderConfirmActivateComponent implements OnInit, OnDestroy {
  @ViewChild('emailInput', { static: false }) emailInput: ElementRef;

  subscriptions = [];
  eDt: any;
  orderForm: FormGroup;
  cart: any;
  lang: string;
  collapse = false;
  private cartItems;
  total_items = 0;
  isSeason = false;
  formName = 'cartOrderForm';
  showPromocodePopup = false;
  visitors: { [uuid: string]: any };
  invalidVisitors: string[];
  personalAgree;
  isGarage = false;
  public validateCartItemsSource = new BehaviorSubject(null);
  public validateCartItems$ = this.validateCartItemsSource.asObservable();

  validErrorMessage = {en: 'You should input personal tickets data', ru: 'Необходимо заполнить персональные данные в билетах'}

  constructor(
    private transport: TransportService,
    public global: GlobalService,
    public translate: TranslateService,
    private logger: LoggerService,
    private formBuilder: FormBuilder,
    public cartService: CartService,
    public formService: FormService,
    private metric: MetricService,
    public warningPopupService: WarningPopupService,
    private info: InfoService,
    private mLang: MultilangFieldPipe
  ) {
    this.eDt = this.global.currentEvent;
    this.lang = this.translate.currentLang;
  }

  ngOnInit() {
    const defaultModel: any = {
      phone: '',
      email: '',
    };
    this.cartService.toggleCertNominalMessage();
    this.global.settings$.subscribe(s => {
      this.orderForm = this.formBuilder.group({});
      if (s.widget_settings.cart && s.widget_settings.cart.name) {
        this.orderForm.addControl('lastName', new FormControl('', [Validators.required]));
        this.orderForm.addControl('firstName', new FormControl('', [Validators.required]));
        this.orderForm.addControl('middleName', new FormControl('', [Validators.required]));
        defaultModel.lastName = '';
        defaultModel.firstName = '';
        defaultModel.middleName = '';
      }
      if (s.widget_settings.showCovidMessages) {
        this.orderForm.addControl('personalAgree', new FormControl(false, [Validators.required]));
        defaultModel.personalAgree = false;
      }
      this.orderForm.addControl(
        'phone',
        new FormControl('', [Validators.required, ValidationService.phoneValidator])
      );
      this.orderForm.addControl(
        'email',
        new FormControl('', [Validators.required, ValidationService.emailValidator])
      );
      this.orderForm.get('phone').valueChanges.subscribe(e => {
        if (this.orderForm.get('phone').valid) {
          this.cartService.contacts.phone = e;
        } else {
          this.cartService.contacts.phone = '';
        }
      });
      this.orderForm.get('email').valueChanges.subscribe(e => {
        if (this.orderForm.get('email').valid) {
          this.cartService.contacts.email = e;
        } else {
          this.cartService.contacts.email = '';
        }
      });
      this.orderForm.setValue({
        ...defaultModel,
        ...this.formService.getFormValues(this.formName),
      });
      this.isGarage = s.name.en === 'Garage';
    }, error => {
      console.log('ERROR MESSAGE', error.message)
    });
    this.subscriptions = [
      // this.cartService.cartOutput$.subscribe(e => {
      //   this.cartService.syncCart().then(() => {
      //     this.cartService.toggleCertNominalMessage();
      //   });
      // }),
      this.cartService.cartSync$.subscribe(() => {
        this.cartService.toggleCertNominalMessage();
        this.isSeason = this.cartService.cart_items.some(item => item.schedule.is_season_head);
      }),
      this.translate.onLangChange.subscribe(data => {
        this.lang = data.lang;
      }),
    ];
    this.cartService.getAllowedPaymentMethods();
    console.log('GLOBAL VIS', this.visitors)
  }

  ngOnDestroy(): void {
    this.subscriptions.map(_s => {
      _s.unsubscribe();
    });
  }

  getPlaceSeats(index) {
    const ticket = this.cartItems[index];
    const place = ticket.ProductItem.Place;
    const seats = this.cartItems
      .filter(item => item.ProductItem.Place.meta.row.name === place.meta.row.name)
      .reduce((prev, item, i, out) => {
        out[i] = item.ProductItem.Place.meta.seat;
        return out;
      }, []);

    return seats.join(', ');
  }

  checkout() {
    this.global.showHideLoader.next(true);
    const contacts = {
      ...this.orderForm.value,
      phone: ValidationService.trimPhone(this.orderForm.value.phone),
    };
    const checkoutData: IContacts = {
      contacts: contacts
    };
    if (this.visitors) {
      console.log('VISITORS ARE OK', this.visitors)
      checkoutData.visitors = this.visitors;
    }
    this.formService.saveFormValues(this.formName, this.orderForm.value);
    this.cartService.syncCart().then(() => {
      this.cartService
        .checkout({
          ...checkoutData,
          payment_method: path(['payment_method', 'uuid'], this.cartService),
        })
        .then(
          success => {
            const currency = this.cartService.getCurrency().toUpperCase();
            this.metric.trackFb('InitiateCheckout', { value: this.cartService.total_cost / 100, currency: currency === 'RUR' ? 'RUB' : currency });
            this.global.showHideLoader.next(false);
            this.cartService.moveToPay(success.payment.redirect.value);
          },
          () => this.global.showHideLoader.next(false)
        );
    });
  }

  focusAndTouchForm() {
    this.formService.markFormGroupTouched(this.orderForm);
  }

  focusForm() {
    const c = (Object.values(this.orderForm.controls)[0] as any);
    if (c && c.nativeElement) {
      c.nativeElement.focus();
    }
  }

  onSubmit() {

    // console.log('VALID', this.cartService.restrictionIsValid)
    if (this.cartService.restrictionIsValid) {
       const validationResult = this.validateVisitors();
      if (this.cartService.total_items > 0) {
        if (this.orderForm.valid) {
          // console.log('VISITORS ERROR', this.visitors, this.invalidVisitors.length, this.cartService.restrictionIsValid);
          if (!validationResult) {
            this.info.showError(this.mLang.transform(this.validErrorMessage))
            this.validateCartItemsSource.next(this.invalidVisitors);
          } else {
            this.global.showHideConfirmPopup.next({
              id: 'order-confirm',
              show: true,
              values: Object.values(this.orderForm.value).filter(v => typeof v !== 'boolean'),
            });
          }
        } else {
          this.focusAndTouchForm();
        }
      }
    }

  }

  onBtnClick(pm?: IPaymentMethod) {
    if (this.cartService.restrictionIsValid) {
      if (pm) {
        this.cartService.payment_method = pm;
      }
      this.onSubmit();
    }

  }

  showPromoDialog() {
    this.showPromocodePopup = true;
  }

  hidePromoDialog() {
    this.showPromocodePopup = false;
  }

  applyPromoCode(promocode: string) {
    if (!promocode) {
      return;
    }
    this.cartService
      .addPromocode(promocode)
      .toPromise()
      .then(() => {
        this.showPromocodePopup = false;
      });
  }

  onVisitors(visitors: any) {
    this.visitors = visitors;
    this.validateVisitors();
  }

  validateVisitors(): boolean {

    // const correctVisitors = Object.keys(this.visitors || {}).filter(key => {
    //   if (this.cartItems)
    // });

    console.log('integrity', this.cartService.cart_schedules_storage, this.isGarage);


    this.invalidVisitors = [];

    let invalidVisitors = [];


    if (this.isGarage) {
      // ПРОВЕРКА ДЛЯ ГАРАЖА

      let hasValidation = 0;

      this.cartService.cart_schedules_storage.forEach(item => {
        if (item.PriceValue.PriceCategory.sale_settings && item.PriceValue.PriceCategory.sale_settings.ticket) {
          ++hasValidation;
        }
      });

      if (hasValidation === 0) {
        return true;
      }

      console.log('first, visitors', this.visitors)
      if (this.visitors === {} || !this.visitors) {
        return false;
      }

      invalidVisitors = Object.keys(this.visitors || {}).filter(key => {

        const v = this.visitors[key];

        return !(this.visitors[key].name || '').trim();

      });

    } else {
      // ПРОВЕРКА ДЛЯ ОСТАЛЬНЫХ

      if (!this.global.settings.widget_settings.showCovidMessages) {
        return true;
      }

      console.log('first, visitors', this.visitors)
      if (this.visitors === {} || !this.visitors) {
        return false;
      }

      invalidVisitors = Object.keys(this.visitors || {}).filter(key => {

        const v = this.visitors[key];

        const dt = (this.visitors[key].birthday || '').trim();
        // let validDt = true;

        const dtArr = dt.split('.');
        const d = parse(`${dtArr[1]}/${dtArr[0]}/${dtArr[2]}`);
        const validDt = isValid(new Date(d)) && dt.match(/^\d\d\.\d\d\.\d\d\d\d$/);

        console.log ('Try this', (this.visitors[key].name || '').trim(), validDt);

        return !(this.visitors[key].name || '').trim() || !validDt;

      });

    }

    console.log ('second, invalids', invalidVisitors)

    this.invalidVisitors = invalidVisitors;

    console.log ('third, invalids', this.invalidVisitors.length === 0)

    return this.invalidVisitors.length === 0;
  }
}
