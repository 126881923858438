import { Pipe, PipeTransform } from '@angular/core';
import { IPriceValue } from '../../types/Entities';

@Pipe({
  name: 'minprice',
})
export class MinpricePipe implements PipeTransform {
  transform(value: IPriceValue[], args?: any): any {
    if (value && value.length) {
      return value.sort((a, b) => a.amount - b.amount)[0].amount;
    } else {
      return undefined;
    }
  }
}
