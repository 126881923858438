import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransportService } from '../../services/transport.service';
import { LoggerService } from '../../services/logger.service';
import { GlobalService } from '../../services/global.service';
import { InfoService } from '../../services/info.service';
import { TranslateService } from '@ngx-translate/core';
import { IEvent } from '../../types/IEvent';
import { CartService } from '../../services/cart.service';
import { prop } from 'ramda';
import { format, parse, isSameDay } from 'date-fns';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMediaInfo, IMultilangString, IPriceValue, IWidgetSettings, TCurrency } from '../../types/Entities';
import { MoneyPipe } from '../../pipes/money/money.pipe';
import { ISchedule } from '../../types/ISchedule';
import { tick } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { MultilangFieldPipe } from '../../pipes/multilang/multilang_field.pipe';

@Component({
  selector: 'app-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['./event-table.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [MultilangFieldPipe]
})
export class EventTableComponent implements OnInit, OnDestroy {
  format = format;
  lang = this.translate.currentLang;
  event_uuid: string;
  eDt: IEvent;
  currSchedule: ISchedule;
  eDtMedia: IMediaInfo[];
  eDtPoster: IMediaInfo;
  media_info: IMediaInfo[];
  head_image: IMediaInfo;
  image_init = false;
  widget_settings: IWidgetSettings = {};
  hall: any = {};
  tickets_data: any;
  init_event: any;
  eventDt: string;
  listEvents = [];
  listEventsCurrDate = [];
  count = 0;
  priceAmount = 0;
  price: IPriceValue;
  prices: { [uuid: string]: IPriceValue };
  tickets: Array<any> = null;
  time: Array<any> = [];
  comparedTickets: any;
  d = new Date();
  showInfo: boolean = false;
  currDt: string;
  currTime: string;
  currD: any;
  schedulesByDate: any;
  schedulesCurrDate: any;
  showTime = false;
  subscriptions = [];
  currency: TCurrency;
  isGarage = false;
  infoMessages = [];

  private header: IMultilangString = { ru: '', en: ''};

  descriprion: IMultilangString = { ru: '', en: '' };

  garageAnounsment = {
    en: 'At the entrance to the exposition, a staff member of the Museum will ask you to present the relevant document.\nPlease note that Sophia Al-Maria\'s exhibition Beast Type Song is for visitors aged 18 and over.',
    ru: 'При входе на экспозицию сотрудник Музея попросит вас предъявить соответствующий документ.\nОбращаем ваше внимание, что выставка Софии аль-Марии «Песня чудовищного типа» имеет возрастное ограничение 18+'
  };
  garageAnounsmentEx = {
    en: 'Exhibition tickets are required to join the tour',
    ru: 'Для участия в экскурсии необходим входной билет на выставки'
  };
  garageAnounsmentCon = {
    en: 'All income from ticket sales will go to Garage Endowment Fund. GARAGE cardholders should show their card at the entrance. Please note that the concert On First-Name Terms is for those aged 16+',
    ru: 'Все средства от продажи билетов будут направлены в эндаумент-фонд Музея «Гараж». Владельцы карт GARAGE должны предъявить их при входе на концерт. Обращаем ваше внимание, что концерт «Мы на ты» имеет возрастное ограничение 16+'
  };
  garageEmpty = {
    en: 'Unfortunately there are no longer tickets available for this time interval. Please select another time.',
    ru: 'К сожалению, билеты на этот временной интервал закончились. Пожалуйста, выберите другое время.'
  };

  garageMovie = {
    en: 'Cinema visitors with discounted tickets must produce proof of eligibility at the entrance. Discounted tickets are invalid without a supporting document.',
    ru: 'При посещении кинопоказа по льготному билету сотрудник кинотеатра попросит вас предъявить соответствующий документ. Без данного документа льготный билет недействителен.'
  };
  garagePopup = {en: '', ru: 'Подробная информация'};
  garagePhoto = {
    en: '',
    ru: 'Обращаем ваше внимание, что выставка Родни Грэма «Фонокинетоскоп» имеет возрастное ограничение 18+'
  };

  courseGeneral1 = '1. Билет является документом, подтверждающим покупку абонемента и запись его обладателя в группу слушателей соответствующего курса. Билет необходимо предъявить на первом занятии. Сохраняйте билет до окончания курса.';
  courseGeneral2 = '2. На билете указаны дата и время начала занятий. Также расписание можно проверить на сайте ';
  courseGeneral21 = ' в разделе «Просвещение. Курсы».';
  courseGeneral3 = '3. Задать вопросы или поделиться своим мнением вы можете, написав по адресу ';
  courseGeneral31 = ' или позвонив по номеру +7 (499) 345-10-02.';

  courseMiss1 = '1. Стоимость пропущенных слушателем занятий не возмещается вне зависимости от причины пропуска.';
  courseMiss2 = '2. Администрация Фонда «АЙРИС» оставляет за собой право переносить занятия в случае болезни преподавателя или иных непредвиденных обстоятельств. Вопрос о переносе занятия во время школьных каникул, государственных праздников решается путем общего голосования слушателей курса: занятие переносится на другую дату, если большинство участников проголосовало за это.';

  courseReturns1 = '1. Возврат или обмен абонемента на курс возможен только при наличии билета и документа, удостоверяющего личность. Оформление возврата допускается до начала второго занятия курса. Для оформления возврата необходимо написать заявление по образцу, предоставленному администратором.';
  courseReturns2 = '2. Возврат стоимости абонемента осуществляется за вычетом стоимости прошедшего занятия. Стоимость одного занятия равна стоимости абонемента, разделенной на общее количество занятий.';
  courseReturns3 = '3. Возврат денежных средств осуществляется в течение 21 рабочего дня. Необходимо обратиться к администратору для расчета суммы возврата, далее — в службу поддержки (по адресу ';

  popup = false;
  eventType = 'general';

  soldOut = false;
  quantity = 0;
  choosed = 0;
  quantities = {};
  showItemsQuantity = true;

  hideScheduleDuration = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transport: TransportService,
    private logger: LoggerService,
    public global: GlobalService,
    private translate: TranslateService,
    public cart: CartService,
    private info: InfoService,
    private moneyPipe: MoneyPipe,
    public sanitizer: DomSanitizer,
    private mLang: MultilangFieldPipe
  ) {}

  ngOnInit() {

    console.log('HELLO')

    this.subscriptions = [
      this.translate.onLangChange.subscribe(data => (this.lang = data.lang)),
      this.cart.cartOutput$.subscribe(() => {
        this.syncCart(true, (this.currSchedule || this.init_event).uuid);
      }),
    ];
    this.global.showHideLoader.next(true);
    combineLatest([this.route.params, this.route.queryParams]).subscribe(([params, query]) => {
      if (query) {
        this.eventDt = query.dt;
      }
      combineLatest([
        this.transport.getEvent(params.event_uuid).pipe(
          map(eDt => {

            // console.log('EDT', eDt.tabs)

            if (!eDt) {
              return null;
            }
            this.eDt = eDt;

            console.log('EDT', eDt.tabs);

            if (eDt.description && Object.entries(eDt.description).length > 0) {

              // console.log('-------', Object.entries(eDt.description).length)

              for (const [key, value] of Object.entries(eDt.description)) {

                this.descriprion[key] = value ? value.toString().replace(/<em>/g, '<span style="font-style: italic">').replace(/<\/em>/g, '</span>') : '';

                // console.log('reps', this.descriprion[key]);
              }




              // this.descriprion.en = eDt.description.en.replace('<em>', '<span style="font-style: italic">').replace('</em>', '</span>')
              // this.descriprion.ru = eDt.description.ru;
            } else {
              this.descriprion = null//{en: '', ru: ''}
            }

            // if (this.descriprion === {}) {
            //
            // }

            console.log('Descr', this.descriprion)

            this.checkType();
            this.setEdtProps();
            this.getSchedules(true);
            this.soldOut = eDt.is_sold_out ? true : false;
            console.log('SHOWT', this.tickets)
            // if (!this.tickets) {
            //   this.tickets = [];
            // }
            if (eDt.page_components && eDt.page_components.alert && eDt.page_components.alert.length > 0) {
              this.showInfo = true;
            }

            return eDt;
          })
        ),
        query.event_hall ? this.transport.getHall(query.event_hall) : of(null),
        this.global.settings$,
      ]).subscribe(([eDt, hall, settings]) => {



        this.hall = hall;
        if (hall) {
          this.media_info = hall.media_info || [];
        }
        if (settings) {
          this.widget_settings = settings.widget_settings;

          if (this.widget_settings.hasOwnProperty('hideScheduleDuration')) {
            this.hideScheduleDuration = this.widget_settings.hideScheduleDuration;
          }

          this.isGarage = settings.name.en === 'Garage';

          console.log('SHOW ENTRANCE', this.widget_settings.showEntranceQuantity, this.widget_settings.hasOwnProperty('showEntranceQuantity'))

          // this.showItemsQuantity = this.widget_settings.showEntranceQuantity !== null ? this.widget_settings.showEntranceQuantity : true;


          if (this.widget_settings.hasOwnProperty('showEntranceQuantity')) {
            this.showItemsQuantity = this.widget_settings.showEntranceQuantity;
          }

          console.log('SHOW ITEMS QUANTTY', this.showItemsQuantity)
        }
        this.setHeadImage();
        this.global.showHideLoader.next(false);
      });
    });

  }

  ngOnDestroy() {
    this.subscriptions.map(_s => _s.unsubscribe());
  }

  setEdtProps() {
    if (this.eDt) {
      this.event_uuid = this.eDt.uuid;
      this.eDtMedia = this.eDt.media || [];
      this.eDtPoster =
        this.eDt.poster ||
        (this.eDt.posters && this.eDt.posters.showcase ? this.eDt.posters.showcase : null);
      this.media_info = this.eDt.NearestSchedule ? this.eDt.NearestSchedule.Hall.media_info : [];
    }
  }

  setHeadImage() {
    this.image_init = true;
    // console.log('LOOK FOR IMG', this.media_info[0], this.eDtMedia[0], this.eDtPoster)
    this.head_image = this.media_info[0] || this.eDtPoster || this.eDtMedia[0];
  }

  syncCart(compare = true, schedule_uuid?: string) {
    if (!(schedule_uuid || this.init_event)) return;

    const cartItems = this.cart.getEventItems(schedule_uuid || this.init_event.uuid, true);
    if (cartItems) {
      this.count = cartItems.quantity;
      this.price = cartItems.price;
      this.priceAmount = cartItems.price.amount;
    } else {
      this.priceAmount = 0;
      this.count = 0;
    }
    if (compare) {
      this.compareTickets(this.tickets, this.init_event, this.tickets_data);
    }
  }

  getSchedules(syncCart = false) {
    this.global.showHideLoader.next(true);
    this.transport
      .getSchedules(this.event_uuid)
      .toPromise()
      .then(
        schedules => {
          this.listEvents = schedules;
          let eventsCurrDate;

          eventsCurrDate = schedules.filter(item => {
            return isSameDay(item.begin, this.eventDt || new Date());
          });

          if (!eventsCurrDate.length) {
            this.global.showHideLoader.next(false);
            return;
          }

          const initEvent =
            eventsCurrDate.find(ev => ev.begin === this.eventDt) || eventsCurrDate[0];

          this.init_event = initEvent;
          this.getTickets(initEvent);

          // console.log('LOADING', this.tickets)



          const schedulesByDate = schedules.reduce((acc, s) => {
            const dateKey = format(s.begin, 'YYYY-MM-DD');
            const timeKey = this.getTimeInterval(s.begin, s.end);

            if (!prop(dateKey, acc)) {
              acc[dateKey] = {};
            }
            if (!prop(timeKey, acc[dateKey])) {
              acc[dateKey][timeKey] = [];
            }
            acc[dateKey][timeKey].push(s);
            return acc;
          }, {});

          const currDt = initEvent.begin;
          const currD = parse(currDt);
          const formatDt = format(currD, 'YYYY-MM-DD');
          const schedulesCurrDate = schedulesByDate[formatDt];
          const schedulesTimeArr = Object.keys(schedulesCurrDate);
          const currTime = this.getTimeInterval(initEvent.begin, initEvent.end);

          this.listEvents = schedules;
          this.currTime = currTime;
          this.currDt = currDt;
          this.currD = currD;
          this.currSchedule =
            schedulesCurrDate && schedulesCurrDate[currTime]
              ? schedulesCurrDate[currTime][0]
              : null;
          this.time = schedulesTimeArr.map((time, i) => {
            const event = schedulesCurrDate[time][0];

            return {
              dt: event.begin,
              value: time,
              src: event,
            };
          });
          if (syncCart) {
            this.syncCart(false, (this.currSchedule || this.init_event).uuid);
          }
          this.global.showHideLoader.next(false);
          this.logger.l(schedulesByDate);
        },
        () => {
          this.global.showHideLoader.next(false);
        }
      );
  }

  getTickets(event) {
    this.global.showHideLoader.next(true);
    console.log('TESTING ticks', this.tickets)
    this.transport
      .getTickets(event.uuid)
      .toPromise()
      .then(
        data => {

          console.log('WE GET TICKETSDATA', data);

          this.tickets_data = data;
          const { tickets, prices } = data;
          if (tickets && tickets.length > 0) {
            this.quantity = tickets[0].quantity;
          }
          console.log ('TTTT', tickets, this.quantity)
          this.compareTickets(tickets, event, data);
          this.prices = prices;

          console.log('TESTING ticks', tickets, this.tickets)

          this.tickets = tickets;
          // if (!this.tickets) {
          //   this.tickets = [];
          // }
          console.log('TICKS', this.tickets)



          // console.log('WARNING', this.prices, this.tickets)
          this.global.showHideLoader.next(false);
        },
        () => {
          this.global.showHideLoader.next(false);
        }
      );



  }

  onSelectTime(data, e: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (data.src && data.src.is_sold_out) {
      return;
    }
    this.currTime = data.value;
    this.currDt = data.dt;
    this.currD = parse(data.dt);

    const event = this.listEvents.filter(e => {
      return e.begin === data.dt;
    });
    this.currSchedule = event[0];
    this.getTickets(event[0]);
    this.syncCart(false, event[0].uuid);
    this.showTime = false;
  }

  compareTickets(tickets = [], event, data) {



    const out = [];



    const settings = this.global.settings;
    const limit = settings ? settings.widget_settings.limits.tickets_in_order : undefined;

    const ticketDefaultTitle = {
      ru: 'Входной билет',
      en: 'Entry ticket',
    };

    if (tickets.length) {

      console.log('HERE ARE MY TICKETS', tickets)

      tickets
        .sort((_tA, _tB) => {
          let aPrice = this.cart.getBasePrice(Object.values(data.prices), _tA.price_values);
          if (!aPrice && _tA.price_values && _tA.price_values.length > 0) {
            for (const uuid of _tA.price_values) {
              if (uuid in data.prices) {
                aPrice = data.prices[uuid];
                break;
              }
            }
          }

          let bPrice = this.cart.getBasePrice(Object.values(data.prices), _tB.price_values);
          if (!bPrice && _tB.price_values && _tB.price_values.length > 0) {
            for (const uuid of _tB.price_values) {
              if (uuid in data.prices) {
                bPrice = data.prices[uuid];
                break;
              }
            }
          }

          const aAmount = aPrice ? aPrice.amount : 0;
          const bAmount = bPrice ? bPrice.amount : 0;
          return aAmount - bAmount;
        })
        .forEach(ticket => {

          const ID = ticket.uuid;

          this.quantities[ID] = {quantity: ticket.quantity, selected: 0};

          // Object.create([ID]: );


          this.choosed = 0;
          const pricesForTicket = ticket.price_values.reduce(
            (a, priceValueUuid) =>
              data.prices[priceValueUuid] ? [...a, ...data.prices[priceValueUuid]] : a,
            []
          );
          pricesForTicket.forEach(price => {
            let cartItem;
            // console.log('TOTAL ITEMS ARE', ticket)
            if (this.cart.total_items) {
              console.log('trying');
              cartItem = this.cart.findCartItem(ticket, price);
            }

            const src = {
              ...ticket,
              schedule: {
                Event: this.eDt,
                ...event,
                ...this.eDt.NearestSchedule,
              },
            };

            const ticketQuantity = +(ticket.quantity || 0);
            const cartItemQuantity: number = cartItem ? cartItem.quantity : 0;

            let max_count = ticketQuantity;

            if (!(limit === null || limit === undefined)) {
              max_count = Math.min(
                limit - this.cart.total_items + cartItemQuantity,
                ticketQuantity
              );
            }

            this.quantities[ID].selected += cartItemQuantity;

            console.log('data.prices', max_count, cartItemQuantity, ticketQuantity, cartItem);

            out.push({
              src,
              disabled: !ticket.is_for_sale,
              id: ticket.uuid,
              max_count,
              value: price.amount,
              // 'time': this.time.length > 1 ? false : this.time[0] ? this.time[0].value : undefined,
              title: price.PriceCategory ? price.PriceCategory.name : ticketDefaultTitle,
              title_value: `${this.moneyPipe.transform(price.amount, price.amount_currency)}`,
              description: price.PriceCategory.description ? price.PriceCategory.description : '',
              count: cartItem ? cartItem.quantity : 0,
              priceValueUuid: price.uuid,
            });
            // this.choosed += cartItem ? cartItem.quantity : 0;
            // console.log('lookup', out)
          });
          this.choosed = 0;
          out.forEach(elem => {
            this.choosed += elem.count;
          })
        });
    } else {
      console.log('OUTTER', out)
    }

    // console.log(out);

    this.comparedTickets = out;

    console.log('QANTITIES are', this.quantities)


    console.log('OUT', out);
    console.log('CT', this.choosed)
  }

  getPrice(ticket, priceValueUuid?: string) {
    return this.prices[priceValueUuid || ticket.price_values[0]];
  }

  getTimeInterval(begin, end) {
    if (this.widget_settings.hide_shedule_end) {
      return `${format(begin, 'HH:mm')}`;
    }
    return `${format(begin, 'HH:mm')}-${format(end, 'HH:mm')}`;
  }

  confirm() {
    if (this.count === 0) return;

    this.global.ticketDt = this.currDt;
    this.router.navigate(['order/activate']);
  }

  toggleTime() {
    this.showTime = !this.showTime;
  }

  incrementTicket(item) {
    this.cart.addItem(item.src, this.getPrice(item.src, item.priceValueUuid), this.prices);
  }

  decrementTicket(item) {
    this.cart.removeItem(item.src, this.getPrice(item.src, item.priceValueUuid));
  }

  checkType() {








    // console.log('head', this.infoMessages);

    if (!this.eDt) {
      return;
    }

    if (this.eDt.page_components && this.eDt.page_components.alert && this.eDt.page_components.alert.length > 0) {
      this.infoMessages = this.eDt.page_components.alert;
    }

    // console.log ('WHILE', this.eDt)

    this.header = (this.eDt.short_name || this.eDt.name).ru.toLowerCase();


    if (this.header.includes('кинопоказ') || this.header.includes('гараж скрин')) {
      this.eventType = 'cinema';
    }

    if (this.header.includes(' курс') || this.header.includes('хроника новейшего кино') ||
      this.header.includes(' практика') || this.header.includes('архитектурные наброски') ||
      this.header.includes('онлайн-лекция')) {
      this.eventType = 'course';
    }

    if (this.header.includes('экскурсии')) {
      this.eventType = 'excursion';
    }

    if (this.header.includes('концерт')) {
      this.eventType = 'concert';
    }

    // if (this.descriprion.ru && this.descriprion.ru.includes('«Фонокинетоскоп»')) {
    //   this.eventType = 'photoDisplay';
    // }
  }

  openPopup() {
    if (this.eventType === 'course') {
      this.popup = true;
    }
  }

  getDisclaimer(): IMultilangString {

    if (this.header.includes('кинопоказ') || this.header.includes('гараж скрин')) {
      this.eventType = 'cinema';
      return this.garageMovie;
    }

    if (this.header.includes(' курс') || this.header.includes('хроника новейшего кино') ||
      this.header.includes(' практика') || this.header.includes('архитектурные наброски') ||
      this.header.includes('онлайн-лекция')) {
      this.eventType = 'course';
      return this.garagePopup;
    }

    if (this.header.includes('экскурсии')) {
      this.eventType = 'excursion';
      return this.garageAnounsmentEx;
    }

    if (this.header.includes('концерт')) {
      this.eventType = 'concert';
      return this.garageAnounsmentCon;
    }

    // if (this.descriprion.ru && this.descriprion.ru.includes('«Фонокинетоскоп»')) {
    //   this.eventType = 'photoDisplay';
    //   return this.garagePhoto;
    // }

    return this.garageAnounsment;
  }
}
