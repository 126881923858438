import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { LoggerService } from '../../services/logger.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
})
export class TranslateComponent implements OnDestroy {
  defaultLang = 'ru';
  selectedLang: string;
  langInit: Subscription;

  constructor(
    private translate: TranslateService,
    public global: GlobalService,
    private logger: LoggerService,
    private route: ActivatedRoute,
    private storage: StorageService
  ) {
    if (this.global.selectedLang) {
      this.selectedLang = this.global.selectedLang;
    } else {
      this.langInit = this.global.langInit.subscribe(lang => {
        this.selectedLang = lang;
        if (this.langInit) {
          this.langInit.unsubscribe();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.langInit) {
      this.langInit.unsubscribe();
    }
  }

  switchLanguage(language: string) {
    this.selectedLang = language;
    this.global.switchLanguage(language, this.translate);
  }
}
