import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter, map } from 'rxjs/operators';
import { StorageService } from './storage.service';
import {ISettings} from '../types/Entities';

@Injectable({
  providedIn: 'root',
})
export class SecureApiService {
  private auth_token: string;

  constructor(
    public global: GlobalService,
    private http: HttpClient,
    private storage: StorageService
  ) {}

  private save_token(token: string) {
    if (token && token !== '') {
      this.auth_token = token;
      this.storage.setItem('x-auth-token', token);
    }
  }

  private get_token() {
    return this.storage.getItem('x-auth-token');
  }

  public apiRunner(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    uri: string,
    body?: any
  ): Observable<any> {
    let httpHeaders = new HttpHeaders();

    if (body) {
      httpHeaders = httpHeaders.append('content-type', 'application/json');
    }

    const httpParams = new HttpParams();
    httpParams.append('observe', 'response');

    const httpRequest = new HttpRequest(
      method,
      this.global.serverCertificateUrl + uri,
      httpHeaders
    );

    const options = {
      responseType: 'json' as 'json',
      headers: httpHeaders,
      observe: 'response' as 'response',
    };
    if (body) {
      options['body'] = body;
    }

    if (this.global.settings) {
      if (this.get_token()) {
        httpHeaders = httpHeaders.append('authorization', `Bearer ${this.get_token()}`);
      }
      options.headers = httpHeaders;
      return this.http
        .request(method, this.global.serverCertificateUrl + uri, options)
        .pipe(filter(e => e instanceof HttpResponse))
        .pipe(
          map((e: any) => {
            this.save_token(e.headers.get('x-auth-token'));
            return e.body;
          })
        );
    } else {
      return fromPromise(
        this.getSettings()
          .toPromise()
          .then(d => {
            this.save_token(d.headers.get('x-auth-token'));
            if (this.get_token()) {
              httpHeaders = httpHeaders.append('authorization', `Bearer ${this.get_token()}`);
            }
            this.global.setSettings({...d.body, widget_settings: {...d.body.widget_settings}} as ISettings);
            options.headers = httpHeaders;
            return this.http
              .request(method, this.global.serverCertificateUrl + uri, options)
              .toPromise()
              .then((d: any) => {
                this.save_token(d.headers.get('x-auth-token'));
                return d.body;
              });
          })
      );
    }
  }

  public getSettings(): Observable<HttpResponse<any>> {
    return this.http.get(this.global.serverCertificateUrl + 'settings', { observe: 'response' });
  }
}
