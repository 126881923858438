import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CartService, ICartScheduleStorageItem} from '../../services/cart.service';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../services/global.service';
import {ICartItem, IPriceValue} from '../../types/Entities';
import {differenceInCalendarYears, parse} from 'date-fns';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';
import {ISelectItem} from '../select/select.component';

export interface ITicketPriceValues {
  [ticket_uuid: string]: ISelectItem[];
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less'],
})
export class CartComponent implements OnInit, OnChanges, OnDestroy {

  @Input('type') type: 'popup' | 'order' = 'order';
  @Input() validateCartItems$: Observable<string[]>;
  @Output() changeVisitors: EventEmitter<any> = new EventEmitter<any>();

  events;
  schedules: ICartScheduleStorageItem[];
  lang = this.translate.currentLang;
  subscriptions = [];
  cart_items: ICartItem[];
  priceValue: IPriceValue;
  visitors = {};
  visitorsErrors = {};
  changeVisitorsSource = new BehaviorSubject(null);
  changeVisitors$ = this.changeVisitorsSource.pipe(switchMap(v => of(v).pipe(delay(600))));
  restrictEvent: any = null;
  public priceValues: ITicketPriceValues = {};
  public selectedPriceValues: {[ticket_uuid: string]: IPriceValue} = {};
  public showPrivileges = false;
  isGarage = false;
  excUid = '961a411d-acc9-4f46-85c1-99abac299644';


  garagePrivileges = { en: 'Discount', ru: 'Скидка'};

  restrictionDescription = [];

  constructor(
    public cart: CartService,
    public translate: TranslateService,
    public global: GlobalService
  ) {
  }

  ngOnInit() {




    this.subscriptions = [

      this.global.settings$.subscribe(s => {
        this.showPrivileges = s.widget_settings.showPrivileges;
        this.isGarage = s.name.en === 'Garage';
      }),
      this.cart.cartUpdateSchedules$.subscribe(() => {
        this.setAndSortSchedules();
      }),
      this.cart.cartSync$.subscribe(() => {
        this.setAndSortSchedules();
      }),
      this.translate.onLangChange.subscribe(data => {
        this.lang = data.lang;
      }),
      this.changeVisitors$.subscribe(v => {

        // console.log('VISITORS', v)

        if (!v) {

          return;
        }

        if (!this.visitors[v.uuid]) {
          this.visitors = Object.assign(this.visitors, { [v.uuid]: {name: v.value} });
          this.changeVisitors.emit(this.visitors);
        }

        console.log(v, 'READY TO VALIDATE')
        this.saveVisitorsToStorage();
        if (this.visitors[v.uuid].birthday) {
          this.validateVisitor(v.uuid, false);
        } else {
          this.validateVisitorv2(v.uuid, false);
        }
        this.changeVisitors.emit(this.visitors);
      }),
      this.global.cartRestriction.subscribe(data => {
        this.onRestriction(data);
      })
    ];

    if (this.type !== 'popup') {
      this.cart.syncCart();
      this.subscriptions.push(
        this.cart.cartOutput$.subscribe(() => {
          this.cart.syncCart();
        })
      );
    }

    this.getVisitorsFromStorage();
    this.setAndSortSchedules();

    console.log('ITEMS', this.cart_items)

    // this.cart.cart_schedules_storage.forEach(item => {
    //   console.log('SINGLE ITEM', item, item.PriceValue.PriceCategory.sale_settings)
    //
    //   // const obj = {[item.items[0].uuid]: { name: '', birthday: '' }};
    //
    //   // console.log('OOOOOBJ',obj)
    //   if (item.PriceValue.PriceCategory.sale_settings && item.PriceValue.PriceCategory.sale_settings.ticket) {
    //     // this.visitors = Object.assign(this.visitors, obj);
    //     // this.changeVisitors.emit(this.visitors);
    //   }
    //
    // console.log('VISITS', this.visitors, )
    // })

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validateCartItems$ && changes.validateCartItems$.currentValue) {
      this.subscriptions.push(
        changes.validateCartItems$.currentValue.subscribe((keys: string[]) => {
          (keys || []).forEach(key => {
            if (this.visitors[key].birthday) {
              this.validateVisitor(key);
            } else {
              this.validateVisitorv2(key, false);
            }

          });
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(_s => _s.unsubscribe());
  }

  sortSchedules() {
    this.schedules
      .filter(_s => !_s.Schedule.has_geometry)
      .map(_s => {
        _s.items.sort((_aItem, _bItem) => {
          return _aItem.PriceValue.amount - _bItem.PriceValue.amount;
        });
      });
    this.schedules
      .filter(_s => _s.Schedule.has_geometry)
      .map(_s => {
        _s.items.sort((_aItem, _bItem) => {
          const aPlace = _aItem.ProductItem.Place;
          const bPlace = _bItem.ProductItem.Place;
          const aRowName = aPlace.meta.row.name || '';
          const bRowName = bPlace.meta.row.name || '';

          const aRow = parseInt((aRowName.match(/\d+/) || ['1'])[0], 10);
          const bRow = parseInt((bRowName.match(/\d+/) || ['1'])[0], 10);

          return aRow - bRow;
        }).sort((_aItem, _bItem) => {
          const aPlace = _aItem.ProductItem.Place;
          const bPlace = _bItem.ProductItem.Place;
          const aSeatName = aPlace.meta.seat || '';
          const bSeatName = bPlace.meta.seat || '';

          const aSeat = parseInt((aSeatName.match(/\d+/) || ['1'])[0], 10);
          const bSeat = parseInt((bSeatName.match(/\d+/) || ['1'])[0], 10);

          return aSeat - bSeat;
        });
      });

  }

  setPriceValues() {
    this.priceValues = Object.keys(this.cart.priceValues).reduce((acc, ticket_uuid) => {
      const priceValues = this.cart.priceValues[ticket_uuid];
      acc[ticket_uuid] = priceValues
        .sort((aPriceItem, bPriceItem) => {
          const a = aPriceItem.is_base_price ? 1 : 0;
          const b = bPriceItem.is_base_price ? 1 : 0;
          return a - b;
        })
        .map(PriceValue => {
          const cartItem: ICartItem = this.cart.getCartItem(ticket_uuid);
          const {ProductItem} = cartItem;
          const selectedPrice: IPriceValue = this.cart.selectedPriceValues[ProductItem.uuid];
          const isBasePrice = PriceValue.is_base_price;
          const selectItem: ISelectItem = {
            name: isBasePrice ? {ru: 'Без льгот'} : PriceValue.PriceCategory.name,
            description: PriceValue.PriceCategory.description,
            value: PriceValue.uuid,
            src: {cartItem, PriceValue}
          };
          this.selectedPriceValues[ProductItem.uuid] = selectedPrice ? {...selectedPrice} : (isBasePrice ? PriceValue : undefined);
          this.selectedPriceValues = {...this.selectedPriceValues};

          return selectItem;
        });
      return acc;
    }, {});
  }

  setSchedules() {
    const settings = this.global.settings;
    const limit = settings ? settings.widget_settings.limits.tickets_in_order : undefined;
    this.cart_items = this.cart.cart_items;
    this.priceValue = this.cart_items && this.cart_items[0] ? this.cart_items[0].PriceValue : null;
    this.schedules = [...this.cart.cart_schedules_storage];
    // console.log('Нам передают вот что', this.cart)

    this.schedules.map(_s => {
      if (!_s.Schedule.has_geometry) {
        _s.items.map(_item => {

          const ticketQuantity = +(_item.ProductItem.quantity || 0);
          const cartItemQuantity: number = _item.quantity;
          _item.max_count = ticketQuantity;
          if (!(limit === null || limit === undefined)) {
            _item.max_count = Math.min(limit - this.cart.total_items + cartItemQuantity, ticketQuantity);
          }
        });
      }
    });
  }

  setAndSortSchedules() {
    // console.log('А вот и причина', this.cart.cart_items, this.cart.cart_items.length)
    if (!this.cart.cart_items.length) {
      return;
    }
    this.setSchedules();
    this.sortSchedules();
    this.setVisitors();
    this.setPriceValues();
  }

  setVisitors() {
    if (this.global.settings.widget_settings.showCovidMessages) {
      this.cart_items.forEach(item => {
        if (item.uuid) {
          this.visitors[item.uuid] = this.visitors[item.uuid] || {};
        }
        // this.visitorsErrors[item.uuid] = {};
      });
      Object.keys(this.visitors || {}).forEach(key => {
        if (!this.cart_items.find(item => item.uuid === key)) {
          delete this.visitors[key];
        }
      });
      this.changeVisitors.emit(this.visitors);
    }
  }

  setVisitorsv2() {
    this.cart.cart_schedules_storage.forEach(item => {
      if (item.Event.uuid) {
        this.visitors[item.Event.uuid] = this.visitors[item.Event.uuid] || {};
      }
      // this.visitorsErrors[item.uuid] = {};
    });
    Object.keys(this.visitors || {}).forEach(key => {
      if (!this.cart_items.find(item => item.uuid === key)) {
        delete this.visitors[key];
      }
    });
    this.changeVisitors.emit(this.visitors);
  }

  getPlaceName(sIndex, index, endSymbol = '') {
    const storage = this.schedules;
    const prop =
      storage[sIndex].items[index].ProductItem.Place.meta.sector ||
      storage[sIndex].items[index].ProductItem.Place.meta.fragment;
    const name = prop.name;
    const langName = name[this.lang];

    return langName && langName.trim().length ? langName + endSymbol : '';
  }

  onVisitorsChange(v: any, uuid: string, name?: string) {
    this.changeVisitorsSource.next({value: v, uuid, name});
  }

  validateVisitor(key: string, validateEmpty = true) {
    this.visitorsErrors[key] = {};
    console.log('vis', this.visitors, key)
    const name = (this.visitors[key].name || '').trim();
    const dt = (this.visitors[key].birthday || '').trim();
    const dtArr = dt.split('.');

    const d = parse(`${dtArr[1]}/${dtArr[0]}/${dtArr[2]}`);

    const correctDate = this.isValidDate(`${dtArr[1]}/${dtArr[0]}/${dtArr[2]}`);

    const now = Date.now();
    const age = differenceInCalendarYears(now, d);
    const err = this.visitorsErrors[key] || {};
    const validDt = correctDate && dt.match(/^\d\d\.\d\d\.\d\d\d\d$/) && age > 0;

    if ((!dt || !name) && validateEmpty) {
      err.invalid = true;
    } else if (dt && !validDt) {
      err.invalidDate = true;
    } else if (age >= 65) {
      err.maxAgeError = true;
    }
    this.visitorsErrors[key] = err;
  }

  validateVisitorv2(key: string, validateEmpty = true) {
    this.visitorsErrors[key] = {};
    console.log('vis v2', this.visitors, key)

    const name = (this.visitors[key].name || '').trim();

    const err = this.visitorsErrors[key] || {};

    if (!name && validateEmpty) {
      err.invalid = true;
    }

    this.visitorsErrors[key] = err;

    console.log('validation errors', err, this.visitorsErrors[key])
  }

  private isValidDate(date): boolean {
    const d = new Date(date);
    let [month, day, year] = date.split('/');

    --month;

    return d.getFullYear() === +year && d.getMonth() === +month && d.getDate() === +day;

  }



  public displayItem(item): Number {
    return item.price ? item.price * item.quantity : item.total_cost;
  }

  public displayTotal(): Number {
    return this.cart.total_cost;
  }

  onRestriction(data) {

    this.restrictionDescription = this.getRestrictionDescription(data);
    this.restrictEvent = data[0].enitity[0].value;
    this.cart.restrictionIsValid = false;
  }

  getRestrictionDescription(data): string[] {

    if (!data) {
      return [];
    }

    const res = [];

    data.forEach(elem => {
      if (!elem.functional) {
        res.push('');
      }

      const countDescription = elem.functional.count ? elem.functional.count : false;

      if (countDescription) {
        let from = -1;
        let till = -1;
        let equal = 0;

        let form = '';

        countDescription.forEach(el => {
          switch (el.equality) {
            case '>':
              from = +el.value;
              break;

            case  '<':
              till = +el.value;
              break;

            case '=':
              equal = +el.value;
              break;
          }
        });

        // КОНКРЕТНОЕ КОЛИЧЕСТВО БИЛЕТОВ
        if (equal > 0) {
          form = this.getTicketsLexiForm(equal);
          res.push(`Необходимо купить ${equal} ${form} на сеанс`);
        }

        // ПРОСТО КУПИТЬ БИЛЕТЫ
        if (from === 0 && till < 0) {
          res.push('Необходимо купить билеты на сеанс');
        }

        // НЕ МЕНЕЕ СКОЛЬКИ-ТО БИЛЕТОВ
        if (from > 0 && till < 0) {
          form = this.getTicketsLexiForm(+from + 1);
          res.push(`Необходимо купить не менее ${+from + 1} ${form} на сеанс`);
        }

        // НЕ БОЛЕЕ СКОЛЬКИ-ТО БИЛЕТОВ
        if (from <= 0 && till > 0) {
          form = this.getTicketsLexiForm(+till - 1);
          res.push(`Необходимо купить не более ${+till - 1} ${form} на сеанс`);
        }

        // КОЛИЧЕСТВО В ИНТЕРВАЛЕ БИЛЕТОВ
        if (from > 0 && till < 0) {
          form = this.getTicketsLexiForm(+till - 1);
          res.push(`Необходимо купить от ${+from + 1} до ${+till - 1} ${form} на сеанс`);
        }
      }
    });

    return res;
  }

  getTicketsLexiForm(count): string {
    const main = 'билет';
    let ending = '';
    let tens = 0;
    let ones: number;

    if (count.toString().length > 1) {
      tens = count.toString()[count.toString().length - 2];
    }
    ones = count.toString()[count.toString().length - 1];

    if (+tens === 1) {
      ending = 'ов';
    } else {
      switch (+ones) {

        case 1:
          ending = '';
          break;

        case 2:
        case 3:
        case 4:
          ending = 'а';
          break;

        case 0:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          ending = 'ов';
          break;
      }
    }


    return `${main}${ending}`;
  }

  onPriceValueChange(item: ISelectItem) {
    this.cart.changItemPriceValue(item.src.cartItem.ProductItem, item.src.PriceValue);
    this.selectedPriceValues[item.src.cartItem.ProductItem.uuid] = item.src.PriceValue;
    // console.log('onPriceValueChange', this.selectedPriceValues);
  }

  saveVisitorsToStorage() {
    localStorage.setItem('visitors', JSON.stringify(this.visitors));
  }

  getVisitorsFromStorage() {
    this.visitors = JSON.parse(localStorage.getItem('visitors') || '{}');
  }
}
