import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { LoggerService } from '../../services/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../../services/cart.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavService } from '../../services/nav.service';
import { ResizedEvent } from 'angular-resize-event';
import { IMultilangString, ISettings, ISettingsRoutes, IWidgetSettings } from '../../types/Entities';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() nameEvent: string;
  @Input() dateEvent: string;
  @Input() placeEvent: string;
  @Input() type: string;
  @Input() title: string;
  @Input() title2: IMultilangString;
  @Input() sub_title: string;
  @Input() nav: Array<any>;
  @Input() activeItem: string;
  @Input() showCartIcon = true;
  @Input() activeSearch =
    this.navService._state && this.navService._state.current.name === 'search';
  @Input() searchText = '';
  @Input() focusSearch = false;
  @Input() showNav = false;
  @Input() showPromo = true;
  @Input() showCompanyLogo = false;
  @Input() showBackBtn = true;
  @Input() showSearch = false;
  @Input() showCert = true;
  @Input() showCloseFrame = true;
  @Input() bottomContent = false;
  @Input() transparent = false;
  @Input() fixed = false;
  @Input() backRoute;
  @Input() theme;
  isGarage = false;

  @Output() viewInit: EventEmitter<any> = new EventEmitter();
  @Output() popupOpen: EventEmitter<any> = new EventEmitter();
  @Output() selectFilter: EventEmitter<any> = new EventEmitter();
  @Output() searchFilter: EventEmitter<any> = new EventEmitter();
  @Output() viewSearch: EventEmitter<any> = new EventEmitter();
  @Output() toggleSearch: EventEmitter<any> = new EventEmitter();
  @Output() searchSubmit: EventEmitter<any> = new EventEmitter();
  @Output() resizedEv: EventEmitter<ResizedEvent> = new EventEmitter<ResizedEvent>();
  @Output() resizedH: EventEmitter<ResizedEvent> = new EventEmitter<ResizedEvent>();

  @ViewChild('header', { static: false }) header: ElementRef;

  subscriptions: Subscription[] = [];
  navItemClickSource: Subject<any> = new Subject<any>();
  navItemClick$: Observable<any> = this.navItemClickSource.asObservable();
  currentPage: string;
  historyLength = 0;
  dragScrollWidth: 320;
  cart_popup = false;
  inIframe = window.self !== window.top;
  widget_alias: string;
  updateSearchValueTimeOut = null;
  searchForm: FormGroup;
  settings: ISettings;
  routes: ISettingsRoutes;
  indexPage: string;
  tags = this.nav;
  cartPopupSubscription: Subscription;
  size: ResizedEvent;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    public router: Router,
    private logger: LoggerService,
    private elRef: ElementRef,
    public global: GlobalService,
    public cart: CartService,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    public navService: NavService
  ) {
    this.logger.l(this.router.url, this.translate.currentLang);

    this.searchForm = this.formBuilder.group({
      text: ['', []],
    });

    const schedulesPage = /\/events\/.+\/schedules/;
    const descriptionPage = /\/events\/.+\/description/;
    const resRegExsSchedulesPage = schedulesPage.exec(this.router.url);
    const isEventSchedulePage = resRegExsSchedulesPage ? resRegExsSchedulesPage[0] : false;
    const resRegExsDescriptionPage = descriptionPage.exec(this.router.url);
    const isEventDescriptionPage = resRegExsDescriptionPage ? resRegExsDescriptionPage[0] : false;

    this.global.settings$.subscribe(s => {
      this.settings = s;
      this.routes = s.widget_settings.routes;
      this.indexPage = this.routes && this.routes.index ? this.routes.index : 'events';
    });

    this.isGarage = this.global.settings.name.ru === 'Garage';

    this.route.queryParams.subscribe(params => {
      // console.log('PARAMS', params)
      this.widget_alias = params.widget_alias;


    });

    switch (this.router.url.replace(/\/?\?.*/, '')) {
      case this.indexPage:
        this.currentPage = 'index';
        break;
      case '/gift/activate':
        this.currentPage = 'giftActivate';
        break;
      case '/events':
        this.currentPage = 'events';
        break;
      case '/gift':
        this.currentPage = 'gift';
        break;
      case '/order/confirm':
        this.currentPage = 'orderConfirm';
        break;
      case '/order/activate':
        this.currentPage = 'orderActivate';
        break;
      case isEventSchedulePage || isEventDescriptionPage:
        this.currentPage = 'event';
        break;
    }

    this.logger.l(this.currentPage);
  }

  ngOnInit() {

    // console.log('URL', this.router.url, this.router.url.includes('result'))

    // console.log('tags detected', this.nav);

    if (this.nav) {
      this.tags = this.nav;
    }

    this.searchForm.get('text').valueChanges.subscribe(text => {
      this.updateSearch(text);
    });
    if (this.searchText) {
      this.searchForm.get('text').setValue(this.searchText);
    }
    this.historyLength = window.history.length;
  }

  ngAfterViewInit() {
    // this.navItemClickSource = new Subject<any>();
    // this.navItemClick$ = this.navItemClickSource.asObservable();
    this.viewChange();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(_s => _s.unsubscribe());
  }

  viewChange() {
    setTimeout(() => {
      this.viewInit.emit(this.header);
    }, 200);
  }

  onNavItemClick(e, filter: string, tag) {
    console.log('CLICK NAV TAB', filter)
    this.activeFilter(filter, tag);
    this.navItemClickSource.next({
      width: e.currentTarget.clientWidth,
      offsetLeft: e.currentTarget.offsetLeft,
    });
  }

  back() {
    if (this.backRoute) {
      this.router.navigate([this.backRoute]);
    } else {
      this.global.backLocation();
    }
  }

  popup() {
    this.popupOpen.emit(null);
  }

  toggleCartPopup() {
    this.cart_popup = !this.cart_popup;
    this.global.showHideCartPopup.next(this.cart_popup);
    if (this.cart_popup) {
      this.cartPopupSubscription = this.global.showHideCartPopup.subscribe(show => {
        if (!show && this.cartPopupSubscription) {
          this.cart_popup = show;
          this.cartPopupSubscription.unsubscribe();
        }
      });
    }
  }

  activeFilter(tp: string, tag: any) {
    this.activeItem = tp;
    this.selectFilter.emit(tag);
    // console.log('this is tp===============', tp);
    const path = tp === 'all' ? '' : `?hall=${tp}`;

    this.router.navigateByUrl(`/events${path}`);
  }

  onViewSearch(x: boolean, toggle?: boolean) {
    this.activeSearch = toggle ? !this.activeSearch : x;
    this.toggleSearch.emit(this.activeSearch);

    if (!this.activeSearch) {
      // this.activeItem = 'all';
      clearTimeout(this.updateSearchValueTimeOut);
      this.viewSearch.emit(this.searchText);
      this.searchText = '';
      // this.selectFilter.emit('all');
    }
  }

  onSearchSubmit() {
    this.searchSubmit.emit(this.searchForm.value.text);
  }

  onResized(e: ResizedEvent) {
    this.size = e;
    this.resizedEv.emit(e);
    this.viewChange();
    if (this.size.newHeight !== this.size.oldHeight) {
      this.resizedH.emit(e);
    }
  }

  updateSearch(e) {
    const delay = this.updateSearchValueTimeOut ? 800 : 0;
    clearTimeout(this.updateSearchValueTimeOut);
    this.updateSearchValueTimeOut = setTimeout(() => {
      // if (this.searchText.length > 2 || this.searchText.length === 0) {
      this.searchFilter.emit(this.searchForm.value.text);
      // }
    }, delay);
  }

  closeFrame() {
    try {
      window.top.postMessage(
        'cert-widget-close-popup',
        this.global.settings.widget_settings.parent_origin_url || location.origin
      );
    } catch (e) {
      console.error(e);
    }
  }

  toGarage() {
    window.location.href = 'https://garagemca.org/';
  }

  getTitle() {
    // console.log('TTL', this.title2);
    return this.title2;
  }
}
