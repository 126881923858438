import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-hall-schema-controls',
  template: `
    <div class="zoom-block">
      <div
        class="control-button plus"
        (click)="zoomIncrease($event)"
        [ngClass]="{ disable: zoomLevel >= 9 }"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z"
            fill="#CBA150"
          />
        </svg>
      </div>
      <div
        class="control-button minus"
        (click)="zoomDecrease($event)"
        [ngClass]="{ disable: zoomLevel === 1 }"
      >
        <svg
          width="18"
          height="3"
          viewBox="0 0 12 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="#fff" d="M0 0h12v2H0z" />
        </svg>
      </div>
    </div>
    <div>
      <div class="control-button info" (click)="info($event)">?</div>
    </div>
  `,
  styleUrls: ['./controls.component.less'],
})
export class HallSchemaControlsComponent implements OnInit {
  @Input('zoomSubject') zoomSubjectSource: Subject<number>;
  @Input('infoSubject') infoSubjectSource: Subject<any>;
  @Input('zoomLevel$') zoomLevel$: Observable<number>;
  @Input() zoomControlsDisabled = false;

  public zoomLevel = 0;

  constructor() {}

  public ngOnInit() {
    if (this.zoomLevel$) {
      this.zoomLevel$.subscribe(lvl => {
        this.zoomLevel = lvl;
      });
    }
  }

  public zoomIncrease(ev) {
    ev.stopPropagation();
    console.log('watch',ev)
    ev.preventDefault();
    if (this.zoomSubjectSource && !this.zoomControlsDisabled) {
      this.zoomSubjectSource.next(1);
    }
  }

  public zoomDecrease(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    if (this.zoomSubjectSource && !this.zoomControlsDisabled) {
      this.zoomSubjectSource.next(-1);
    }
  }

  public info(ev) {
    if (this.infoSubjectSource) {
      this.infoSubjectSource.next('info');
    }
  }
}
