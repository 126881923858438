import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { SecureApiService } from '../../services/secure-api.service';

interface IFnData {
  id: any;
  show: boolean;
}

@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.less'],
})
export class DocComponent implements OnInit {
  @Input('src') src;
  @Input('id') id;
  @Input('srcHtml') srcHtml;

  html: string;
  show = false;

  constructor(
    public httpClient: HttpClient,
    private api: SecureApiService,
    public global: GlobalService
  ) {}

  ngOnInit() {
    this.global.showHideDoc$.subscribe((data: IFnData) => {
      if (data.id !== this.id) return;

      if (data.show) {
        const headers = new HttpHeaders()
          .set('Accept', 'text/html')
          .set('Content-Type', 'text/html')
          .set('responseType', 'text/html');

        if (this.src) {
          this.httpClient
            .get(this.src, { headers, responseType: 'text' })
            .toPromise()
            .then(
              res => {
                this.html = res;
              },
              err => {
                this.html = err.error.toString();
              }
            );
        }
        this.global.toggleBodyOverflow(false);
      } else {
        this.global.toggleBodyOverflow(true);
      }

      this.show = data.show;
    });
  }
}
