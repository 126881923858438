import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';


export interface IWarningPopupShowHide {
  show?: boolean;
  id?: string;
}

@Injectable({
  providedIn: 'root'
})
export class WarningPopupService {
  private showHideSource: BehaviorSubject<IWarningPopupShowHide> = new BehaviorSubject<IWarningPopupShowHide>(null);
  public showHide$: Observable<IWarningPopupShowHide> = this.showHideSource.asObservable();

  constructor() { }

  show(data: IWarningPopupShowHide) {
    this.showHideSource.next({show: true, ...data});
  }

  hide(data: IWarningPopupShowHide) {
    this.showHideSource.next({show: false, ...data});
  }
}
