import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-banner',
  templateUrl: './event-banner.component.html',
  styleUrls: ['./event-banner.component.less']
})
export class EventBannerComponent implements OnInit {

  open: boolean = true;
  @Input() special: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  hide() {
    this.open = false;
  }


}
