import { EventEmitter, Injectable, Renderer2 } from '@angular/core';
import { ILanguages } from '../types/ILanguages';
import { IInfoPopup } from '../types/IInfoPopup';
import { IOrder } from '../types/IOrder';
import { ReplaySubject, BehaviorSubject, Subject, merge, Observable } from "rxjs";
import { IEvent } from '../types/IEvent';
import { ICart } from '../types/ICart';
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { ISettings } from '../types/Entities';
import { IBackground } from '../directives/background.directive';
import { DeviceInfo } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { filter, map } from "rxjs/operators";
import set = Reflect.set;

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public initDefaultLang = 'ru';
  public defaultLang = 'ru';
  public selectedLang: string;
  public showHideLoader = new ReplaySubject<boolean>();
  public showHideInfo = new Subject<IInfoPopup>();
  public showHideConfirmPopup = new Subject<any>();
  public showHideCartPopup = new Subject<any>();
  public showCartPopup = false;
  public mapInfo = new ReplaySubject<any>(0);
  public blur = new ReplaySubject<any>(0);
  public langChange = new BehaviorSubject<string>(this.defaultLang);
  public langInit = new ReplaySubject<string>(0);
  public showHideDoc$ = new Subject();
  public showHideInfoPopup$ = new Subject();
  public eventChange$ = new Subject<any>();
  public hallChange$ = new Subject<any>();
  public background$: Subject<IBackground> = new ReplaySubject<IBackground>();
  public bodyOverflow$: Subject<boolean> = new ReplaySubject<boolean>();

  public settings: ISettings;
  public routes: any = {};

  public serverUrl = '/api/gift/v1/';
  public serverCertificateUrl = '/api/widget/v1/';
  public initUrlCount = 0;
  private isBackLocation = false;

  public languages: ILanguages[] = [
    { val: 'ru', name: 'RU' },
    { val: 'en', name: 'EN' }
  ];

  // public activeSertificate: ISertificate;
  public currentEvent: IEvent;
  public currentHall: any;
  public cart: ICart;
  public ticketDt: string;

  public logoImage = 'assets/img/mariinsky-logo@2x.png';
  public serviceMoney = 120;
  public deviceInfo: DeviceInfo;
  public deviceType: string;
  public isDesktop: boolean;

  public orderGlobal: any = new ReplaySubject<IOrder>(0);
  public cartRestriction = new EventEmitter<any>();


  private settingsSource = new ReplaySubject<ISettings>();
  public settingsInit = new Subject<ISettings>();

  settings$ = this.settingsSource.asObservable();

  public days = [
    { ru: { name: 'Воскресенье', short_name: 'Вск' }, en: { name: 'Sunday', short_name: 'Sun' } },
    { ru: { name: 'Понедельник', short_name: 'Пн' }, en: { name: 'Monday', short_name: 'Mon' } },
    { ru: { name: 'Вторник', short_name: 'Вт' }, en: { name: 'Tuesday', short_name: 'Tue' } },
    { ru: { name: 'Среда', short_name: 'Ср' }, en: { name: 'Wednesday', short_name: 'Wed' } },
    { ru: { name: 'Четверг', short_name: 'Чт' }, en: { name: 'Thursday', short_name: 'Thu' } },
    { ru: { name: 'Пятница', short_name: 'Пт' }, en: { name: 'Friday', short_name: 'Fri' } },
    { ru: { name: 'Суббота', short_name: 'Сб' }, en: { name: 'Saturday', short_name: 'Sat' } },
  ];

  public month = [
    {
      ru: { name: 'Января', s_name: 'Январь', short_name: 'Янв' },
      en: { name: 'January', s_name: 'January', short_name: 'Jan' },
    },
    {
      ru: { name: 'Февраля', s_name: 'Февраль', short_name: 'Фев' },
      en: { name: 'February', s_name: 'February', short_name: 'Feb' },
    },
    {
      ru: { name: 'Марта', s_name: 'Март', short_name: 'Мар' },
      en: { name: 'March', s_name: 'March', short_name: 'Mar' },
    },
    {
      ru: { name: 'Апреля', s_name: 'Апрель', short_name: 'Апр' },
      en: { name: 'April', s_name: 'April', short_name: 'Apr' },
    },
    {
      ru: { name: 'Мая', s_name: 'Май', short_name: 'Май' },
      en: { name: 'May', s_name: 'May', short_name: 'May' },
    },
    {
      ru: { name: 'Июня', s_name: 'Июнь', short_name: 'Июн' },
      en: { name: 'June', s_name: 'June', short_name: 'Jun' },
    },
    {
      ru: { name: 'Июля', s_name: 'Июль', short_name: 'Июл' },
      en: { name: 'July', s_name: 'July', short_name: 'Jul' },
    },
    {
      ru: { name: 'Августа', s_name: 'Август', short_name: 'Авг' },
      en: { name: 'August', s_name: 'August', short_name: 'Aug' },
    },
    {
      ru: { name: 'Сентября', s_name: 'Сентябрь', short_name: 'Сен' },
      en: { name: 'September', s_name: 'September', short_name: 'Sep' },
    },
    {
      ru: { name: 'Октября', s_name: 'Октябрь', short_name: 'Окт' },
      en: { name: 'October', s_name: 'October', short_name: 'Oct' },
    },
    {
      ru: { name: 'Ноября', s_name: 'Ноябрь', short_name: 'Ноя' },
      en: { name: 'November', s_name: 'November', short_name: 'Nov' },
    },
    {
      ru: { name: 'Декабря', s_name: 'Декабрь', short_name: 'Дек' },
      en: { name: 'December', s_name: 'December', short_name: 'Dec' },
    },
  ];

  public hours = { ru: { name: 'час(а)' }, en: { name: 'hours' } };
  public minutes = { ru: { name: 'минут' }, en: { name: 'minutes' } };

  public eventsScroll = 0;
  public eventsDt = null;
  public head_image;
  public readonly prices_currency = {
    rur: '₽',
    rub: '₽',
    usd: '$',
    eur: '€',
    amd: '֏',
  };
  public readonly default_currency = 'rub';

  constructor(
    private router: Router,
    private storage: StorageService) {

    this.settings$.subscribe(settings => {
      const widget = settings.widget_settings;
      if (widget.additionalLanguages) {
        this.languages = [...this.languages, ...widget.additionalLanguages];
      }
      this.defaultLang = widget.defaultLang || this.defaultLang;
      this.settings = settings;
      this.routes = widget.routes || {};
      this.settingsInit.next(settings);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 1);
        // this.currentUrl = event.url;
        if (this.isBackLocation) {
          this.initUrlCount--;
          this.isBackLocation = false;
          return;
        }

        this.initUrlCount++;
        // if (this.currentEvent) {
        //   this.eDts[this.currentEvent.uuid] = this.currentEvent;
        //   console.log('this.eDts', this.eDts);
        // }
      }
    });
  }

  public setSettings(settings: any) {
    this.settingsSource.next(settings);
  }

  public setDevice(info: DeviceInfo, isDesktop: boolean) {
    this.deviceInfo = info;
    this.deviceType = isDesktop ? 'desktop' : 'mobile';
    this.isDesktop = isDesktop;
  }

  backLocation() {
    this.isBackLocation = true;
    window.history.back();
  }

  toggleBodyOverflow(show: boolean) {
    this.bodyOverflow$.next(show);
  }

  switchLanguage(language: string, service: TranslateService) {
    service.use(language);
    this.storage.setItem('language', language);
    this.selectedLang = language;
    this.langChange.next(language);
  }

  finalQueryParams(route: ActivatedRoute): Observable<Params> {
    return merge(
      // get urls with query params like /test?project=test
      route.queryParams.pipe(
        filter(params => Object.keys(params).length > 0)
      ),
      // get urls without query params like /test
      route.queryParams.pipe(
        filter(() => !(window.location.href || '').match(/\?.+/)),
        map(() => ({}))
      )
    );
  }

  private loadGoogleTagManager(id: string): void {

    const head = document.getElementsByTagName('head')[0];
    const body = document.getElementsByTagName('body')[0];

    const script = document.createElement('script');
    script.type = 'text/javascript'; // set the script type
    // node.async = true; // makes script run asynchronously
    // node.charset = 'utf-8';
    script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');
`;
    // append to head of document
    head.insertBefore(script, head.children[0]);

    const noscript = document.createElement('noscript');
    noscript.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;
    body.insertBefore(noscript, body.children[0]);
  }

  public initTagManager(): void {
    if (!this.settings || !this.settings.name) {
      return;
    }

    if (this.settings.name.ru.toLowerCase().includes('планетарий')) {
      this.loadGoogleTagManager('GTM-M6T3N5D');
      return;
    }
    if (this.settings.name.ru.toLowerCase().includes('чайковского')) {
      this.loadGoogleTagManager('GTM-MVXVKJV');
      return;
    }
  }
}
