import { Pipe, PipeTransform } from '@angular/core';
import { ICartItem } from '../types/Entities';
import { CartService } from '../services/cart.service';

@Pipe({
  name: 'cartItemPrice'
})
export class CartItemPricePipe implements PipeTransform {

  constructor(private cart: CartService) {
  }

  transform(value: ICartItem): number {
    return this.cart.getCartItemBasePrice(value);
  }

}
