import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { event, select, zoom, quadtree, mouse, zoomIdentity } from 'd3';
import { prop, isNil, isEmpty, path } from 'ramda';
import { IPriceValue, IProductTicket } from '../../types/Entities';
import { Observable, Subject, Subscription } from 'rxjs';
import * as palette from 'google-palette';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CartService } from '../../services/cart.service';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { propOr, clone } from 'ramda';
import { IGeometryPlaceView } from '../../types/ISchema';
import { IHidePopupOptions } from './popup/popup.component';

interface TBoundingBoxGeometry {
  x: number;
  y: number;
  w: number;
  h: number;
}

interface TObjectGeometry {
  w: number;
  h: number;
  cx: number;
  cy: number;
  name?: string;
  uuid?: string;
  path?: string;
  gs?: number;
  gi?: string;
}

export interface TPlaceInfo extends TObjectGeometry {
  place_view?: any;
  sector: string;
  row: string;
  seat: string;
  price: {
    amount: number;
    currency?: string;
  };
}

interface TRowGeometry {
  places: TObjectGeometry[];
  bb: TBoundingBoxGeometry;
  uuid: string;
  path?: string;
}

interface TFragmentGeometry {
  rows: TRowGeometry[];
  bb: TBoundingBoxGeometry;
  uuid: string;
  path?: string;
}

interface TSectorGeometry {
  uuid: string;
  fragments: TFragmentGeometry[];
  bb: TBoundingBoxGeometry;
  path: string;
}

interface TCustomObjectGeometry {
  uuid: string;
  bb: TBoundingBoxGeometry;
  path?: { value: string; style?: string };
  text?: { value: { ru?: string; en?: string }; style?: string };
}

interface THallGeometry {
  sectors: TSectorGeometry[];
  bb: TBoundingBoxGeometry;
  objects?: TCustomObjectGeometry[];
  settings?: any;
}

interface TQuadItem {
  x: number;
  y: number;
  uuid: string;
}

enum DeviceType {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

@Component({
  selector: 'app-hall-schema',
  template: `
    <svg xmlns="http://www.w3.org/1999/html" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter id="shadow1" x="-40%" y="-40%" width="180%" height="180%">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g class="wrapper" style="will-change: transform">
        <g class="sectors"></g>
        <g class="interactive"></g>
        <g class="custom-objects"></g>
      </g>
    </svg>
    <app-hall-schema-controls
      [zoomSubject]="onZoomButtonSource"
      [infoSubject]="onInfoButtonSource"
      [zoomLevel$]="zoomLevel$"
      [zoomControlsDisabled]="zoomControlsDisabled"
    >
    </app-hall-schema-controls>
    <app-hall-schema-popup
      [offset]="componentOffset"
      [onHover]="onHover$"
      [onClick]="onSelect$"
      [onUnhover]="wrapperMouseLeave$"
      [onToucMove]="touchMove$"
      [onPositionChange]="schema.viewPort.positionChange$"
      [hidePopup]="hidePopup$"
      [show_price_categories]="geometrySettings ? geometrySettings.show_price_category_name : false"
      [place_view]="place_view"
      (toggleExpandImage)="toggleBlockUpdate($event)"
      (clickPlaceView)="onPlaceViewClick($event)"
    ></app-hall-schema-popup>
    <app-hall-schema-legend
      [prices]="actual_prices_filtered"
      [display]="onInfoButton$"
      [schedule]="schedule"
      [show_price_categories]="geometrySettings ? geometrySettings.show_price_category_name : false"
      (showHide)="onLegendShowHide($event)"
    >
    </app-hall-schema-legend>
    <app-event-banner *ngIf="showBanner"
                      [special]="schedule.uuid === 'f7564dfd-616b-458f-9a4f-34f4ff373992'
                      || schedule.uuid === 'cb3adc8c-5d07-47c3-b8b5-3e234e822918'
                      || schedule.uuid === '8261fa75-4b5c-4d11-9ff2-4e4186efedbd'
                      || schedule.uuid === '36f06e43-86a5-4a70-a5ba-1d4c168b98e4'
                      || schedule.uuid === '5d02f932-ce8f-4bdb-89e9-a28b3e37aa54'
                      || schedule.uuid === '998ba570-effb-4173-8cff-9b0d8eee0422'
                      || schedule.uuid === 'f1a57f94-d847-4bfa-82f7-874c9f57dc24'
                      || schedule.uuid === '74298622-cc3b-4b88-a79f-dc55c900989a'
                      || schedule.uuid === 'c4b8fc2c-9371-4c7c-aaa5-e7d5baf28b31'
                      || schedule.uuid === '31cae760-f7de-484f-831e-5ded26455be5'">
    </app-event-banner>
  `,
  styleUrls: ['./hall_schema.component.less'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class HallSchemaComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input('schedule') schedule: any;
  @Input() tickets_data: any;
  @Input() parentSetSize$: Observable<any>;

  private container: HTMLElement;
  private svg: HTMLElement;
  private size: { w: number; h: number };
  private screenSize: { w: number; h: number };
  private oldScreenSize: { w: number; h: number };
  private geometry: THallGeometry;
  public place_view: IGeometryPlaceView;
  private object_names: { [uuid: string]: { [lang: string]: string } };
  private tickets_hash: { [place_uuid: string]: IProductTicket[] };
  private prices_hash: { [price_uuid: string]: IPriceValue };
  public actual_prices: { [price_uuid: string]: { amount: number; color: string; uuid: string } };
  public actual_prices_filtered: {
    [price_uuid: string]: { amount: number; color: string; uuid: string };
  };
  public cart_places;
  private limits: number;
  private selectedCount = 0;
  public schema: Schema;
  private deviceType: DeviceType;
  public componentOffset: IPoint;
  private onSelectOutput: Subject<any>;
  public geometrySettings: any = {};
  public blockedUpdate = false;
  public zoomControlsDisabled = false;

  public onInfoButtonSource = new Subject<string>();
  public onZoomButtonSource = new Subject<number>();
  private onHoverSource = new Subject<any>();
  private onClickSource = new Subject<any>();
  private wrapperMouseLeaveSource = new Subject<any>();
  private onSelectSource = new Subject<any>();
  private touchMoveSource = new Subject<any>();
  private hidePopupSource = new Subject<IHidePopupOptions>();

  public onHover$ = this.onHoverSource.asObservable();
  public onClick$ = this.onClickSource.asObservable();
  public wrapperMouseLeave$ = this.wrapperMouseLeaveSource.asObservable();
  public onSelect$ = this.onSelectSource.asObservable();
  public touchMove$ = this.touchMoveSource.asObservable();
  public hidePopup$ = this.hidePopupSource.asObservable();

  private onZoomButton$ = this.onZoomButtonSource.asObservable();
  public onInfoButton$ = this.onInfoButtonSource.asObservable();
  showBanner: boolean = false;
  public zoomLevel$: Observable<number>;

  private symbols = {
    hovered: function (options: any, el: SVGGElement, x, y, w, h, color, name?: string) {
      const scale = 1.1;
      const k = select(el);
      const c = k
        .append('circle')
        .attr('r', w * scale)
        .attr('cy', 0)
        .attr('cx', 0)
        .attr('fill', `#${color}`)
        .attr('style', `fill: #${color};`);
      if (options.deviceType === DeviceType.desktop && options.browser !== 'Safari') {
        c.attr('filter', 'url(#shadow1)');
      }
      // else {
      //   c.attr('stroke', '#A0A0A0');
      // }

      k.append('text')
        .attr('x', 0)
        .attr('y', 0.2 * h)
        .attr('fill', '#ffffff')
        .attr('text-anchor', 'middle')
        .attr('style', `font-size: ${Math.round(1.2 * h * scale)}px; font-weight: 100`)
        .text(name);
    },
    selected: function (options: any, el, x, y, w, h, color, name?: string) {
      const s = (0.5 * w) / 15;
      const k = select(el)
        .append('g')
        .attr('transform', `translate(${-w},${-h}) scale(${w / 15})`);

      const c = k
        .append('circle')
        .attr('transform', 'translate(8.5,6.5)')
        .attr('r', '15')
        .attr('cy', '8.5')
        .attr('cx', '6.5')
        .attr('fill', '#ffffff')
        .attr('style', 'fill: #ffffff');
      if (options.deviceType === DeviceType.desktop && options.browser !== 'Safari') {
        c.attr('filter', 'url(#shadow1)');
      } else {
        c.attr('stroke', '#A0A0A0');
      }

      k.append('path')
        .attr('transform', 'translate(8.5,6.5)')
        .attr(
          'd',
          `m 10.04917,3.5018693 c 0,1.954 -1.5733598,3.50198 -3.5019898,3.50198 -1.92862,0 -3.50198,-1.5733
-3.50198,-3.50198 0,-1.92863 1.57336,-3.50198005 3.50198,-3.50198005 1.92863,0 3.5019898,1.54797005 3.5019898,3.50198005 z
m 3.0452,10.9373497 c 0,2.080891 -13.09436979854815,2.080891 -13.09436979854815,0 0,-3.09596 2.91831999854815,-6.2172897
6.54717999854815,-6.2172897 3.6288698,0 6.5471898,3.1213297 6.5471898,6.2172897 z`
        )
        .attr('style', `fill:#${color}`);

      return k;
    },
    group_hovered: function (
      options: any,
      el: SVGGElement,
      x,
      y,
      w,
      h,
      color,
      path,
      name: string,
      gs
    ) {
      const scale = 1.1;
      const k = select(el);
      const c = k
        .append('circle')
        .attr('r', w * scale)
        .attr('cy', 0)
        .attr('cx', 0)
        .attr('fill', `#${color}`)
        .attr('style', `fill: #${color};`);
      if (options.deviceType === DeviceType.desktop && options.browser !== 'Safari') {
        c.attr('filter', 'url(#shadow1)');
      }
      // else {
      //   c.attr('stroke', '#A0A0A0');
      // }

      k.append('text')
        .attr('x', 0)
        .attr('y', 0.2 * h)
        .attr('fill', '#ffffff')
        .attr('text-anchor', 'middle')
        .attr('style', `font-size: ${Math.round(1.2 * h * scale)}px; font-weight: 100`)
        .text(name);
    },
    group_selected: function (options: any, el, x, y, w, h, color, path, name: string, gs) {
      const s = Math.min(h, w) / 15;

      const g = select(el)
        .append('g')
        .attr('transform', `translate(0, 0)`);

      const k = g.append('g').attr('transform', `scale(${s}) translate(2, -2)`);

      const c = k
        .append('circle')
        .attr('transform', 'translate(-8.5, -6.5)')
        .attr('r', '15')
        .attr('cy', '8.5')
        .attr('cx', '6.5')
        .attr('fill', '#ffffff')
        .attr('style', 'fill: #ffffff');
      if (options.deviceType === DeviceType.desktop && options.browser !== 'Safari') {
        c.attr('filter', 'url(#shadow1)');
      } else {
        c.attr('stroke', '#A0A0A0');
      }

      k.append('path')
        .attr('transform', 'translate(-8.5, -6.5)')
        .attr(
          'd',
          `m 10.04917,3.5018693 c 0,1.954 -1.5733598,3.50198 -3.5019898,3.50198 -1.92862,0 -3.50198,-1.5733
-3.50198,-3.50198 0,-1.92863 1.57336,-3.50198005 3.50198,-3.50198005 1.92863,0 3.5019898,1.54797005 3.5019898,3.50198005 z
m 3.0452,10.9373497 c 0,2.080891 -13.09436979854815,2.080891 -13.09436979854815,0 0,-3.09596 2.91831999854815,-6.2172897
6.54717999854815,-6.2172897 3.6288698,0 6.5471898,3.1213297 6.5471898,6.2172897 z`
        )
        .attr('style', `fill:#${color}`);

      return g;
    },
  };

  private subscriptions = [];

  constructor(
    private _elemRef: ElementRef,
    private deviceService: DeviceDetectorService,
    private renderer: Renderer2,
    private cartService: CartService,
    public global: GlobalService,
    private translateService: TranslateService
  ) {
    this.size = { w: 0, h: 0 };
    this.screenSize = { w: screen.width, h: screen.height };
    this.oldScreenSize = this.screenSize;
    this.onZoomButton$.subscribe(e => this.zoomStep(e));
  }

  ngOnInit() {
    this.container = this._elemRef.nativeElement;
    this.componentOffset = this.getOffsetTop();
    if (this.deviceService.isDesktop()) {
      this.deviceType = DeviceType.desktop;
    }
    if (this.deviceService.isMobile()) {
      this.deviceType = DeviceType.mobile;
    }
    if (this.deviceService.isTablet()) {
      this.deviceType = DeviceType.tablet;
    }
    this.svg = this.container.children[0] as HTMLElement;
    this.svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    this.subscriptions.push(
      this.cartService.removeFromCart$.subscribe((cartItem: any) => {
        if (cartItem.schedule.uuid !== this.schedule.uuid) return;
        this.schema.places[cartItem.ProductItem.Place.uuid].toggleSelected(false);
        // this.schema.places[(cartItem.ProductItem as IProductTicket).place_uuid].toggleSelected(false);
      }),
      this.cartService.cartOutput$.subscribe(() => {
        this.schema.viewPort.recalcSelected(this.cartService.total_items);
      }),
      this.global.showHideCartPopup.subscribe(show => {
        if (show) {
          this.hidePopupSource.next({ transition: false, emitExpanded: false });
          this.unbindZoom();
        } else {
          this.bindZoom();
        }
        this.toggleBlockUpdate(show);
      })
    );
    if (this.parentSetSize$) {
      this.subscriptions.push(
        this.parentSetSize$.subscribe((isOrient: boolean) => {
          if (this.schema) {
            this.componentOffset = this.getOffsetTop();
            if (isOrient) {
              this.schema.viewPort.setInitialZoom();
            }
            this.schema.resize();
          }
        })
      );
    }
    const { settings } = this.global;
    console.log(settings)
    this.showBanner = settings && settings.name && settings.name.en === 'Zapomni' ? true : false;
    const limits = settings ? settings.widget_settings.limits : null;
    const orderLimit = limits.tickets_in_order;

    this.cart_places = this.cartService.getEventItems(this.schedule.uuid);
    this.selectedCount = this.cartService.total_items;

    const options = {
      deviceType: this.deviceType,
      browser: this.deviceService.browser,
    };
    this.schema = new Schema(
      this.svg,
      options,
      () => {
        this.setAvailableTickets();
        this.cart_places.map(cartItem => {
          this.schema.places[cartItem.ProductItem.Place.uuid].toggleSelected(true);
        });
      },
      this.onSelect.bind(this),
      this.onHover.bind(this),
      this.translateService.currentLang,
      this.symbols,
      {
        limit: orderLimit,
        count: this.selectedCount,
        errCb: this.showLimitMessage.bind(this),
      }
    );

    this.zoomLevel$ = this.schema.viewPort.onZoomChange$;

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(lng => {
        // console.log(lng.lang);
        this.schema.setLanguage(lng.lang);
      }),
      this.zoomLevel$.subscribe(() => {
        this.hidePopupSource.next();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.schema && changes.setGeometry && changes.setGeometry.currentValue) {
      this.schema.resize();
    }
  }

  ngAfterViewInit() {
    // select(this.$wrapper.nativeElement).on('click', this.onWrapperMouseLeave.bind(this));
    select(this._elemRef.nativeElement).on('mouseleave', this.onWrapperMouseLeave.bind(this));
  }

  onLegendShowHide(value) {
    if (value) {
      this.unbindZoom();
      this.toggleBlockUpdate(true);
      this.hidePopupSource.next({ transition: false, emitExpanded: false });
    } else {
      this.global.showHideCartPopup.next(false);
      this.bindZoom();
      this.toggleBlockUpdate(false);
    }
  }

  @Input('onSelect')
  set setSelectCb(cb: Subject<any>) {
    this.onSelectOutput = cb;
  }

  @Input('geometry')
  set setGeometry(data: any) {
    if (!data) {
      return;
    }
    // console.log('setGeometry', data);

    this.geometry = data.geometry as THallGeometry;
    this.place_view = data.place_view as IGeometryPlaceView;
    this.schema.setSettings(data.settings);
    this.geometrySettings = data.settings;
    this.schema.setGeometry(data.geometry);
  }

  setAvailableTickets() {
    if (!prop('tickets', this.tickets_data) || !prop('prices', this.tickets_data)) {
      return;
    }
    // console.log('setTickets', ticketsData);
    this.prices_hash = this.tickets_data.prices;
    const { tickets, prices } = this.tickets_data.tickets.reduce(
      (acc, item) => {
        if (!prop(item.place_uuid, acc.tickets)) {
          acc.tickets[item.place_uuid] = [];
        }
        acc.prices = item.price_values.reduce((pva, pvi) => {
          if (!prop(pvi, pva) && prop(pvi, this.prices_hash)) {
            const price = prop(pvi, this.prices_hash);
            // console.log(this.prices_hash);
            pva[pvi] = clone(price);
            // {
            //   uuid: price.uuid,
            //   amount: price.amount,
            //   color: undefined,
            //   PriceCategory: {
            //     name: prop('name', price.PriceCategory)
            //   }
            // };
          }
          return pva;
        }, acc.prices);
        acc.tickets[item.place_uuid].push(item);
        return acc;
      },
      { tickets: {}, prices: {} }
    );
    this.tickets_hash = tickets;
    const actual_prices = prices;
    const colors = palette('mpn65', Object.keys(prices).length).map(c => {
      const r = Math.round((parseInt(c.substr(0, 2), 16) + 200) / 2);
      const g = Math.round((parseInt(c.substr(2, 2), 16) + 200) / 2);
      const b = Math.round((parseInt(c.substr(4, 2), 16) + 200) / 2);

      return r.toString(16) + g.toString(16) + b.toString(16);
    });
    const priceValues = Object.values(actual_prices);
    this.actual_prices = priceValues
      .sort((a, b) => prop('amount', a) - prop('amount', b))
      .reduce((acc, item: any) => {
        const exist = Object.values(acc).find(_item => _item.amount === item.amount);
        const color = exist ? exist.color : undefined;
        item['color'] = color || colors.pop();
        acc[item['uuid']] = item;
        return acc;
      }, prices) as { [price_uuid: string]: { amount: number; color: string; uuid: string } };
    this.actual_prices_filtered = priceValues.reduce((acc, item: any) => {
      if (!Object.values(acc).some(_item => _item.amount === item.amount)) {
        acc[item.uuid] = item;
      }
      return acc;
    }, {}) as any;
    this.schema.setPlaces(this.tickets_data.tickets, this.actual_prices);
  }

  @Input('selected-tickets')
  set selectedTickets(tickets: IProductTicket[]) { }

  public zoomStep(n: number) {
    this.schema.zoomStep(n);
  }

  private getOffsetTop(): IPoint {
    const el = this._elemRef.nativeElement;
    let yPos = el.offsetTop;
    let xPos = el.offsetLeft;
    let nextEl = el.offsetParent;

    while (nextEl != null) {
      yPos += nextEl.offsetTop;
      xPos += nextEl.offsetLeft;
      nextEl = nextEl.offsetParent;
    }
    return { x: xPos, y: yPos };
  }

  public toggleBlockUpdate(value: boolean) {
    if (value === this.blockedUpdate) {
      return;
    }
    this.blockedUpdate = value;
    this.schema.viewPort.toggleBindEvents(!value);
  }

  public onPlaceViewClick(td: any) {
    if (td) {
      this.unbindZoom();
    } else {
      this.bindZoom();
    }
  }

  public unbindZoom() {
    this.schema.viewPort.unbindZoom();
    this.zoomControlsDisabled = true;
  }

  public bindZoom() {
    this.schema.viewPort.bindZoom();
    this.zoomControlsDisabled = false;
  }

  private onHover(state: boolean, seat_uuid: string, clientXY: IPoint, bb) {
    // console.log('hover', state, seat_uuid, bb);
    const ticket = prop(seat_uuid, this.tickets_hash)
      ? prop(seat_uuid, this.tickets_hash)[0]
      : null;

    if (!ticket) return;

    const hoveredPlace = {
      uuid: seat_uuid,
      ticket: ticket,
      priceValues: ticket.price_values.map(e => this.actual_prices[e]),
    };
    // debugger;
    this.onHoverSource.next({
      state,
      placeData: hoveredPlace,
      clientXY: clientXY,
      bb,
      zoomLevel: this.schema.viewPort.getCurrentZoom(),
    });

    /* if(this.hoverCb) {
       this.hoverCb(state, hoveredPlace);
     }*/
  }

  private onWrapperMouseLeave() {
    this.wrapperMouseLeaveSource.next();
  }

  private onSelect(state: boolean, seat_uuid: string, clientXY: IPoint, bb) {
    // console.log('select', state, seat_uuid);
    const { schedule } = this;
    const ticket = path([seat_uuid, 0], this.tickets_hash);
    if (ticket) {
      const selectedPlace = {
        uuid: seat_uuid,
        ticket: { ...ticket, schedule },
        prices_hash: this.prices_hash,
        priceValues: ticket.price_values.map(e => {
          const PriceValue = this.prices_hash[e];
          // return {...PriceValue, amount: PriceValue.amount * 100};
          return PriceValue;
        }),
        schedule,
      };
      this.onSelectSource.next({ state, placeData: selectedPlace, clientXY, bb });
      this.onSelectOutput.next({ state, placeData: selectedPlace, bb });
    }
  }

  showLimitMessage() {
    this.cartService.showLimitMessage();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }
}

interface ISize {
  w: number;
  h: number;
}

interface IPoint {
  x: number;
  y: number;
  z?: number;
}

class ViewPort {
  private x: number;
  private y: number;
  private scale: number;
  private el: SVGGElement;
  private vpSize: ISize;
  private oldVpSize: ISize;
  public schema: Schema;
  private zoomLevel = 1;
  private worldOffset: IPoint;
  private zoom: number;
  private zoomBehavior;
  private selection;
  private oldOffset: IPoint;
  private state: {
    hovered?: Seat;
    smooth_zoom?: any;
    sector_zoom?: any;
  };
  private countState: any;
  private limit: number;
  private selectedCounter: number;
  private eventSubscriptions: Subscription[] = [];
  private bindedEvents: {
    [key: string]: {
      [key: string]: () => any;
    };
  };

  private cursorMoveSource = new Subject<{ x: number; y: number }>();
  private cursorMove$ = this.cursorMoveSource.asObservable();

  private panZoomSource = new Subject<{ x: number; y: number; k: number }>();
  private panZoom$ = this.panZoomSource.asObservable();

  private seatClickSource = new Subject<Seat>();
  private seatClick$ = this.seatClickSource.asObservable();

  private onHoveredSource = new Subject<any>();
  public onHovered$ = this.onHoveredSource.asObservable();

  private onSelectSource = new Subject<any>();
  public onSelect$ = this.onSelectSource.asObservable();

  private onZoomChangeSource = new Subject<number>();
  public onZoomChange$ = this.onZoomChangeSource.asObservable();

  private positionChangeSource = new Subject<any>();
  public positionChange$ = this.positionChangeSource.asObservable();

  private toggleBindEventsSource = new Subject<boolean>();
  public toggleBindEvents$ = this.toggleBindEventsSource.asObservable();

  private initialState = true;
  private zoomBinded = false;

  constructor(schema: Schema, el: SVGGElement, x = 0, y = 0, z = 1, countState?: any) {
    const self = this;
    this.x = x;
    this.y = y;
    this.scale = z;
    this.el = el;
    this.schema = schema;
    this.worldOffset = { x: 0, y: 0 };
    this.state = {};
    this.oldVpSize = { w: 0, h: 0 };
    this.oldOffset = { x: 0, y: 0 };
    this.countState = countState;
    this.limit = countState ? countState.limit : undefined;
    this.selectedCounter = countState ? countState.count : 0;
    this.vpSize = this.schema.getSize();
    this.zoomBehavior = zoom();
    this.selection = select(this.el.parentElement.parentElement);
    this.bindedEvents = {
      zoomBehavior: {
        zoom: self.onPanZoom,
        start: self.zoomStart,
        end: self.zoomEnd,
      },
      selection: {
        mousemove: self.onMouseMove,
        click: self.onMouseClick,
        tap: self.onMouseClick,
        'dblclick.zoom': null,
        mousewheel: () => {
          self.state['smooth_zoom'] = self.state.smooth_zoom;
          self.state['sector_zoom'] = self.state.sector_zoom;
        },
      },
    };
    this.bindZoom();
    this.bindEvents();
    this.toggleBindEvents$.subscribe(value => {
      value ? this.bindEvents() : this.unbindEvents();
    });
  }

  private bindEvents() {
    Object.keys(this.bindedEvents).map(key => {
      Object.keys(this.bindedEvents[key]).map(_key => {
        const fn = this.bindedEvents[key][_key];
        if (fn) {
          this[key].on(_key, fn.bind(this));
        }
      });
    });
    this.eventSubscriptions = [
      this.seatClick$.subscribe(seat => {
        this.onSeatClick(seat);
      }),
      this.panZoom$.subscribe(e => {
        this.doZoom(e);
      }),
    ];
  }

  private unbindEvents() {
    this.selection.on('.click', null);
    Object.keys(this.bindedEvents).map(key => {
      Object.keys(this.bindedEvents[key]).map(_key => {
        const fn = this.bindedEvents[key][_key];
        if (fn) {
          this[key].on(_key, null);
        }
      });
    });
    this.eventSubscriptions.map(s => {
      s.unsubscribe();
    });
  }

  public unbindZoom() {
    if (this.zoomBinded) {
      this.selection.on('.zoom', null);
      this.zoomBinded = false;
    }
  }

  public bindZoom() {
    if (!this.zoomBinded) {
      this.selection.call(this.zoomBehavior);
      this.zoomBinded = true;
    }
  }

  public setInitialZoom() {
    this.selection.call(this.zoomBehavior.transform, zoomIdentity);
  }

  public toggleBindEvents(value: boolean) {
    this.toggleBindEventsSource.next(value);
  }

  public zoomStep(n: number) {
    const s = select(this.el.parentElement.parentElement);
    let next_level = this.zoomLevel + n * Math.log(this.zoomLevel + 1.5);
    if (next_level < 1.5 || (n < 0 && this.state['sector_zoom'])) {
      next_level = 1;
    }
    // console.log(next_level, n, this.state['sector_zoom'], next_level < 1.5 || (n < 0 && this.state['sector_zoom']))
    this.state['sector_zoom'] = false;
    this.zoomBehavior.scaleTo(s, next_level);
  }

  public getCurrentZoom(): number {
    return this.zoomLevel;
  }

  public setSize(size: ISize) {
    const w = this.vpSize ? this.vpSize.w : 0;
    const h = this.vpSize ? this.vpSize.h : 0;
    this.oldVpSize = { w, h };
    this.vpSize = size;
    this.reset();
  }

  public onSectorClick(uuid: string, bb, ev) {
    // console.log(uuid, bb);

    if (this.initialState && propOr(false, 'show_sectors_paths', this.schema.settings)) {
      ev.stopPropagation();
      ev.preventDefault();

      this.el.parentElement.setAttribute('class', 'animate');
      this.state['sector_zoom'] = true;

      // calc minimal zoom level

      const z1 = this.vpSize.w / bb.width;
      const z2 = this.vpSize.h / bb.height;
      let zoomK = Math.min(z1, z2) * 0.85;

      if (zoomK > 9) {
        zoomK = 9;
      }
      // console.log('ZoomK', zoomK);
      // calc centers of viewport and sector's bounding box;

      const screen_center = {
        x: this.vpSize.w / 2,
        y: this.vpSize.h / 2,
      };

      const sector_center = {
        x: bb.left + bb.width / 2,
        y: bb.top + bb.height / 2,
      };

      /*

      const debug = document.getElementById('debug');
      debug.innerHTML = '';


      const screen_axis_x = document.createElement('div');
      const screen_axis_y = document.createElement('div');

      const sector_axis_x = document.createElement('div');
      const sector_axis_y = document.createElement('div');

      screen_axis_y.style.position = 'absolute';
      screen_axis_y.style.width = '1px';
      screen_axis_y.style.background = '#0F0';
      screen_axis_y.style.left = screen_center.x + 'px';
      screen_axis_y.style.top = '0';
      screen_axis_y.style.bottom = '0';

      screen_axis_x.style.position = 'absolute';
      screen_axis_x.style.height = '1px';
      screen_axis_x.style.background = '#0F0';
      screen_axis_x.style.top = screen_center.y + 'px';
      screen_axis_x.style.left = '0';
      screen_axis_x.style.right = '0';

      sector_axis_y.style.position = 'absolute';
      sector_axis_y.style.width = '1px';
      sector_axis_y.style.background = '#f00';
      sector_axis_y.style.left = sector_center.x + 'px';
      sector_axis_y.style.top = '0';
      sector_axis_y.style.bottom = '0';

      sector_axis_x.style.position = 'absolute';
      sector_axis_x.style.height = '1px';
      sector_axis_x.style.background = '#f00';
      sector_axis_x.style.top = sector_center.y + 'px';
      sector_axis_x.style.left = '0';
      sector_axis_x.style.right = '0';

      debug.append(screen_axis_x, screen_axis_y, sector_axis_x, sector_axis_y);
*/

      const dx = sector_center.x - screen_center.x;
      const dy = sector_center.y - screen_center.y;

      const s = select(this.el.parentElement.parentElement);

      this.state['smooth_zoom'] = true;

      this.zoomBehavior.scaleTo(s, zoomK);
      this.zoomBehavior.translateBy(s, -dx, -dy + 55);
    }
  }

  private onMouseMove() {
    const ev = mouse(this.el);
    const evClient = mouse(document.body);
    //  console.log(ev, evClient);
    this.state['smooth_zoom'] = false;
    if (
      this.schema.geometrySize
      // && ev[0] >= 0
      // && ev[0] <= this.schema.geometrySize.w
      // && ev[1] >= 0
      // && ev[1] <= this.schema.geometrySize.h
    ) {
      this.onCursorMove({ x: ev[0], y: ev[1] }, { x: evClient[0], y: evClient[1] });
    }
  }

  private onMouseClick() {
    const ev = mouse(this.el);
    const evClient = mouse(document.body);
    if (
      this.schema.geometrySize &&
      ev[0] >= 0 &&
      ev[0] <= this.schema.geometrySize.w &&
      ev[1] >= 0 &&
      ev[1] <= this.schema.geometrySize.h
    ) {
      this.onCursorClick({ x: ev[0], y: ev[1] }, { x: evClient[0], y: evClient[1] });
    }
  }

  private onCursorMove(ev: IPoint, clientXY: IPoint, is_selection = false) {
    if (
      !is_selection &&
      !(this.initialState && propOr(false, 'show_sectors_paths', this.schema.settings))
    ) {
      if (this.schema.options.deviceType === DeviceType.desktop) {
        const hovered = this.schema.quadTree.find(ev.x, ev.y, this.schema.minimal_place_size.w);

        if (hovered && hovered.is_active) {
          if (this.state.hovered && hovered.getUUID() !== this.state.hovered.getUUID()) {
            this.state.hovered.setHovered(false);
          }
          if (this.state.hovered && hovered.getUUID() === this.state.hovered.getUUID()) {
            return;
          }
          this.state.hovered = hovered;
          this.state.hovered.setHovered();
          const bb = this.state.hovered.getBoundingBox();
          // debugger;
          this.onHoveredSource.next({
            hovered: true,
            seat_uuid: this.state.hovered.getUUID(),
            clientXY: clientXY,
            bb,
          });
        } else {
          if (this.state.hovered) {
            this.state.hovered.setHovered(false);
            const bb = this.state.hovered.getBoundingBox();
            // debugger;
            this.onHoveredSource.next({
              hovered: false,
              seat_uuid: this.state.hovered.getUUID(),
              clientXY: clientXY,
              bb,
            });
            this.state.hovered = undefined;
          }
        }
      }
    } else {
      if (this.state.hovered) {
        this.state.hovered.setHovered(false);
      }
    }
  }

  private onCursorClick(ev: IPoint, clientXY: IPoint, is_selection = false) {
    if (
      !is_selection &&
      !(this.initialState && propOr(false, 'show_sectors_paths', this.schema.settings))
    ) {
      const selected = this.schema.quadTree.find(ev.x, ev.y, this.schema.minimal_place_size.w);
      if (selected) {
        this.seatClickSource.next(selected);
      }
    }
  }

  public update() {
    this.el.setAttribute(
      'transform',
      `translate(${this.x},${this.y}) scale(${this.scale * this.zoomLevel})`
    );
    this.initialState = this.zoomLevel <= 1;
    if (this.state.hovered && this.initialState) {
      this.state.hovered.setHovered(false);
    }
    if (!this.initialState) {
      if (!this.schema.sgContainer.classList.contains('zoom-state')) {
        this.schema.sgContainer.classList.add('zoom-state');
      }
    } else {
      if (this.schema.sgContainer.classList.contains('zoom-state')) {
        this.schema.sgContainer.classList.remove('zoom-state');
      }
    }
    if (this.oldOffset.x !== this.x || this.oldOffset.y !== this.y) {
      this.positionChangeSource.next();
    }
    this.oldOffset.x = this.x;
    this.oldOffset.y = this.y;
  }

  public recalcSelected(value?: number, dir?: 'plus' | 'minus') {
    if (value) {
      this.selectedCounter = value;
      return;
    }

    dir === 'plus' ? this.selectedCounter++ : this.selectedCounter--;
  }

  private reset() {
    if (!this.schema || !this.schema.geometrySize) {
      return;
    }
    this.vpSize = this.schema.getSize();
    this.scale =
      Math.min(
        this.vpSize.w / this.schema.geometrySize.w,
        this.vpSize.h / this.schema.geometrySize.h
      ) * 0.8;
    // console.log(this.vpSize.w, this.schema.geometrySize.w);
    const ox = (this.vpSize.w - this.schema.geometrySize.w * this.scale) / 2;
    const oy = (this.vpSize.h - this.schema.geometrySize.h * this.scale) / 2;

    this.oldOffset.x = ox - this.oldOffset.x;
    this.oldOffset.y = oy - this.oldOffset.y;
    const dx = -0.5 * (this.vpSize.w - this.oldVpSize.w) + this.oldOffset.x;
    const dy = -0.5 * (this.vpSize.h - this.oldVpSize.h) + this.oldOffset.y;
    // console.log('DDD', dx, dy);
    this.zoomBehavior.translateBy(select(this.el.parentElement.parentElement), dx, dy);
    this.zoomBehavior.scaleExtent([1, 9]);
    /*this.zoomBehavior.extent([[-dx, -dy], [this.vpSize.w - dx, this.vpSize.h -dy]]);
    this.zoomBehavior.translateExtent([[-dx, -dy], [this.vpSize.w - dx, this.vpSize.h -dy]]);*/
    this.zoomBehavior.constrain((transform, extent, translateExtent) => {
      return this.constrain(transform, extent, translateExtent);
    });

    this.worldOffset.x = dx;
    this.worldOffset.y = dy;
    this.update();
  }

  private constrain(transform, extent, translateExtent) {
    const ox = (this.vpSize.w - this.schema.geometrySize.w * this.scale) / 2;
    const oy = (this.vpSize.h - this.schema.geometrySize.h * this.scale) / 2;

    const screenSchemaSize = {
      w: this.schema.geometrySize.w * this.scale * transform.k,
      h: this.schema.geometrySize.h * this.scale * transform.k,
    };

    const max = {
      x: Math.abs(this.vpSize.w - screenSchemaSize.w) / 2,
      y: Math.abs(this.vpSize.h - screenSchemaSize.h) / 2,
    };

    const min = {
      x: -(max.x + screenSchemaSize.w - this.vpSize.w),
      y: -(max.y + screenSchemaSize.h - this.vpSize.h),
    };

    // console.log(transform.x.toFixed(2), transform.y.toFixed(2), min, max);

    if (transform.x < min.x) {
      transform.x = min.x;
    }

    if (transform.x > max.x) {
      transform.x = max.x;
    }

    if (transform.y > max.y) {
      transform.y = max.y;
    }

    if (transform.y < min.y) {
      transform.y = min.y;
    }

    return transform;
  }

  private onPanZoom() {
    const ev = event;
    // console.log(ev.transform);
    this.panZoomSource.next(ev.transform);
  }

  private zoomStart() {
    if (!this.state['smooth_zoom']) {
      this.el.parentElement.setAttribute('class', '');
    } else {
      this.el.parentElement.setAttribute('class', 'animate');
    }
  }

  private zoomEnd() {
    this.el.parentElement.setAttribute('class', 'animate');
  }

  private doZoom(transform: { x: number; y: number; k: number }) {
    this.x = transform.x;
    this.y = transform.y;
    this.zoomLevel = transform.k;

    this.onZoomChangeSource.next(this.zoomLevel);
    this.update();
  }

  private onSeatClick(seat: Seat) {
    if (seat.getStatus()) {
      //  console.log('onSeatClick');
      if (!seat.getState() && this.selectedCounter === this.limit) {
        if (this.countState && this.countState.errCb) {
          this.countState.errCb();
        }
        return;
      }

      const state = seat.toggleSelected();

      state ? this.selectedCounter++ : this.selectedCounter--;

      this.onSelectSource.next({
        selected: state,
        seat_uuid: seat.getUUID(),
        clientXY: this.x,
        bb: seat.getBoundingBox(),
      });
    }
    //  console.log('CLICKED ON', seat);
  }
}

class DObject {
  public x: number;
  public y: number;
  public w: number;
  public h: number;
  protected data: any;
  protected parent: any;
  public world: IPoint;
  public schema: Schema;

  constructor(
    schema: Schema,
    x: number,
    y: number,
    w: number,
    h: number,
    data?: any,
    parent?: any
  ) {
    this.schema = schema;
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.data = data;
    if (!!parent) {
      this.world = { x: this.x + parent.world.x, y: this.y + parent.world.y };
    } else {
      this.world = { x: this.x, y: this.y };
    }
  }
}

export class Schema {
  public viewPort: ViewPort;
  public quadTree: any;
  private childrens: Sector[];
  public places: { [uuid: string]: Seat };
  private svg: HTMLElement;
  public sgContainer: HTMLElement;
  public igContainer: SVGGElement;
  public ogContainer: HTMLElement;
  private viewportElement: SVGGElement;
  private size = { w: 0, h: 0 };
  public geometrySize: ISize;
  private html = '';
  public minimal_place_size: ISize;
  private countState: any;
  private lang: string;

  private geoReady = false;
  private colorsReady = false;
  public symbols: { [name: string]: Function };
  private customObjects: CustomObject[] = [];
  public onSetGeometryCb: Function;
  public onSelectCb: Function;
  public onHoverCb: Function;
  public options: any;
  public settings: any;

  constructor(
    svg: HTMLElement,
    options: any,
    onSetGeometryCb: Function,
    onSelectCb: Function,
    onHoverCb: Function,
    lng: string,
    symbols?: { [name: string]: Function },
    countState?: any
  ) {
    this.svg = svg;
    this.getSize();
    this.viewportElement = this.svg.children[1] as SVGGElement;
    // containers
    this.sgContainer = this.viewportElement.children[0] as HTMLElement;
    this.igContainer = this.viewportElement.children[1] as SVGGElement;
    this.ogContainer = this.viewportElement.children[2] as HTMLElement;

    this.viewPort = new ViewPort(
      this,
      this.viewportElement,
      this.size.w,
      this.size.h,
      undefined,
      countState
    );
    this.places = {};
    this.symbols = symbols;
    this.onSetGeometryCb = onSetGeometryCb;
    this.onSelectCb = onSelectCb;
    this.onHoverCb = onHoverCb;
    this.options = options;
    this.countState = countState;
    this.lang = lng;
  }

  public setLanguage(lng: string) {
    this.lang = lng;
    this.customObjects.forEach((co: CustomObject) => co.setLanguage(this.lang));
  }

  public setGeometry(geometry: THallGeometry) {
    this.childrens = geometry.sectors.map(s => new Sector(this, s));
    this.html = this.childrens.reduce((acc, el) => {
      acc += el.render();
      return acc;
    }, '');
    this.geometrySize = { w: geometry.bb.w, h: geometry.bb.h };
    this.sgContainer.innerHTML = this.html;
    this.ogContainer.innerHTML = (geometry.objects || [])
      .map(x => {
        const co = new CustomObject(this, x, this.lang);
        this.customObjects.push(co);
        return co.render();
      })
      .join('');

    this.getSize();
    // console.log(this.size);
    this.viewPort.setSize(this.size);
    this.viewPort.onHovered$.subscribe(e => {
      if (this.onHoverCb) {
        this.onHoverCb(e.hovered, e.seat_uuid, e.clientXY, e.bb);
      }
    });

    this.viewPort.onSelect$.subscribe(e => {
      if (this.onSelectCb) {
        this.onSelectCb(e.selected, e.seat_uuid, e.clientXY, e.bb);
      }
    });

    this.svg.setAttribute('viewport', `0 0 ${this.size.w} ${this.size.h}`);

    const sectors = Array.prototype.slice.call(this.svg.querySelectorAll('.sector-path'), 0);
    if (sectors && sectors.length) {
      sectors.forEach(sce => sce.addEventListener('click', this.onSectorClick.bind(this)));
    }

    this.quadTree = quadtree<Seat>()
      .x(i => i.world.x)
      .y(i => i.world.y)
      .addAll(Object.values(this.places));
    const seats = this.svg.getElementsByClassName('seat');

    const place = Object.values(this.places)[0];
    this.minimal_place_size = { w: place.w, h: place.h };

    for (let i = 0; i < seats.length; i++) {
      const seat_uuid = seats[i].getAttribute('data-uuid');
      this.places[seat_uuid].setElement(seats[i]);
      this.minimal_place_size.w = Math.min(this.minimal_place_size.w, this.places[seat_uuid].w);
      this.minimal_place_size.h = Math.min(this.minimal_place_size.h, this.places[seat_uuid].h);
    }
    this.geoReady = true;
    this.colorize();

    if (this.onSetGeometryCb) {
      this.onSetGeometryCb();
    }
  }

  public onSectorClick(ev) {
    const target: Element = ev.target;
    const p_group = target.parentElement;
    const bb = p_group.getBoundingClientRect();

    this.viewPort.onSectorClick(p_group.getAttribute('id'), bb, ev);
  }

  public setSettings(settings: any) {
    this.settings = settings;
  }

  public setPlaces(
    tickets: IProductTicket[],
    actualPrices: { [price_uuid: string]: { amount: number; color: string; uuid: string } }
  ) {
    tickets.forEach(t => {
      if ((t.is_for_sale || t.is_in_cart) && parseInt(t.quantity, 10) > 0) {
        const p = this.places[t.place_uuid];
        if (p) {
          p.setColor(actualPrices[t.price_values[0]].color);
          p.setActive(true);
          // if (t.is_in_cart) {
          //   p.toggleSelected(true);
          // }
        }
      }
    });
    this.colorsReady = true;
    this.colorize();
  }

  private colorize() {
    if (!(this.colorsReady && this.geoReady)) {
      return;
    }
    Object.values(this.places).forEach(e => e.update());
  }

  public resize() {
    this.getSize();
    this.viewPort.setSize(this.size);
  }

  public getSize() {
    this.size.w = this.svg.parentElement.offsetWidth;
    this.size.h = this.svg.parentElement.offsetHeight;

    // console.log('getSize', this.size);
    return this.size;
  }

  public getElementByID(id: string) {
    return this.svg.querySelector(`#${id}`);
  }

  public zoomStep(n: number) {
    this.viewPort.zoomStep(n);
  }
}

class CustomObject extends DObject {
  private text: { value: { ru?: string; en?: string }; style?: string };
  private path: { value: string; style?: string };
  private have_path = false;
  private have_text = false;
  private lang: string;

  private objectUUID: string;

  constructor(schema: Schema, geometry: TCustomObjectGeometry, lang?: string) {
    super(schema, geometry.bb.x, geometry.bb.y, geometry.bb.w * 100, geometry.bb.h * 100, {
      uuid: geometry.uuid,
    });
    this.text = geometry.text;
    this.path = geometry.path;
    this.have_path = !isNil(this.path);
    this.have_text = !isNil(this.text);
    this.objectUUID = geometry.uuid;
    this.lang = lang;
  }

  public render() {
    let html = `<g class="custom-object" transform="translate(${this.x},${this.y})">`;
    let tv;
    if (this.text) {
      tv =
        prop(this.lang, this.text.value) ||
        prop('*', this.text.value) ||
        prop('ru', this.text.value);
    }
    if (this.have_path) {
      html += `<path class="custom-object-path" d="${this.path.value}" style="${this.have_text ? 'fill: none; stroke:none;' : ''
        }${this.path.style} " id="tp_${this.objectUUID}"></path>`;
      if (this.have_text) {
        html += `<text style="${this.text.style}" class="custom-object-text">
                  <textPath id="tpc_${this.objectUUID}" startOffset="50%" xlink:href="#tp_${this.objectUUID}">
                   <tspan id="txv_${this.objectUUID}">${tv}</tspan>
                  </textPath>
                 </text>`;
      }
    } else {
      html += `<text style="${this.text.style}" class="custom-object-text">
                <tspan id="txv_${this.objectUUID}">${tv}</tspan>
               </text>`;
    }

    html += '</g>';
    return html;
  }

  public setLanguage(lang: string): void {
    this.lang = lang;
    if (!this.have_text) {
      return;
    }

    const tv =
      prop(this.lang, this.text.value) || prop('*', this.text.value) || prop('ru', this.text.value);
    if (tv) {
      this.schema.getElementByID('txv_' + this.objectUUID).innerHTML = tv;
    }
  }
}

class Sector extends DObject {
  private childrens: Fragment[];
  private path: string;

  constructor(schema: Schema, geometry: TSectorGeometry) {
    super(schema, geometry.bb.x, geometry.bb.y, geometry.bb.w, geometry.bb.h, {
      uuid: geometry.uuid,
    });
    this.path = geometry.path;
    this.childrens = geometry.fragments.map(f => new Fragment(schema, f, this));
  }

  public render() {
    let sector_html = `<g class="sector" id="${this.data.uuid}" transform="translate(${this.x},${this.y})">`;
    if (prop('show_sectors_paths', this.schema.settings)) {
      sector_html += `<path class="sector-path" d="${this.path}"></path>`;
    }
    sector_html += this.childrens.reduce((acc, el) => {
      acc += el.render();
      return acc;
    }, '');
    sector_html += '</g>';
    return sector_html;
  }
}

class Fragment extends DObject {
  private childrens: Row[];

  constructor(schema: Schema, geometry: TFragmentGeometry, parent) {
    super(
      schema,
      geometry.bb.x,
      geometry.bb.y,
      geometry.bb.w,
      geometry.bb.h,
      { uuid: geometry.uuid },
      parent
    );
    this.childrens = geometry.rows.map(r => new Row(schema, r, this));
  }

  public render() {
    let fragment_html = `<g class="lst-hs_fragment" transform="translate(${this.x},${this.y})">`;
    fragment_html += this.childrens.reduce((acc, el) => {
      acc += el.render();
      return acc;
    }, '');
    fragment_html += '</g>';
    return fragment_html;
  }
}

class Row extends DObject {
  private childrens: Seat[];

  constructor(schema: Schema, geometry: TRowGeometry, parent) {
    super(
      schema,
      geometry.bb.x,
      geometry.bb.y,
      geometry.bb.w,
      geometry.bb.h,
      { uuid: geometry.uuid },
      parent
    );
    this.childrens = geometry.places.map(p => new Seat(schema, p, this));
  }

  public render() {
    let row_html = `<g class="lst-hs_row" transform="translate(${this.x},${this.y})">`;
    row_html += this.childrens.reduce((acc, el) => {
      acc += el.render();
      return acc;
    }, '');
    row_html += '</g>';
    return row_html;
  }
}

class Seat extends DObject {
  protected color = '7f7f7f';
  private inactiveColor = '7f7f7f';
  private uuid: string;
  protected domElement: Element;
  protected is_active = false;
  protected is_hovered = false;
  protected is_selected = false;
  private interactiveElement: SVGGElement;

  constructor(schema: Schema, geometry: TObjectGeometry, parent) {
    super(
      schema,
      geometry.cx,
      geometry.cy,
      geometry.w,
      geometry.h,
      {
        name: geometry.name,
        uuid: geometry.uuid,
        path: geometry.path,
        gi: geometry.gi,
        gs: geometry.gs,
      },
      parent
    );
    this.uuid = geometry.uuid;
    schema.places[this.uuid] = this;
  }

  public setActive(is_active = true) {
    this.is_active = is_active;
  }

  public getState(): boolean {
    return this.is_selected;
  }

  getStatus() {
    return this.is_active;
  }

  public setColor(color = '7f7f7f') {
    this.color = color;
    // console.log(this.color);
  }

  public setElement(el: Element) {
    this.domElement = el;
  }

  public setHovered(h = true) {
    this.is_hovered = h;
    this.update();
  }

  public toggleSelected(selected?: boolean) {
    if (selected === undefined) {
      this.is_selected = !this.is_selected;
    } else {
      this.is_selected = selected;
    }
    this.update();
    return this.is_selected;
  }

  public getUUID() {
    return this.uuid;
  }

  public getBoundingBox() {
    return this.domElement ? this.domElement.getBoundingClientRect() : undefined;
  }

  public render() {
    if (this.data.path) {
      let s = 1;
      if (!this.is_active) {
        s = s * 0.3;
      }
      return `<g class="seat" transform="translate(${this.x}, ${this.y})" data-uuid="${this.uuid}">
        <g transform="scale(${s}) translate(${-0.5 * this.w}, ${-0.5 * this.h})">
        <path d="${this.data.path}" style="fill: #${this.color}" fill="#${this.color}"></path>
        </g>
      </g>`;
    } else {
      let r = this.w / 2;
      if (!this.is_active) {
        r = r * 0.3;
      }
      return `<circle class="seat" data-uuid="${this.uuid}" cx="${this.x}" cy="${this.y}" r="${r}"
fill="#${this.color}" style="fill: #${this.color}"></circle>`;
    }
  }

  public update() {
    if (this.data.path) {
      let s = 1;
      let c = this.inactiveColor;
      if (this.is_active) {
        c = this.color;
      } else {
        s = s * 0.3;
      }

      if (this.is_active) {
        if (this.is_selected) {
          const el = document.createElementNS('http://www.w3.org/2000/svg', 'g');
          el.setAttribute('transform', `translate(${this.world.x},${this.world.y})`);
          this.schema.symbols.group_selected(
            this.schema.options,
            el,
            this.x,
            this.y,
            this.w,
            this.h,
            this.color,
            this.data.path,
            this.data.name,
            this.data.gs
          );
          this.setInteractiveElement(el);
        } else if (this.is_hovered && this.schema.options.deviceType === DeviceType.desktop) {
          if (this.schema.symbols.group_hovered) {
            const el = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            el.setAttribute('transform', `translate(${this.world.x},${this.world.y})`);
            this.schema.symbols.group_hovered(
              this.schema.options,
              el,
              this.x,
              this.y,
              this.w,
              this.h,
              this.color,
              this.data.path,
              this.data.name,
              this.data.gs
            );
            this.setInteractiveElement(el);
          } else {
            s = 1;
          }
        } else {
          this.setInteractiveElement();
        }
      }

      const pathE = this.domElement.querySelector('path');
      pathE.setAttribute('fill', `#${c}`);
      pathE.setAttribute('style', `fill:#${c}`);
      this.domElement
        .querySelector('g')
        .setAttribute('transform', `scale(${s}) translate(${-0.5 * this.w}, ${-0.5 * this.h})`);
    } else {
      let r = this.w / 2;
      let c = this.inactiveColor;
      if (this.is_active) {
        c = this.color;
      } else {
        r = r * 0.3;
      }

      if (this.is_active) {
        if (this.is_selected) {
          const el = document.createElementNS('http://www.w3.org/2000/svg', 'g');
          el.setAttribute('transform', `translate(${this.world.x},${this.world.y})`);
          this.schema.symbols.selected(
            this.schema.options,
            el,
            this.x,
            this.y,
            this.w,
            this.h,
            this.color,
            this.data.name
          );
          this.setInteractiveElement(el);
        } else if (this.is_hovered && this.schema.options.deviceType === DeviceType.desktop) {
          if (this.schema.symbols.hovered) {
            const el = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            el.setAttribute('transform', `translate(${this.world.x},${this.world.y})`);
            this.schema.symbols.hovered(
              this.schema.options,
              el,
              this.x,
              this.y,
              this.w,
              this.h,
              this.color,
              this.data.name
            );
            this.setInteractiveElement(el);
          } else {
            r = 2 * r;
          }
        } else {
          this.setInteractiveElement();
        }
      }

      if (this.interactiveElement && !this.is_hovered && !this.is_selected) {
        this.setInteractiveElement();
      }
      this.domElement.setAttribute('fill', `#${c}`);
      this.domElement.setAttribute('style', `fill:#${c}`);
      this.domElement.setAttribute('r', r.toString());
    }
  }

  private setInteractiveElement(el?: SVGGElement) {
    if (this.interactiveElement) {
      this.interactiveElement.remove();
      this.interactiveElement = undefined;
    }
    if (el) {
      this.interactiveElement = el;
      this.schema.igContainer.appendChild(el);
    }
  }
}
