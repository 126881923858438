import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { SecureApiService } from './secure-api.service';
import { IEvent } from '../types/IEvent';
import { ISettings } from '../types/Entities';

@Injectable({
  providedIn: 'root',
})
export class TransportService {
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

  constructor(
    private httpClient: HttpClient,
    private secureApi: SecureApiService,
    public global: GlobalService
  ) {
    // this.httpClient.get(this.serverCertificateUrl+'settings', { headers: this.headers }).
    // subscribe(response => console.log(response));
  }

  public login(body: { type: string; number: number }): Observable<any> {
    return this.secureApi.apiRunner('POST', `login`, body);
  }

  public getSettings(): Observable<ISettings> {
    return this.secureApi.apiRunner('GET', `settings`);
  }

  /* Gift Activate Page */
  public certificateValidation(uuid): Observable<any> {
    return this.httpClient.post(this.global.serverUrl + 'certificate/' + uuid + '/validation', {});
  }

  public certificateActivate(id): Observable<any> {
    //http://le.lastick.xd.unitix.cloud/widget/v1/certificate/activate
    //{"number": TBH104193}
    return this.secureApi.apiRunner('POST', 'certificate/activate', { number: id });
  }

  /* Gift Page */
  public showcaseList(): Observable<any> {
    return this.httpClient.get(this.global.serverUrl + 'showcase/list', { headers: this.headers });
  }

  /* Order Page */
  public showcaseOrder(uuid): Observable<any> {
    return this.httpClient.get(this.global.serverUrl + 'showcase/order/' + uuid, {
      headers: this.headers,
    });
  }

  /* Order Confirm Page */
  public showcasePaymentMethod(): Observable<any> {
    return this.httpClient.get(this.global.serverUrl + 'showcase/payment_method', {
      headers: this.headers,
    });
  }

  public newOrder(dt): Observable<any> {
    return this.httpClient.put(this.global.serverUrl + 'showcase/cart', dt, {
      headers: this.headers,
    });
  }

  public gvalidateMerchant(payment_method_uuid, dt): Observable<any> {
    return this.httpClient.post(
      this.global.serverUrl + `showcase/payment_method/${payment_method_uuid}/merchant_validation`,
      dt,
      { headers: this.headers }
    );
  }

  /* Events Page */
  public getEvents(date?, date_interval?, conditions?, count?, page?): Observable<any> {
    // conditions, page, count
    // https://widget.lastick.xd.unitix.cloud/api/widget/v1/nearest_events?page=0&count=200&date=2018-11-29&date_interval=2
    // &count=${count}&page=${page}
    //?date=${date}&date_interval=${date_interval}&conditions=${JSON.stringify(conditions)}${count ? `&count=${count}` : ''}
    return this.secureApi.apiRunner(
      'GET',
      `nearest_events?${date ? `date=${date}` : ''}${
        date_interval ? `&date_interval=${date_interval}` : ''
      }&conditions=${JSON.stringify(conditions)}`
    );
  }

  public getEventsByDate(date, date_interval = 2, conditions, page?): Observable<any> {
    //nearest_events_by_date?date=2018-12-03&date_interval=14&conditions={"tags":["41d14ebf-fe4c-44dc-8059-d130311d51e8"]}
    return this.secureApi.apiRunner(
      'GET',
      `nearest_events_by_date?${date ? `date=${date}` : ''}${
        date_interval ? `&date_interval=${date_interval}` : ''
      }&conditions=${JSON.stringify(conditions)}`
    );
  }

  public getCalendarLenta(conditions?): Observable<any> {
    //nearest_events_by_date?date=2018-12-03&date_interval=14&conditions={"tags":["41d14ebf-fe4c-44dc-8059-d130311d51e8"]}
    return this.secureApi.apiRunner(
      'GET',
      `events_calendar?${conditions ? `conditions=${JSON.stringify(conditions)}` : ''}`
    );
  }

  /* Event page */
  public getEvent(uuid: string): Observable<IEvent> {
    return this.secureApi.apiRunner('GET', `events/${uuid}`) as Observable<IEvent>;
  }

  public getHall(uuid: string): Observable<any> {
    return this.secureApi.apiRunner('GET', `hall/${uuid}`);
  }

  public getSpot(uuid: string): Observable<any> {
    return this.secureApi.apiRunner('GET', `spot/${uuid}`);
  }

  public getSpots(): Observable<any> {
    return this.secureApi.apiRunner('GET', `spot`);
  }

  /* Event Shedules page */
  public getTickets(uuid: string): Observable<any> {
    return this.secureApi.apiRunner('GET', `schedule/${uuid}/tickets`);
  }

  public getEventsTickets(aUuid: string[]): Observable<any> {
    // http://le.lastick.xd.unitix.cloud/widget/v1/schedules?events=["9883668d-e74f-4424-877f-e1cfda7797b4"]
    return this.secureApi.apiRunner('GET', `schedules?events=["${aUuid}"]`);
  }

  /* Geometry data */
  public getGeometry(uuid: string): Observable<any> {
    return this.secureApi.apiRunner('GET', `geometry/${uuid}`);
  }

  public getSchedule(uuid: string): Observable<any> {
    return this.secureApi.apiRunner('GET', `schedule/${uuid}`);
  }

  public getSchedules(
    uuid: string,
    key: 'events' | 'spots' | 'halls' | 'tags' = 'events',
    date?: string,
    conditions?
  ): Observable<any> {
    return this.secureApi.apiRunner(
      'GET',
      `schedules?${key}=${JSON.stringify([uuid])}${date ? `&date=${date}` : ''}${
        conditions ? `&conditions=${JSON.stringify(conditions)}` : ''
      }`
    );
  }

  /* Cart work */
  public getCart(): Observable<any> {
    return this.secureApi.apiRunner('GET', 'cart');
  }

  public addItemCart(itemUuid: string, priceUuid: string): Observable<any> {
    return this.secureApi.apiRunner('PUT', 'cart', {
      item_uuid: itemUuid,
      price_uuid: priceUuid,
    });
  }

  public syncCart(items: any[], lang: string): Observable<any> {
    return this.secureApi.apiRunner('POST', `cart`, { items, lang });
  }

  public dellItemCart(uuid: string): Observable<any> {
    return this.secureApi.apiRunner('DELETE', `cart/${uuid}`);
  }

  public clearCart(): Observable<any> {
    return this.secureApi.apiRunner('DELETE', `cart`);
  }

  ///cart/payment_method
  public getCartPaymentMethod(): Observable<any> {
    return this.secureApi.apiRunner('GET', 'cart/payment_method');
  }

  public setCartPaymentMethod(body: any): Observable<any> {
    return this.secureApi.apiRunner('POST', 'cart/payment_method', body);
  }

  public newCartOrder(data): Observable<any> {
    return this.secureApi.apiRunner('POST', 'cart/order', data);
  }

  public getOrder(uuid: string): Observable<any> {
    return this.secureApi.apiRunner('GET', `order/${uuid}`);
  }

  public validateMerchant(payment_method_uuid, data): Observable<any> {
    return this.secureApi.apiRunner('POST', `merchant_validation/${payment_method_uuid}`, data);
  }

  public applyPromocode(promocode: string): Observable<any> {
    return this.secureApi.apiRunner('PUT', `cart/promocode/${promocode}`);
  }

  public removePromocode(promocode: string): Observable<any> {
    return this.secureApi.apiRunner('DELETE', `cart/promocode/${promocode}`);
  }
}
