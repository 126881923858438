import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../components/layout/layout.component';
import { LayoutHeaderBottomTemplateDirective } from './layout-templates.directive';
import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CartModule } from '../cart/cart.module';
import { AngularResizedEventModule } from 'angular-resize-event';

const declarations = [
  LayoutComponent,
  HeaderComponent,
  FooterComponent,
  LayoutHeaderBottomTemplateDirective,
];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    SharedModule,
    AngularSvgIconModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    CartModule,
    AngularResizedEventModule,
  ],
  exports: declarations,
})
export class LayoutModule {}
