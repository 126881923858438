import defaultLanguage from './../assets/i18n/ru.json';
import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './services/global.service';
import { StorageService } from './services/storage.service';
import { CartService } from './services/cart.service';
import { Meta } from '@angular/platform-browser';
import { IBackground } from './directives/background.directive';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {IMultiLangField} from './types/Entities';
import {WarningPopupService} from './services/warning-popup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app';
  public bg: IBackground;
  private resizeListener;
  public windowHeight: number;

  constructor(
    private translate: TranslateService,
    public global: GlobalService,
    private storage: StorageService,
    private cart: CartService,
    private meta: Meta,
    private device: DeviceDetectorService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private warningPopup: WarningPopupService
  ) {
    if (!global.selectedLang) {
      translate.setTranslation(global.defaultLang, defaultLanguage);
      translate.use(global.defaultLang);
      combineLatest([this.route.params, this.global.finalQueryParams(route), global.settingsInit]).subscribe(([params, query]) => {
        translate.setDefaultLang(global.defaultLang);
        const lang =
          query.lang || params.lang || storage.getItem('language') || translate.getBrowserLang();
        const l = lang && this.global.languages.some(_l => _l.val === lang) ? lang : this.global.defaultLang;
        this.global.switchLanguage(l, translate);
        this.global.langInit.next(l);
      });
    }
    const deviceInfo = this.device.getDeviceInfo();
    const isDesktop = this.device.isDesktop();
    this.global.setDevice(deviceInfo, isDesktop);
    const d = new Date();
    const timeMark = storage.getItem('time_mark');

    if (
      !timeMark ||
      (timeMark &&
        cart.getCartItemsOrderFromStorage().length > 0 &&
        d.getTime() - parseInt(timeMark, 10) >= cart.timeToDropCart)
    ) {
      this.cart.dropCart(true).then(() => {
        this.cart.reloadCart();
      });
      storage.removeItem('visitors');
    } else {
      this.cart.reloadCart();
    }
    storage.setItem('time_mark', d.getTime());
    global.showHideLoader.next(true);

    global.settings$.subscribe(s => {
      if (
        s.widget_settings.showCovidMessages &&
        // !this.storage.getItem('covidWarningPopup') &&
        !location.pathname.match(/^\/gift$/) && !location.pathname.match(/^\/order\/confirm$/) &&
        !location.pathname.match(/^\/widget\/gift$/) && !location.pathname.match(/^\/widget\/order\/confirm$/)
      ) {
        setTimeout(() => {
          this.warningPopup.show({id: 'covid'});
          const warnSub = this.warningPopup.showHide$.subscribe(v => {
            if (v && !v.show) {
              // this.storage.setItem('covidWarningPopup', '1');
              warnSub.unsubscribe();
            }
          });
        });
      }
    });
  }

  ngOnInit(): void {
    this.global.background$.subscribe(bg => {
      if (bg) {
        this.windowHeight = window.innerHeight + 120;
        this.resizeListener = this.renderer.listen('window', 'resize', () => {
          this.windowHeight = window.innerHeight + 120;
        });
        if (bg.target === 'body') {
          this.renderer.addClass(document.body, `body-bg`);
          this.renderer.addClass(document.body, `body-bg_theme-${bg.theme || '1'}`);
          this.renderer.setStyle(document.body, 'background-image', `url(${bg.src})`);
          this.renderer.setAttribute(document.body, 'data-bg-theme', bg.theme || '1');
        }
      } else {
        if (this.resizeListener) {
          this.resizeListener();
        }
        this.windowHeight = undefined;
        this.renderer.removeStyle(document.body, 'background-image');
        this.renderer.removeClass(document.body, `body-bg`);
        this.renderer.removeClass(
          document.body,
          `body-bg_theme-${document.body.getAttribute('data-bg-theme')}`
        );
        this.renderer.removeAttribute(document.body, 'data-bg-theme');
      }
      if (bg && bg.target !== 'body') {
        Promise.resolve(null).then(() => (this.bg = bg));
      } else {
        this.bg = null;
      }
    });
    this.global.bodyOverflow$.subscribe(show => {
      const html = document.querySelector('html');
      this.renderer.removeClass(html, 'ovh');
      if (!show) {
        // this.storage.setItem('body_scroll', pageYOffset, true);
        this.renderer.addClass(html, 'ovh');
      }
      // else {
      //   window.scrollTo(0, this.storage.getItem('body_scroll', true) || 0);
      // }
    });
  }

  ngAfterViewInit(): void {
    this.global.showHideLoader.next(false);
  }

  onRouterActivate(data: any) {
    window.scrollTo(0, 0);
    // switch (document.location.pathname) {
    //   case '/events':
    //     this.meta.addTag({name: 'og:title', content: 'og title'});
    //     break;
    // }
  }
}
