import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { IInfoPopup } from '../../types/IInfoPopup';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.less'],
})
export class InfoComponent implements OnInit, OnChanges {
  @Input() top = 0;
  @Input() checkTop = true;
  currentTop = 0;
  show: boolean = false;
  type: string = '';
  timer: number = 3000;
  msg: string;
  scrollListener;

  constructor(public global: GlobalService, private renderer: Renderer2) {
    this.global.showHideInfo.subscribe((e: IInfoPopup) => {
      this.type = e.type; //error/success
      this.msg = e.msg;
      this.timer = e.timer ? e.timer : null;

      this.show = true;
      this.listenCheckTop();
      if (e.timer) {
        setTimeout(() => {
          this.show = false;
          if (this.scrollListener) {
            this.scrollListener();
          }
        }, this.timer);
      }
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.top) {
      this.checkAndSetTop();
    }
  }

  listenCheckTop() {
    if (this.checkTop) {
      this.checkAndSetTop();
      this.scrollListener = this.renderer.listen('window', 'scroll', e => {
        this.checkAndSetTop();
      });
    }
  }

  checkAndSetTop() {
    if (this.checkTop && window.pageYOffset > this.top) {
      this.currentTop = 0;
    } else {
      this.currentTop = this.top;
    }
  }
}
