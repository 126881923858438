import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransportService } from '../../services/transport.service';
import { LoggerService } from '../../services/logger.service';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { IEvent } from '../../types/IEvent';
import { IHall } from '../../types/IHall';
import { ILanguages } from '../../types/ILanguages';
import { parse, isSameDay } from 'date-fns';
import { ISchedule } from '../../types/ISchedule';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMediaInfo, IWidgetSettings } from '../../types/Entities';

@Component({
  selector: 'app-event-schedules',
  templateUrl: './event-schedules.component.html',
  styleUrls: ['./event-schedules.component.less'],
})
export class EventSchedulesComponent implements OnInit {
  event_uuid: string;
  eDt: IEvent;
  eDtMedia: IMediaInfo[];
  eDtPoster: IMediaInfo;
  media_info: IMediaInfo[];
  head_image: IMediaInfo;
  image_init = false;
  eventDt: string;
  hallId: string;
  schedules: ISchedule[];
  linked: ISchedule[];
  viewSchedules: boolean = true;
  desc;
  widget_settings: IWidgetSettings = {};

  canDisplayPosters = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transport: TransportService,
    private logger: LoggerService,
    public global: GlobalService,
    public translate: TranslateService
  ) {}

  ngOnInit() {

    console.log('EV-SCHED with GEOMETRY')

    this.global.showHideLoader.next(true);
    combineLatest([this.route.params, this.route.queryParams]).subscribe(([params, query]) => {
      if (query) {
        this.eventDt = query.dt;
        this.hallId = query.event_hall;
      }
      combineLatest([
        this.transport.getEvent(params.event_uuid).pipe(
          map(eDt => {

            console.log('EDt', eDt)
            this.eDt = eDt;
            this.setEdtProps();
            this.getTickets();
            return eDt;
          })
        ),
        query.event_hall ? this.transport.getHall(query.event_hall) : of(null),
        this.global.settings$,
      ]).subscribe(([eDt, hall, settings]) => {


        console.log('THIS IS COMBINATION', eDt, hall, settings);

        // console.log('settings', settings)
        if (hall) {
          this.media_info = hall.media_info || [];
        }
        if (settings) {
          this.widget_settings = settings.widget_settings;
          this.canDisplayPosters = settings.name.ru === 'Zapomni';
        }
        this.setHeadImage();
        this.global.showHideLoader.next(false);
      });
    });
  }

  setEdtProps() {
    if (this.eDt) {
      this.event_uuid = this.eDt.uuid;
      this.eDtMedia = this.eDt.media || [];
      this.eDtPoster = this.eDt.poster || (this.eDt.posters && this.eDt.posters.showcase ? this.eDt.posters.showcase : null);
      this.media_info = this.eDt.NearestSchedule ? this.eDt.NearestSchedule.Hall.media_info : [];
      this.desc = this.propIsExist('description') ? this.eDt.description : null;
    }
  }

  setHeadImage() {
    this.image_init = true;
    this.head_image =
      this.widget_settings && this.widget_settings.own_event
        ? this.eDtMedia[0] || this.eDtPoster || this.media_info[0]
        : this.media_info[0] || this.eDtMedia[0] || this.eDtPoster;
  }

  getTickets() {
    this.global.showHideLoader.next(true);
    this.transport
      .getEventsTickets([this.event_uuid])
      .toPromise()
      .then(
        success => {
          this.logger.l('Successfully Completed');
          this.logger.l(success);

          this.schedules = success.filter((item, i) => {
            const condition = this.eventDt
              ? isSameDay(item.begin, this.eventDt)
              : true;

            if (condition) {
              item.PriceValues.sort((a, b) => a.amount - b.amount);
            }

            return condition;
          });
          if (this.schedules && this.schedules.length) {
            this.linked = this.schedules.reduce((acc, _h) => {
              if (_h.LinkedSchedules && _h.LinkedSchedules.length) {
                const sorted = _h.LinkedSchedules.sort((a, b) => {
                  return parse(a.begin).getTime() - parse(b.begin).getTime();
                });
                acc = acc.concat(sorted);
              }
              return acc;
            }, []);

            if (!this.hallId) {
              this.media_info = this.schedules[0].Hall.media_info || [];
            }
          }
          this.global.showHideLoader.next(false);
        },
        error => {
          this.logger.l('Error Completed');
          this.global.showHideLoader.next(false);
        }
      );
  }

  propIsExist(prop) {
    const event = this.eDt;
    const val = event[prop];

    return val && Object.keys(val).filter(lang => (val[lang] || '').trim().length > 0).length > 0;
  }
  dateDisplayMode(): string {
    if (!this.schedules) {
      return 'd MMMM, EEEE';
    }
    return !!this.schedules.find(el => el.has_geometry === true) ? 'd MMMM, EEEE' : 'd MMMM, EEEE, H:mm';
  }
}
