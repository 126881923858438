import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { LayoutHeaderBottomTemplateDirective } from '../../modules/layout/layout-templates.directive';
import { ResizedEvent } from 'angular-resize-event';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit, OnDestroy {
  @Input() theme: string;
  @Input() footerTheme: string;
  @Input() footerPos: string;
  @Input() logo;
  @Input() bg;
  @Input() header_title;
  @Input() header_sub_title;
  @Input() transparentHeader;
  @Input() showNav = false;
  @Input() showHeaderPromo = true;
  @Input() showHeaderCompanyLogo = false;
  @Input() showSearch = false;
  @Input() showBack = true;
  @Input() showCart = true;
  @Input() showCert = true;
  @Input() showCloseFrame = false;
  @Input() showRules = false;
  @Input() focusSearch;
  @Input() headerFixed;
  @Input() nav;
  @Input() navActiveItem;
  @Input() headerSearchText;
  @Input() backRoute;
  @Input() headerActiveSearch;
  @Input() headerBottomContent;
  @Input() footer = true;
  @Input() headerTheme;
  @Input() manualLogo = false;

  @Output() headerTagClick: EventEmitter<any> = new EventEmitter();
  @Output() headerSearchToggle: EventEmitter<any> = new EventEmitter();
  @Output() headerSearchChange: EventEmitter<any> = new EventEmitter();
  @Output() headerSearchSubmit: EventEmitter<any> = new EventEmitter();
  @Output() headerViewSearch: EventEmitter<any> = new EventEmitter();

  @ContentChild(LayoutHeaderBottomTemplateDirective, { read: TemplateRef, static: false })
  headerBottomTemplate: TemplateRef<any>;

  private subscriptions: Subscription[] = [];

  public headMargin = 0;
  public rules;
  public rulesData;

  // private localLogo = '';

  constructor(
    public global: GlobalService,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.showRules) {
      this.getRules();
      this.subscriptions.push(
        this.translate.onLangChange.subscribe(lang => {
          this.getRules();
        })
      );
    }

    // console.log('LOOK', 'theme',this.theme, 'logo',this.logo, 'bg',this.bg, 'comLog',this.showHeaderCompanyLogo)
    // if (this.manualLogo) {
    //   this.localLogo = '\'assets/img/mariinsky-logo@2x.png\';';
    // } else {
    //   this.localLogo = this.global.settings.widget_settings.head_logo;
    // }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  getRules() {
    this.translate.get('Rules').subscribe(values => {
      this.rules = values;
      this.rulesData = Object.keys(values).map(k => {
        if (!values[k].Data) {
          return;
        }
        return Object.keys(values[k].Data).map(index => {
          const out = {};
          const ruleData = values[k].Data[index];
          Object.keys(ruleData).map(valueKey => {
            out[valueKey] = ruleData[valueKey]();
          });
          return out;
        });
      });
    });
  }

  setHeadMargin(e?: ResizedEvent) {
    this.headMargin = e.newHeight;
  }
}
