import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private storage: StorageService) {}

  isNotify(form: FormGroup, key) {
    return form.controls[key].touched && !form.value[key].length;
  }

  markFormGroupTouched(form: FormGroup, dirty = true, focus = true, invalidControl?) {
    (<any>Object).values(form.controls).forEach(control => {
      control.markAsTouched();

      if (dirty) {
        control.markAsDirty();
      }
      if (focus && control.nativeElement && !control.valid && !invalidControl) {
        control.nativeElement.focus();
        invalidControl = control;
      }
      if (control.controls) {
        this.markFormGroupTouched(control, dirty, focus, invalidControl);
      }
    });
  }

  saveFormValues(name: string, values: {} = {}) {
    this.storage.setItem(name, JSON.stringify(values));
  }

  getFormValues(name: string) {
    const v = this.storage.getItem(name);
    return v ? JSON.parse(v) : {};
  }
}
