import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, ActivationEnd } from '@angular/router';

import { GlobalService } from '../services/global.service';
import { TransportService } from '../services/transport.service';
import { LoggerService } from '../services/logger.service';
import { CartService } from '../services/cart.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.less'],
})
export class LayoutsComponent implements OnInit, AfterViewInit {
  bgImg = true;
  bgImgPage = false;
  isSchedulesPage = false;
  event_uuid: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggerService,
    public global: GlobalService,
    private transport: TransportService,
    public cart: CartService,
    private storage: StorageService
  ) {
    // this.global.currentEvent = storage.getItem('event') ? JSON.parse(sessionStorage.getItem('event')) : undefined;
    // this.global.activeSertificate = sessionStorage.getItem('activeSertificate') ? JSON.parse(sessionStorage.getItem('activeSertificate')) : undefined;

    this.global.cart = storage.getItem('cart') ? JSON.parse(storage.getItem('cart')) : undefined;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.useBg(event.url.replace(/\/?\?.*/, ''));
      }

      if (event instanceof ActivationEnd) {
        const currEvent = this.global.currentEvent;
        const { event_uuid } = event.snapshot.params;

        if (!currEvent && event_uuid) {
          this.global.showHideLoader.next(true);
          this.transport
            .getEvent(event_uuid)
            .toPromise()
            .then(
              s => {
                this.global.currentEvent = s;
                // this.storage.setItem('event', JSON.stringify(s));
                this.global.eventChange$.next(s);
                this.global.showHideLoader.next(false);
              },
              () => {
                this.global.showHideLoader.next(false);
              }
            );
        }
      }
    });

    this.route.queryParams.subscribe(params => {
      const { event_hall } = params;

      if (event_hall) {
        this.global.showHideLoader.next(true);
        this.transport
          .getHall(event_hall)
          .toPromise()
          .then(
            hall => {
              this.global.currentHall = hall;
              this.global.hallChange$.next(hall);
              this.global.showHideLoader.next(false);
            },
            () => {
              this.global.showHideLoader.next(false);
            }
          );
      }
    });

    this.useBg(this.router.url.replace(/\/?\?.*/, ''));
  }

  useBg(url: string) {
    const schedulesPage = /\/events\/.+\/schedules/;
    const entrySchedulesPage = /\/events\/.+/;
    const descriptionPage = /\/events\/.+\/description/;
    const resRegExsSchedulesPage = schedulesPage.exec(url);
    const resRegExsEntrySchedulesPage = entrySchedulesPage.exec(url);
    const isEventSchedulePage = resRegExsSchedulesPage ? resRegExsSchedulesPage[0] : false;
    const isEntrySchedulePage = resRegExsEntrySchedulesPage
      ? resRegExsEntrySchedulesPage[0]
      : false;
    const resRegExsDescriptionPage = descriptionPage.exec(url);
    const isEventDescriptionPage = resRegExsDescriptionPage ? resRegExsDescriptionPage[0] : false;

    switch (url) {
      case '/':
        this.bgImg = true;
        this.isSchedulesPage = false;
        this.bgImgPage = false;
        break;
      case '/gift/activate':
        this.bgImg = true;
        this.isSchedulesPage = false;
        this.bgImgPage = false;
        break;
      case '/events':
        this.bgImg = false;
        this.isSchedulesPage = false;
        this.bgImgPage = false;
        break;
      case '/gift':
        this.bgImg = true;
        this.isSchedulesPage = false;
        this.bgImgPage = false;
        break;
      case '/order/confirm':
        this.bgImg = false;
        this.isSchedulesPage = false;
        this.bgImgPage = false;
        break;
      case isEventSchedulePage || isEntrySchedulePage:
        this.bgImg = true;
        this.isSchedulesPage = true;
        this.bgImgPage = true;
        break;
      // case isEventSchedulePage || isEventDescriptionPage  :
      //   this.isSchedulesPage = true;
      //   this.bgImg = false;
      //   this.bgImgPage = true;
      //
      //   if (!this.global.currentEvent) {
      //     const aUrl = url.split('/');
      //     this.event_uuid = aUrl[2];
      //
      //     this.transport.getEvent(this.event_uuid).subscribe(
      //       s => {
      //         this.logger.l(s);
      //         this.global.currentEvent = s;
      //         const event = this.global.currentEvent;
      //         if (event.poster !== undefined) {
      //           this.global.bgImgSchedulesPage = event.poster !== null ? this.global.imgUrl + event.poster.path : '';
      //         }
      //         sessionStorage.setItem('event', JSON.stringify(s));
      //       },
      //       e => this.logger.e(e)
      //     );
      //   } else {
      //     const event = this.global.currentEvent;
      //     if (event.poster !== undefined) {
      //       this.global.bgImgSchedulesPage = event.poster !== null ? this.global.imgUrl + event.poster.path : '';
      //     }
      //   }
      //   break;
      default:
        this.bgImg = false;
        this.isSchedulesPage = false;
        this.bgImgPage = false;
        break;
    }
  }

  onRouterActivate() {
    this.global.head_image = '';
  }

  ngOnInit() {
    // this.cart.reloadCart();
    // this.cart.getFromStorage();
    // this.cart.getAllowedPaymentMethods();
    this.global.showHideLoader.next(true);
    this.global.eventChange$.next(this.global.currentEvent);
  }

  ngAfterViewInit(): void {
    this.global.showHideLoader.next(false);
  }
}
