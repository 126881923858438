import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { DatePipe } from "@angular/common";
import { parse } from "date-fns";

@Pipe({
  name: 'mandatetime',
  pure: false,
})
export class MandatetimePipe implements PipeTransform {
  constructor(public translate: TranslateService, public global: GlobalService) {}

  /* Возвращает дату в формате -- 9 июня, пятница, 19:00 */
  transform(dt: string, type?: string): string {
    const date = parse(dt);

    if (isNaN(date.getTime())) return;

    const day = this.global.days[date.getDay()][this.translate.currentLang].name;
    const month = this.global.month[date.getMonth()][this.translate.currentLang].name;
    const numbr = date.getDate();
    const time =
      date.getHours() +
      ':' +
      (date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes());
    let result = numbr + ' ' + month + ', ' + day + ', ' + time;

    switch (type) {
      case 'm:d':
        result = `${numbr} ${month}, ${day}`;
        break;
      case 'm:d:y':
        // result = format(date, 'DD.MM.YYYY hh:mm');
        result = `${numbr} ${month}, ${day}, ${date.getFullYear()}`;
        break;
      case 't':
        result = time;
        break;
    }
    return result;
  }
}
