import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { GlobalService } from '../services/global.service';
import { InfoService } from '../services/info.service';
import { SecureApiService } from '../services/secure-api.service';
import { Observable } from 'rxjs/index';
import { finalize, tap, map } from 'rxjs/internal/operators';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  status = '';

  constructor(
    public global: GlobalService,
    private secureApi: SecureApiService,
    private info: InfoService,
    private translate: TranslateService,
    private storage: StorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = Date.now();
    const token = this.storage.getItem('x-auth-token');

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    /*else {
               const xmlHttp = new XMLHttpRequest();
               xmlHttp.open( "GET", this.global.serverCertificateUrl+'settings', false );
               xmlHttp.send( null );
               localStorage.setItem('auth_token', xmlHttp.getResponseHeader('x-auth-token'));
           }
   */
    /*
            if (!this.global.settings){
                const xmlHttp = new XMLHttpRequest();
                xmlHttp.open( "GET", this.global.serverCertificateUrl+'settings', false );
                xmlHttp.send( null );

                if(xmlHttp.status === 200){
                    this.global.settings = JSON.parse(xmlHttp.response);
                }
                console.warn(JSON.parse(xmlHttp.response));
            }
    */
    /*if (!this.global.settings){
        const getSettings = this.secureApi.getSettings();
        getSettings.subscribe(success => {
            console.warn(success);
            this.global.settings = success;
        },
            fail => {
                console.warn(fail);
        });
    }*/

    return next
      .handle(request)
      .pipe(
        map(event => {
          /*if (event instanceof HttpResponse) {
            console.warn(event.headers.get('x-auth-token'));
                localStorage.setItem('auth_token', event.headers.get('x-auth-token'))
            }
            */
          return event;
        })
      )
      .pipe(
        tap(
          event => {
            this.status = '';
            if (event instanceof HttpResponse) {
              this.status = 'succeeded';
            }
          },
          serverError => {
            this.status = 'failed';
            this.translate
              .get('ERRORS')
              .toPromise()
              .then(err => {
                const { message } = serverError.error;
                

                if (serverError.status === 406 && message === 'AUTH_FAIL') {
                  return;
                }
                let value =
                  typeof message !== 'string' || !err || !err[message]
                    ? err['UNEXPECTED']()
                    : err[message]();

                if (serverError.status === 406 && message === 'CART_ITEM_RESTRICTED') {
                      const {restrictions} = serverError.error;
                      this.global.cartRestriction.emit(restrictions)
                      value = err['RESTRICTED']()
                }

                switch (message) {
                  case 'ALREADY_ACTIVATED':
                    this.global.showHideInfoPopup$.next({ id: 'isActivatedPopup', show: true });
                    break;
                  default:
                    this.info.showError(value);
                    break;
                }

               
              });
          }
        ),
        finalize(() => {
          const elapsedTime = Date.now() - startTime;
          const message =
            request.method +
            ' ' +
            request.urlWithParams +
            ' ' +
            this.status +
            ' in ' +
            elapsedTime +
            'ms';

          this.logDetails(message);
        })
      );
  }

  private logDetails(msg: string) {
    if (!environment.production) {
      console.log(msg);
    }
  }
}
