import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
  ContentChild,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @ContentChild('child', { static: false }) child: ElementRef;
  // @HostBinding('class')
  // class = 'click-outside';
  // get elClass(): string {
  //   return this._elementClass.join(' ');
  // }

  @Output()
  public clickedOutside = new EventEmitter();

  class = 'click-outside';
  show = false;
  listener: any;

  constructor(private _elementRef: ElementRef, private renderer: Renderer2) {
    renderer.addClass(this._elementRef.nativeElement, this.class);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.listener) {
      this.listener = this.renderer.listen('document', 'click', ev => {
        const $el = this._elementRef.nativeElement;

        if (!$el.contains(ev.target)) {
          this.listener = this.listener();
          this.clickedOutside.emit();
        }
      });
    }
  }
}
