import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMultiLangField} from '../../types/Entities';


export interface ISelectItem {
  name: IMultiLangField;
  description?: IMultiLangField;
  value: any;
  src?: any;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less']
})
export class SelectComponent implements OnInit {
  @Input() items: ISelectItem[];
  @Input() selectedValue: any;
  @Input() disabled = false;

  @Output() changed: EventEmitter<ISelectItem> = new EventEmitter<ISelectItem>();

  public currSelectedValue: any;

  constructor() { }

  ngOnInit() {
    this.currSelectedValue = this.selectedValue;
  }

  onChange(item: ISelectItem) {
    this.changed.emit(this.items.find(_item => _item.value === item.value));
  }
}
