import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { IMultiLangField, IMultilangString } from '../types/Entities';


export interface IExcursionPopupShowHide {
  show?: boolean;
  id?: string;
  info?: IMultilangString[];
}

@Injectable({
  providedIn: 'root'
})
export class ExcursionPopupService {
  private showHideSource: BehaviorSubject<IExcursionPopupShowHide> = new BehaviorSubject<IExcursionPopupShowHide>(null);
  public showHide$: Observable<IExcursionPopupShowHide> = this.showHideSource.asObservable();

  constructor() { }

  show(data: IExcursionPopupShowHide) {
    this.showHideSource.next({show: true, ...data});
  }

  hide(data: IExcursionPopupShowHide) {
    this.showHideSource.next({show: false, ...data});
  }
}
