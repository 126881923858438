import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BuyComponent } from './pages/buy/buy.component';
import { GiftComponent } from './pages/gift/gift.component';
import { GiftActivateComponent } from './pages/gift-activate/gift-activate.component';
import { OrderConfirmComponent } from './pages/order-confirm/order-confirm.component';
import { EventSchedulesComponent } from './pages/event-schedules/event-schedules.component';
import { EventTableComponent } from './pages/event-table/event-table.component';
import { PromocodePopupComponent } from './components/promocode-popup/promocode-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeArm from '@angular/common/locales/hy';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CardActivateComponent } from './pages/card-activate/card-activate.component';
import { SharedModule } from './modules/shared/shared.module';
import { CartModule } from './modules/cart/cart.module';
import { LayoutModule } from './modules/layout/layout.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LayoutsComponent } from './layouts/layouts.component';
import { OrderCertStatusComponent } from './pages/order-cert-status/order-cert-status.component';
import { OrderConfirmActivateComponent } from './pages/order-confirm-activate/order-confirm-activate.component';
import { OrderStatusComponent } from './pages/order-status/order-status.component';
import { HallSchemaModule } from './components/hall_schema/hall_schema.module';
import { DragScrollModule } from 'ngx-drag-scroll';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeRu);
registerLocaleData(localeArm);

@NgModule({
  declarations: [
    AppComponent,
    BuyComponent,
    GiftComponent,
    GiftActivateComponent,
    OrderConfirmComponent,
    EventSchedulesComponent,
    EventTableComponent,
    CardActivateComponent,
    LayoutsComponent,
    OrderCertStatusComponent,
    OrderConfirmActivateComponent,
    OrderStatusComponent,
    PromocodePopupComponent,

    // BlurComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    AngularSvgIconModule,
    // HallSchemaModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    SharedModule.forRoot(),
    CartModule.forRoot(),
    LayoutModule,
    HallSchemaModule,
    DragScrollModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
