import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rate',
})
export class RatePipe implements PipeTransform {
  transform(rate: string, args?: any): any {
    if (!rate) return '';
    return rate.indexOf('+') < 0 ? `${rate}+` : rate;
  }
}
