import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { tap } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-card-activate',
  templateUrl: './card-activate.component.html',
  styleUrls: ['./card-activate.component.less'],
})
export class CardActivateComponent implements OnInit, OnDestroy {
  public viewStep: 'inp' | 'result' = 'inp';
  public form: FormGroup;
  public questLink = 'https://pushkinmuseum.lastick.ru/pushkin_quest.pdf';
  public showQuest = false;
  public quest$ = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(
    private api: TransportService,
    private router: Router,
    private fb: FormBuilder,
    public global: GlobalService,
    public cart: CartService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.quest$.subscribe(() => {
        window.open(this.questLink, '_blank');
      })
    );
    this.form = this.fb.group({
      number: ['', [Validators.required, Validators.pattern(/^\d{6}$/), Validators.maxLength(6)]],
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  onSubmit() {
    // let windowRef = window.open(null, '_blank');
    // windowRef.blur();
    // window.self.focus();
    this.api
      .login({
        type: this.global.settings.widget_settings.authentication,
        number: this.form.value.number,
      })
      .pipe(
        tap(res => {
          // if (res.type === 'MASTERCARD') {
          //   this.showQuest = true;
          // windowRef.location = 'https://pushkinmuseum.lastick.ru/pushkin_quest.pdf';
          // windowRef.focus();
          // } else {
          // this.showQuest = res.type === 'MASTERCARD';
          // windowRef.close();
          // }
          return of(res);
        })
        // catchError((err) => {
        //   window.open('https://pushkinmuseum.lastick.ru/pushkin_quest.pdf', '_blank');
        //   return throwError(err);
        //   // return of(err);
        // })
      )
      .subscribe(res => {
        if (res.type === 'MASTERCARD_PLATINUM') {
          this.router.navigate(['/events']);
        } else if (res.type === 'MASTERCARD') {
          this.quest$.next();
        }
        this.showQuest = res.type === 'MASTERCARD';
      });
  }
}
