import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
})
export class BuyComponent {
  public env = environment;
  constructor(public global: GlobalService) {}
}
