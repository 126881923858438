import { Component, Input, OnInit } from '@angular/core';
import { ISectionSchedules } from '../../services/spot-events/types';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.less'],
})
export class SessionsComponent implements OnInit {
  @Input() sessions: ISectionSchedules[];

  constructor() {}

  ngOnInit() {}
}
