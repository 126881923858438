import { prop, path, pathOr, isNil, isEmpty } from 'ramda';
import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';
import { IInfo } from '../../types/IInfo';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../../services/storage.service';
import { ValidationService } from '../../services/validation.service';
import { FormService } from '../../services/form.service';
import { Observable } from 'rxjs';
import { InfoService } from '../../services/info.service';
import { MultilangFieldPipe } from '../../pipes/multilang/multilang_field.pipe';


@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.less'],
  providers: [MultilangFieldPipe]
})
export class OrderConfirmComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('gpayContainer', { static: false }) gpayContainer: ElementRef;

  garageHeader = {en: 'PURCHASE A GARAGE CARD', ru: 'ПОКУПКА КАРТЫ GARAGE'};
  garageType = {en: 'GARAGE card', ru: 'Карта GARAGE'};
  garageTerms = {en: 'terms and conditions of purchase', ru: 'условиями покупки'};

  garageEmpty = {en: 'Your cart is empty. Please return to the last menu', ru: 'Ваша корзина пуста. Вернитесь в предыдущее меню'};

  garageAction = {en: 'Remove', ru: 'Удалить'};

  isGarage = false;

  orderForm: FormGroup;
  formName = 'certOrderForm';
  prices = {};
  pricesArray = [];
  name = '';
  nameView = '';
  payments: any = [];
  currentLang = 'ru';
  langSubscribe: any;

  info: IInfo;
  order: any;
  currentPaymentMethod: any;
  serviceMoney = 0;
  priceTotal = 0;

  popup = false;

  applepayData: {
    paymentMethodData?: any;
    paymentDetails?: any;
    paymentOptions?: any;
    session?: PaymentRequest;
  };

  gpaySupported: boolean;
  gpayData: {
    paymentMethodData?: any;
    paymentDetails?: any;
    paymentOptions?: any;
    session?: PaymentRequest;
  };

  acsUrl?: string;
  acsMethod?: string;

  constructor(
    private transport: TransportService,
    public global: GlobalService,
    private router: Router,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    public formService: FormService,
    private renderer: Renderer2,
    private infoService: InfoService,
  private mLang: MultilangFieldPipe
  ) {
    this.orderForm = this.formBuilder.group({
      phone: ['', [Validators.required, ValidationService.phoneValidator]],
      email: ['', [Validators.required, ValidationService.emailValidator]],
    });
  }

  ngOnInit() {


    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      console.log('DARK MODE!')
    } else {
      console.log('Light MODE!')
    }

    // window.location.href = '/gift';

    const denominationPrices = JSON.parse(this.storage.getItem('gift_denomination') || '{}');
    this.orderForm.setValue({
      phone: '',
      email: '',
      ...this.formService.getFormValues(this.formName),
    });
    this.currentLang = this.translate.currentLang;
    this.langSubscribe = this.translate.onLangChange.subscribe(data => {
      this.currentLang = data.lang;
    });
    this.prices = JSON.parse(this.storage.getItem('gift_prices'));
    this.info = JSON.parse(this.storage.getItem('gift_info'));

    if (!this.info || !this.prices) {
      return;
    }
    this.orderForm.patchValue({ email: this.info.email });
    this.name = this.info.certificate.name;
    this.nameView = this.info.view.name;


    // console.log('цены', this.prices, this.pricesArray);


    for (const priceItem in this.prices) {
      if (this.prices[priceItem]) {
        this.pricesArray.push(priceItem);
      }
    }
    Object.keys(denominationPrices).map(price => {
      if (denominationPrices[price] > 0) {
        this.prices[price]
          ? (this.prices[price] += denominationPrices[price])
          : (this.prices[price] = denominationPrices[price]);
        this.pricesArray.push(price);
      }
    });
    this.pricesArray.map(price => {
      this.priceTotal += parseInt(price, 10) * this.prices[price];
    });
    this.global.showHideLoader.next(true);
    this.isGarage = this.global.settings.name.ru === 'Garage';
  }

  ngAfterViewInit() {



    this.transport
      .showcasePaymentMethod()
      .toPromise()
      .then((success) => {
        this.payments = success;

        const gpay_payment_method = success.find((m) => m && m.type === 'gpay');
        try {
          this.makeGpayPaymentRequest(gpay_payment_method);
          const pr = new PaymentRequest([this.gpayData.paymentMethodData], this.gpayData.paymentDetails, this.gpayData.paymentOptions);
          return pr.canMakePayment()
            .then((s) => {
              if ((this.gpaySupported = s) === true) {
                const paymentsClient: google.payments.api.PaymentsClient = new google.payments.api.PaymentsClient({
                  environment: this.gpayData.paymentMethodData.data.environment
                });
                const button = paymentsClient.createButton({
                  onClick: () => this.onGpayBtnClick(gpay_payment_method),
                  buttonType: 'long',
                  buttonColor: this.isGarage ? 'black' : 'white'
                });
                this.renderer.appendChild(this.gpayContainer.nativeElement, button);
              }
            })
            .catch(() => Promise.resolve(null));
        } catch (err) {
          console.error(err);
        }
      })
      .finally(() => this.global.showHideLoader.next(false));
  }

  ngOnDestroy() {
    this.langSubscribe.unsubscribe();
  }

  onSubmit() {
    if (this.orderForm.valid) {
      this.global.showHideConfirmPopup.next({
        id: 'order-cert-confirm',
        show: true,
        values: Object.values(this.orderForm.value),
      });

      // this.storage.setItem('info', null);
      // this.storage.setItem('gift_prices', null);
      // this.storage.removeItem('giftForm');
      // this.storage.removeItem('gift_prices');
      // console.log('this.info')
    }
  }

  onApplepayBtnClick(payment_method) {
    this.currentPaymentMethod = payment_method;
    const ext_provider_data = payment_method.ext_provider_data || {};

    const cart_data = this.cartData();
    cart_data['payment_method'] = payment_method._uuid;
    const total_cost = (cart_data.items || []).reduce(
      (acc, item) => acc + Number(item.count) * Number(item.price),
      0
    );

    this.applepayData = this.applepayData || {};
    this.applepayData.paymentMethodData = {
      data: Object.assign({}, prop('params', ext_provider_data)),
      supportedMethods: 'https://apple.com/apple-pay',
    };
    this.applepayData.paymentDetails = {
      total: {
        label: 'Lastick.ru',
        amount: {
          value: (total_cost * 0.01).toFixed(2),
          currency: path(['currency', 'alias'], ext_provider_data),
        },
      },
    };

    const { email, phone } = cart_data.client;
    this.applepayData.paymentOptions = {
      requestPayerEmail: isNil(email) || isEmpty(email),
      requestPayerPhone: isNil(phone) || isEmpty(phone),
    };

    try {
      console.log('try payment session', {
        paymentMethodData: [this.applepayData.paymentMethodData],
        paymentDetails: this.applepayData.paymentDetails,
        paymentOptions: this.applepayData.paymentOptions,
      });
      this.applepayData.session = new PaymentRequest(
        [this.applepayData.paymentMethodData],
        this.applepayData.paymentDetails,
        this.applepayData.paymentOptions
      );

      this.applepayData.session['onmerchantvalidation'] = event => {
        console.log('onmerchantvalidation', { event });
        const validationURL = event.validationURL;
        console.log(`[onmerchantvalidation] ${validationURL}`);

        this.transport
          .gvalidateMerchant(payment_method._uuid, { validationURL })
          .toPromise()
          .then(response => {
            console.log('validateMerchant complete', { response });
            event.complete(response);
          })
          .catch(e => {
            console.log('validateMerchant error', { error: e });
            this.applepayData.session.abort();
          });
      };

      console.log('try session show');
      this.applepayData.session
        .show()
        .then(response => {
          console.log('session.show', { response });
          if (!isNil(response.payerPhone) && !isEmpty(response.payerPhone)) {
            cart_data.client.phone = response.payerPhone;
          }
          if (!isNil(response.payerEmail) && !isEmpty(response.payerEmail)) {
            cart_data.client.email = response.payerEmail;
          }

          this.order = cart_data;
          this.order['payment_token'] = path(['details', 'token', 'paymentData'], response);

          this.global.showHideLoader.next(true);

          this.order['lang'] = this.currentLang;

          console.log('UPDATED', this.order);

          this.transport.newOrder(JSON.stringify(this.order)).subscribe(
            success => {
              response.complete('success');
              window.location.href = success.payment['payment_url'];
              this.global.showHideLoader.next(false);
            },
            error => {
              response.complete('fail');
              this.global.showHideLoader.next(false);
            }
          );
        })
        .catch(e => console.error('[session.show] REJECTED', e));
    } catch (err) {
      console.log('catch payment session', { error: err });
      try {
        if (this.applepayData.session) {
          this.applepayData.session.abort();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  onGpayBtnClick(payment_method) {
    this.currentPaymentMethod = payment_method;
    const cart_data = this.cartData();
    cart_data['payment_method'] = payment_method._uuid;

    this.makeGpayPaymentRequest(payment_method);


    console.log('try payment session');
    this.gpayData.session = new PaymentRequest(
      [ this.gpayData.paymentMethodData ],
      this.gpayData.paymentDetails,
      this.gpayData.paymentOptions
    );
    console.log('payment request', this.gpayData.session);

    try {
      console.log('try session show');
      this.gpayData.session
        .show()
        .then((response) => {
          console.log('session.show', { response });

          if (!isNil(response.payerPhone) && !isEmpty(response.payerPhone)) {
            cart_data.client.phone = response.payerPhone;
          }
          if (!isNil(response.payerEmail) && !isEmpty(response.payerEmail)) {
            cart_data.client.email = response.payerEmail;
          }

          this.order = cart_data;
          this.order['payment_token'] = JSON.parse(
            path(['details', 'paymentMethodData', 'tokenizationData', 'token'], response) || null
          );

          this.order['lang'] = this.currentLang;

          console.log('UPDATED', this.order);

          this.global.showHideLoader.next(true);
          this.transport.newOrder(JSON.stringify(this.order)).subscribe(
            (success) => {
              response.complete(success && success.payment ? 'success' : 'fail');

              const payment = success.payment;
              const redirectType: string = path(['payment_redirect', 'type'], payment);

              if (redirectType === 'acs') {
                this.acsUrl = path(['payment_redirect', 'acsUrl'], payment);
                this.acsMethod = path(['payment_redirect', 'acsMethod'], payment);

                const form = document.getElementById('acsForm') as HTMLFormElement;
                if (form) {
                  const acsData = path(['payment_redirect', 'acsParam'], payment);
                  for (const acsField of Object.keys(acsData)) {
                    form.innerHTML += `<input type="hidden" name="${acsField}" value="${acsData[acsField]}" />`;
                  }

                  // нужно узнать как правильно дождаться окончания изменений в dom
                  setTimeout(() => {
                    this.global.showHideLoader.next(false);
                    form.submit();
                  }, 1500);
                }
              } else {
                this.global.showHideLoader.next(false);
                const redirect_url = path(['payment_redirect', 'value'], payment) || payment.payment_url;
                window.location.href = redirect_url;
              }
            },
            (error) => {
              response.complete('fail');
              console.log(error);
              this.global.showHideLoader.next(false);
            }
          );

        })
        .catch((err) => {
          console.error('PAYMENT REQUEST REJECTED:', err);
        });

    } catch (err) {
      console.log('catch g-payment session', { error: err });
      try {
        if (this.gpayData.session) {
          this.gpayData.session.abort();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  onBtnClick(payment_method) {
    this.currentPaymentMethod = payment_method;
    this.onSubmit();
    if (!this.orderForm.valid) {
      this.formService.markFormGroupTouched(this.orderForm);
    }
  }

  buy() {

    if (!this.orderForm.valid) {
      this.formService.markFormGroupTouched(this.orderForm);
      return;
    }

    const payment_method = this.currentPaymentMethod || this.payments[0];
    console.log('BEFORE PAY', this.order);
    this.order = this.cartData();
    console.log('PAY', this.order);
    this.order['payment_method'] = path(['_uuid'], payment_method);

    this.order['lang'] = this.currentLang;

    console.log('UPDATED', this.order);

    this.global.showHideLoader.next(true);

    this.transport.newOrder(JSON.stringify(this.order)).subscribe(
      success => {
        // console.log('SUCC', success)
        window.location.href = success.payment['payment_url'];
        this.global.showHideLoader.next(false);
      },
      error => {
        this.global.showHideLoader.next(false);
      }
    );
    this.formService.saveFormValues(this.formName, this.orderForm.value);
  }

  cartData() {
    // this.storage.setItem('info', JSON.stringify(this.info));
    const cert_config = this.info.certificate._uuid;
    const cert_view = this.info.view._uuid;
    const itemsArray = Object.entries(this.prices)
      .filter(price => price[1] > 0)
      .map(([price, count]) => {
        return { cert_config, cert_view, count, price };
      });

    const email = this.info.email;
    const phone = ValidationService.trimPhone(this.orderForm.value.phone);
    const cart = {
      items: itemsArray,
      client: { email, phone },
      is_gift: this.info.is_gift || false,
      // 'payment_method': this.payments[0]['_uuid'],
    };
    if (this.info.addressee_email) {
      cart['addressee'] = {
        email: this.info.addressee_email,
        message: this.info.message,
        name: this.info.to,
      };
      cart.client['name'] = this.info.from;
    }
    return cart;
  }

  focusForm() {
    (Object.values(this.orderForm.controls)[0] as any).nativeElement.focus();
  }

  makeGpayPaymentRequest(payment_method) {
    payment_method = payment_method || this.currentPaymentMethod;

    this.gpayData = this.gpayData || {};

    const cart_data = this.cartData();
    const ext_provider_data = payment_method.ext_provider_data || {};
    const settings = ext_provider_data.params || {};

    const currencyCode: string = path(['currency', 'alias'], ext_provider_data) || 'RUR';
    const total_cost = (cart_data.items || []).reduce(
      (acc, item) => acc + (Number(item.count) * Number(item.price)),
      0
    );

    const googlePaymentsConfiguration = {
      environment: 'TEST',
      apiVersion: 2,
      apiVersionMinor: 0,
      ...settings.options,
      merchantInfo: {
        // A merchant ID is available after approval by Google.
        merchantId: settings.merchant.id || undefined,
        merchantName: settings.merchant.name,
      },
      transactionInfo: {
        countryCode: 'RU',
        totalPriceStatus: 'FINAL',
        totalPrice: (total_cost * 0.01).toFixed(2),
        currencyCode: currencyCode,
      },
    };

    this.gpayData.paymentMethodData = {
      supportedMethods: 'https://google.com/pay',
      data: googlePaymentsConfiguration,
    };

    this.gpayData.paymentDetails = {
      total: {
        label: 'LASTICK.RU',
        amount: {
          currency: currencyCode,
          value: (total_cost * 0.01).toFixed(2),
        },
      }
    };

    const { email, phone } = cart_data.client;
    this.gpayData.paymentOptions = {
      requestPayerEmail: isNil(email) || isEmpty(email),
      requestPayerPhone: isNil(phone) || isEmpty(phone),
    };

    return this.gpayData;
  }

  removePosition(price: any): void {
    // console.log('price', price)
    const idx = this.pricesArray.findIndex(a => a === price);

    if (idx > -1) {
      this.pricesArray.splice(idx, 1);
      this.prices[+price]--;
      this.priceTotal -= price;

      this.storage.setItem('gift_prices', JSON.stringify(this.prices));


      // console.log('price', this.prices, this.pricesArray)
    }

    if (this.pricesArray.length === 0) {
      this.infoService.showInfo(this.mLang.transform(this.garageEmpty));
    }
  }
}
