import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { IPriceValue, TCurrency } from '../../types/Entities';
import { CartService } from '../../services/cart.service';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  constructor(public translate: TranslateService, public global: GlobalService) {}

  /* Возвращает стоимость -- 100.00 */
  transform(price: number, currency?: TCurrency): string {
    const currencies = this.global.prices_currency;
    const result = price / 100;
    // if (result === 0) {
    //   // console.log('zero return', `0 ${currencies[currency] || currencies[this.global.default_currency]}`)
    //   return `0 ${currencies[currency] || currencies[this.global.default_currency]}`;
    // }
    // console.log('normal return', `${result} ${currencies[currency] || currencies[this.global.default_currency]}`)
    return `${result} ${currencies[currency] || currencies[this.global.default_currency]}`;
  }
}
