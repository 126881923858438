import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';

@Pipe({
  name: 'duration',
  pure: false,
})
export class DurationPipe implements PipeTransform {
  lang: string;

  constructor(public translate: TranslateService, public global: GlobalService) {
    this.lang = this.translate.currentLang;

    this.translate.onLangChange.subscribe(data => {
      this.lang = data.lang;
    });
  }

  /* Возвращает длительность --1 ЧАС 45 МИНУТ */
  transform(dt: any): string {
    const { hours, minutes } = this.global;
    const g = this.global;
    const _h = hours[this.lang] || hours[g.defaultLang] || hours[g.initDefaultLang];
    const _m = minutes[this.lang] || minutes[g.defaultLang] || minutes[g.initDefaultLang];
    const h = dt.hours ? `${dt.hours} ${_h.name}` : '';
    const m = dt.minutes ? `${dt.minutes} ${_m.name}` : '';
    // const t = `${h.length ? `${h} ` : ''}${m}`;
    // const result = (h.length === 0 || m.length === 0) ? t.replace(/\s/g, '') : t;

    return `${h.length ? `${h} ` : ''}${m}`;
  }
}
