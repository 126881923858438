import { Pipe, PipeTransform } from '@angular/core';
import { IPaymentMethod } from '../../types/Entities';
import { prop, path, isEmpty, isNil } from 'ramda';
import { DeviceDetectorService } from 'ngx-device-detector';

@Pipe({
  name: 'payment_method_filter',
  pure: false,
})
export class PaymentMethodFilterPipe implements PipeTransform {
  constructor(private device: DeviceDetectorService) {
  }
  transform(
    payment_methods: IPaymentMethod[],
    type: 'common' | 'applepay' | 'gift' | 'gpay'
  ): IPaymentMethod[] {
    // console.log('PM PIPE', payment_methods, type);

    let filtered_payment_methods = (payment_methods || []).filter((el: IPaymentMethod) => el && el.type === type);

    if (filtered_payment_methods.length > 0) {
      if (type === 'applepay') {
        filtered_payment_methods = filtered_payment_methods.filter((x) => !isNil(x.ext_provider_data) && !isEmpty(x.ext_provider_data));
        if (!(
            window['ApplePaySession'] &&
            window['ApplePaySession'].canMakePayments &&
            window['PaymentRequest']
            // && !this.device.isDesktop()
        )) {
          filtered_payment_methods = [];
        }
      }

      if (type === 'gpay') {
          filtered_payment_methods = filtered_payment_methods.filter((x) => !isNil(x.ext_provider_data) && !isEmpty(x.ext_provider_data));
      }
    }

    return filtered_payment_methods;
  }
}
