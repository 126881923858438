import { Component, Input, OnInit } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeAnimationHide, fadeAnimationShow } from '../../animations';
import { IMultilangString } from '../../types/Entities';
import { AlertsMiniService } from '../../services/alerts-mini.service';

export interface IAlertBtn {
  text?: string;
  url: string;
  classes?: string;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.less'],
  animations: [
    trigger('showTrigger', [
      transition(':enter', [
        useAnimation(fadeAnimationShow, { params: { timings: '400ms ease-in-out' } }),
      ]),
      transition(':leave', [
        useAnimation(fadeAnimationHide, { params: { timings: '400ms ease-in-out' } }),
      ]),
    ]),
  ],
})
export class AlertComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() btn: IAlertBtn;
  @Input() pos = '1';
  @Input() sz = '1';
  @Input() alerts: any[] = [];
  @Input() special = false;
  @Input() show;

  private position = 0;
  private labelNext: IMultilangString = {ru: 'Далее', en: 'Next'};
  private label: IMultilangString = {ru: '', en: ''};

  constructor(
    private alertsMini: AlertsMiniService
  ) {}

  ngOnInit() {
    this.label = this.position + 1 >= this.alerts.length ? {ru: 'OK', en: 'OK'} : {ru: 'Далее', en: 'Next'};
  }

  next(): void {
    console.log(this.position + 1 > this.alerts.length, this.position + 1, this.alerts.length);
    if (this.position + 1 >= this.alerts.length) {
      this.alertsMini.switchAlert();
      return;
    }
    ++this.position;
    this.label = this.position + 1 === this.alerts.length ? {ru: 'OK', en: 'OK'} : {ru: 'Далее', en: 'Next'};
  }

  getTitle(): IMultilangString {
    return this.position < this.alerts.length ? this.alerts[this.position].title : {ru: '', en: ''};
  }
  hasTitle(): boolean {
    return this.position < this.alerts.length ? (this.alerts[this.position].title.ru.trim().length > 0) : false;
  }
  getBody(): IMultilangString {
    return this.position < this.alerts.length ? this.alerts[this.position].text : {ru: '', en: ''};
  }
}
