import { Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  ICartItem,
  IPromocode,
  ICartData,
  IPaymentMethod,
  IPriceValue,
  IProductItem,
  IContacts,
  ICartCertificate,
  IPriceCategory,
  TCurrency,
} from '../types/Entities';
import { TransportService } from './transport.service';
import { Observable, Subject, of, ReplaySubject, pipe, throwError } from 'rxjs';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { isNil } from 'ramda';
import { LoggerService } from './logger.service';
import { InfoService } from './info.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { GlobalService } from './global.service';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';
import { IEvent } from '../types/IEvent';
import { ISchedule } from '../types/ISchedule';
import * as R from 'ramda';

export interface ICartScheduleStorageItem {
  Event?: IEvent;
  Place?: any;
  PriceCategory?: IPriceCategory;
  PriceValue?: IPriceValue;
  PriceValues?: IPriceValue[];
  Schedule?: ISchedule;
  items?: ICartItem[];
}


export interface SelectedPriceValues {
  [ticket_uuid: string]: IPriceValue;
}

@Injectable()
export class CartService implements OnInit {

  public srcServerCart;
  public cart_schedules_storage: ICartScheduleStorageItem[] = [];
  public event_total_cost = 0;

  private cart_items_storage: {
    [product_item_uuid_underscore_price_value_uuid: string]: ICartItem;
  } = {};

  private cart_items_order: string[] = [];

  public items: ICartItem;
  public certificate: ICartCertificate;
  public currCertNominal = 0;
  public promocodes: IPromocode[];
  public payment_method: IPaymentMethod;
  public payment_methods: IPaymentMethod[];
  public prev_total_cost: number;
  public total_cost: number;
  public total_nominal_cost: number;
  public total_privileges_amount = 0;
  public total_items = 0;
  public promocode_discounts: { [key: string]: { code: string; total: number } };
  public promocode_list: { code: string; total: number }[];
  public service_fee: number;
  public cart_currency: TCurrency;
  public priceValuesData: {[uuid: string]: IPriceValue};
  public priceValues: {[ticket_uuid: string]: IPriceValue[]} = {};
  public selectedPriceValues: SelectedPriceValues = {};
  private serverCart: any = {};
  private currentUrl: string;
  public contacts = { email: '', phone: '' };
  private initClearCart = false;
  private eventMapPage: RegExp = /\/events\/.+\/schedules\/.+/;
  private eventTablePage: RegExp = /\/events\/.+/;
  private orderActivatePage: RegExp = /\/order\/activate/;
  public restrictionIsValid = true;
  // private cartOutputSource = new ReplaySubject<ICartData>(0);
  private cartOutputSource = new Subject<ICartData>();
  public cartOutput$ = this.cartOutputSource.asObservable();

  private cartRecalcSource = new Subject<void>();
  private cartRecalc$ = this.cartRecalcSource.asObservable().pipe(debounceTime(100));
  public cartUpdateSchedules$ = new Subject();
  private removeFromCartSource = new Subject<ICartItem>();
  public removeFromCart$ = this.removeFromCartSource.asObservable();
  public cartSync$ = new ReplaySubject();
  private cartReloading = false;
  public syncing = false;
  public readonly timeToDropCart = 1000 * 60 * 15;

  public discountValue = 0;
  public nominalCost = 0;

  constructor(
    private transport: TransportService,
    private translate: TranslateService,
    private logger: LoggerService,
    private info: InfoService,
    private route: ActivatedRoute,
    private router: Router,
    public global: GlobalService,
    private storage: StorageService
  ) {
    const priceValuesData = this.storage.getItem('priceValuesData') || 'null';
    const priceValues = this.storage.getItem('priceValues') || '{}';
    const selectedPriceValues = this.storage.getItem('selectedPriceValues') || '{}';
    this.priceValues = JSON.parse(priceValues);
    this.priceValuesData = JSON.parse(priceValuesData);
    this.selectedPriceValues = JSON.parse(selectedPriceValues);

    this.cartRecalc$.subscribe(() => {
      this.calculate();
      this.emitCartChanges();
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    this.cartOutputSource.subscribe(() => {
      this.saveToLocalStorage();
    });

  }

  ngOnInit() {}

  public get cart_items(): ICartItem[] {
    return this.cart_items_order.map(el => this.cart_items_storage[el]);
  }

  public findCartItem(item: IProductItem, price: IPriceValue) {
    console.log('key', this.getStorageKey(item, price));
    return this.cart_items_storage[this.getStorageKey(item, price)];
  }

  public getCartItem(productItemUuid: string): ICartItem {
    return this.cart_items.find(item => item.ProductItem.uuid === productItemUuid);
  }

  public getCartItemsOrderFromStorage(): Array<string> {
    const order = this.storage.getItem('cart_items_order');
    return order ? JSON.parse(order) : [];
  }

  public dropCart(sync = false): Promise<any> | any {
    this.clearLocal();
    this.calculate();
    this.emitCartChanges();

    if (sync) {
      return this.transport.clearCart().toPromise();
    }
  }

  public clearLocal(storage = true) {
    this.cart_items_storage = {};
    this.cart_items_order = [];
    this.cart_schedules_storage = [];
    this.priceValuesData = null;
    this.priceValues = {};
    this.selectedPriceValues = {};

    if (storage) {
      this.saveToLocalStorage();
    }
  }

  public clearStorage() {
    this.storage.removeItem('cart_items_storage');
    this.storage.removeItem('cart_items_order');
    this.storage.removeItem('cart_schedules_storage');
    this.storage.removeItem('priceValuesData');
    this.storage.removeItem('priceValues');
    this.storage.removeItem('selectedPriceValues');
  }

  private getStorageKey(item: IProductItem, price: IPriceValue): string {
    //${price.PriceCategory ? `_${price.PriceCategory.uuid}` : ''}
    return `${item.uuid}_${price.uuid}`;
    // return `${item.uuid}`;
  }

  private addPriceValues(item: IProductItem, prices: {[uuid: string]: IPriceValue}): void {
    this.priceValuesData = {...(this.priceValuesData || {}), ...prices};
    this.priceValues[item.uuid] = [];

    item.price_values.forEach(uuid => {
      const priceValue: IPriceValue = this.priceValuesData[uuid];
      if (priceValue) {
        const exist: IPriceValue[] = this.priceValues[item.uuid];
        this.priceValues[item.uuid] = [...(exist || []), priceValue];
      }
    });
  }

  private removePriceValues(item: IProductItem): void {
    delete this.priceValues[item.uuid];

    if (this.selectedPriceValues[item.uuid]) {
      delete this.selectedPriceValues[item.uuid];
    }
    this.storage.setItem('selectedPriceValues', JSON.stringify(this.selectedPriceValues));
    this.storage.setItem('priceValues', JSON.stringify(this.priceValues));
  }

  public changItemPriceValue(item: IProductItem, price: IPriceValue) {
    // console.log('changItemPriceValue', price.amount);
    const cartItem: ICartItem = this.getCartItem(item.uuid);
    const storageKey: string = this.getStorageKey(item, cartItem.PriceValue);
    const newStorageKey: string = this.getStorageKey(item, price);
    const innerItem: ICartItem = this.cart_items_storage[storageKey];

    if (innerItem) {
      delete this.cart_items_storage[storageKey];

      this.cart_items_storage[newStorageKey] = {
        ...innerItem,
        // PriceValue: {...price}
      };
      this.selectedPriceValues[item.uuid] = {...price};

      this.cart_items_order = this.cart_items_order.map(k => {
        if (k === storageKey) {
          return k.replace(storageKey, newStorageKey);
        }
        return k;
      });
      // this.calculate();
      this.saveToLocalStorage();
      this.syncCart().then((data) => {
        // console.log('syncCartsyncCart', data);
      });
    }
    //       this.cart_items_storage[key] = cartItem;
    // this.updateSchedulesStorage(cartItem, 'add');
  }

  public removeItem(item: IProductItem, price?: IPriceValue) {
    this.prev_total_cost = this.total_cost;

    console.log('REMOVING ITEM', item, price)

    if (isNil(price)) {

      const existsKeys = Object.keys(this.cart_items_storage).filter(
        el => el.split('_')[0] === item.uuid
      );

      existsKeys.forEach(el => {
        const citem = this.cart_items_storage[el];
        // this.removeFromCartSource.next(citem);
        this.updateSchedulesStorage(citem, 'remove');
        this.removeFromCartSource.next(citem);
        delete this.cart_items_storage[el];
      });

      existsKeys.forEach(k => {
        this.cart_items_order = this.cart_items_order.filter(el => el !== k);
      });

    } else {

      console.log('Looking for cart items', this.cart_items_storage)

      const key = this.getStorageKey(item, price);
      const cartItem = this.cart_items_storage[key];

      console.log('DELETE KEY', key, cartItem)

      if (cartItem) {
        cartItem.quantity -= 1;
        cartItem.total_cost = cartItem.quantity * cartItem.PriceValue.amount;

        if (cartItem.quantity === 0) {
          // this.removeFromCartSource.next(cartItem);
          delete this.cart_items_storage[key];
          this.cart_items_order = this.cart_items_order.filter(el => el !== key);
        }
        this.updateSchedulesStorage(cartItem, 'remove');
        this.removeFromCartSource.next(cartItem);
      }
    }
    this.removePriceValues(item);
    this.cartRecalcSource.next();
  }

  public addItem(item: IProductItem, price: IPriceValue, prices?: {[uuid: string]: IPriceValue}) {
    const settings = this.global.settings.widget_settings;
    const { limits } = settings;

    if (
      settings &&
      limits &&
      limits.tickets_in_order &&
      this.total_items === limits.tickets_in_order
    ) {
      this.showLimitMessage();
      return;
    }

    const key = this.getStorageKey(item, price);

    console.log('KEYS', key, 'ANOTHER KEY', item.uuid)

    const { schedule } = item;

    let cartItem: ICartItem | any = {
      meta: {},
    };
    cartItem.meta.Schedule = schedule;

    // console.log('COMPARE SS', this.cart_schedules_storage, 'IS', this.cart_items_storage)

    if (this.cart_items_storage.hasOwnProperty(key)) {

      console.log('ТАКОЙ БИЛЕТ ЕСТЬ')

      cartItem = this.cart_items_storage[key];
      cartItem.quantity += 1;
      cartItem.total_cost = cartItem.quantity * cartItem.PriceValue.amount;
      cartItem.schedule = schedule;
    } else {
      console.log('ЭТО НОВЫЙ БИЛЕТ', this.cart_items_storage)

      cartItem = {
        PriceValue: price,
        ProductItem: item,
        PriceModifiers: undefined,
        quantity: 1,
        uuid: item.uuid,
        local_uuid: key,
        total_cost: price.amount,
        service_fee: 0,
        is_expired: false,
        is_reserved: true,
        schedule,
        meta: {}
      };
      this.cart_items_order.push(key);


      this.cart_items_storage[key] = cartItem;

      console.log('КОРЗИНКА БИЛЕТОВ', this.cart_items_storage)
    }
    if (prices) {
      this.addPriceValues(item, prices);
    }
    this.updateSchedulesStorage(cartItem, 'add');
    this.cartRecalcSource.next();
  }

  public addItemv2(item: IProductItem, price: IPriceValue, cItem: any,  prices?: {[uuid: string]: IPriceValue}) {
    const settings = this.global.settings.widget_settings;
    const { limits } = settings;

    // console.log('HEREs out ITEM', item, price, cItem)
    //
    if ( settings && limits && limits.tickets_in_order && this.total_items === limits.tickets_in_order) {
      this.showLimitMessage();
      return;
    }

    const key = this.getStorageKey(item, price);

    // console.log('HEREs out KEY', key, this.cart_items_storage)

    const { schedule } = item;

    let cartItem: ICartItem | any = {
      meta: {},
    };
    cartItem.meta.Schedule = schedule;

    if (this.cart_items_storage.hasOwnProperty(key)) {


      cartItem = this.cart_items_storage[key];

      // console.log('WATCH ITEM', this.cart_items_storage[key], 'TOTAL', cartItem.total_cost)


      if (!this.cart_items_storage[key].nominal_price || this.cart_items_storage[key].nominal_price === 0) {
        this.cart_items_storage[key].nominal_price = cartItem.PriceValue.amount;
      }

      this.cart_items_storage[key].quantity += 1;
      cartItem.total_cost += cartItem.PriceValue.amount;

      if (!this.cart_items_storage[key] || this.cart_items_storage[key].price === 0) {
        this.cart_items_storage[key] = cartItem.total_cost;
      }

      cartItem.schedule = schedule;
    } else {
      // console.log('NO ITEM')
      cartItem = {
        PriceValue: price,
        ProductItem: item,
        PriceModifiers: undefined,
        quantity: 1,
        uuid: '',
        local_uuid: key,
        total_cost: price.amount,
        service_fee: 0,
        is_expired: false,
        is_reserved: true,
        schedule,
        meta: {}
      };
      this.cart_items_order.push(key);
      this.cart_items_storage[key] = cartItem;
    }
    // console.log('WHAT IS PRICES', prices)

    this.addPriceValues(item, prices);
    // if (prices) {
    //   this.addPriceValues(item, prices);
    // }
    this.updateSchedulesStorage(cartItem, 'add');
    this.cartRecalcSource.next();
  }

  public showLimitMessage() {
    const settings = this.global.settings.widget_settings;
    console.log('GLOBAL', this.global.cart, this.global.settings)
    const { limits } = settings;

    this.translate
      .get('ERRORS')
      .toPromise()
      .then(errors => {
        this.info.showError(
          errors[settings.card_auth ? 'TICKET_LIMIT_FORMALIZE' : 'TICKET_LIMIT']({
            value: limits.tickets_in_order,
          })
        );
      });
  }



  removePosition(schedule, item: ICartItem) {
    if (this.syncing) return;

    const key = this.getStorageKey(item.ProductItem, item.PriceValue);
    const findIndex = schedule.items.findIndex(_item => {
      return this.getStorageKey(_item.ProductItem, _item.PriceValue) === key;
    });
    schedule.items.splice(findIndex, 1);
    this.cart_items_order.splice(
      this.cart_items_order.findIndex(_id => key === _id),
      1
    );
    delete this.cart_items_storage[key];
    this.removePriceValues(item.ProductItem);
    this.cartRecalcSource.next();
  }

  public removeSchedule(schedule, item) {
    const key = this.getStorageKey(item.ProductItem, item.PriceValue);
    const findIndex = this.cart_schedules_storage.findIndex(_s => {
      return _s.Schedule.uuid === schedule.Schedule.uuid;
    });
    this.cart_schedules_storage.splice(findIndex, 1);
    // this.cart_items_order.splice(this.cart_items_order.findIndex((_id) => item.uuid === _id), 1);
    delete this.cart_items_storage[key];
    this.cartRecalcSource.next();
  }

  getErrorItems(schedule_uuid?: string) {
    const filterFn = item => {
      return this.isErrorItem(item);
    };

    if (schedule_uuid) {
      const schedule = this.cart_schedules_storage.find(s => {
        return s.Schedule.uuid === schedule_uuid;
      });

      if (!schedule) {
        return [];
      }
      return schedule.items.filter(filterFn);
    }
    return this.cart_items.filter(filterFn);
  }

  isErrorItem(item) {
    return item.is_expired || !item.is_reserved;
  }

  // private clearCartOnPage() {
  //   const url = this.currentUrl;
  //   const isCartPage = url.match(this.eventMapPage) || url.match(this.orderActivatePage) || url.match(this.eventTablePage);
  //   // || url.match(this.eventTablePage)
  //
  //   if ((this.cart_items && this.cart_items.length) && !isCartPage && !this.initClearCart) {
  //     this.initClearCart = true;
  //     this.transport.clearCart().toPromise().then((data) => {
  //       // this.reloadCart();
  //       // this.cart_items_order = [];
  //       // this.cart_items_storage = {};
  //       this.dropCart();
  //       this.initClearCart = false;
  //       // this.emitCartChanges();
  //     });
  //   }
  // }

  getFromStorage(calc = true) {
    const itemsStorage = this.storage.getItem('cart_items_storage');
    const schedulesStorage = this.storage.getItem('cart_schedules_storage');
    const orderStorage = this.storage.getItem('cart_items_order');

    this.cart_items_order = orderStorage ? JSON.parse(orderStorage) : [];
    this.cart_items_storage = itemsStorage ? JSON.parse(itemsStorage) : {};
    this.cart_schedules_storage = schedulesStorage ? JSON.parse(schedulesStorage) : [];

    if (calc) {
      this.calculate();
    }
  }

  public getCartFromStorage(): {order: any, storage: any, schedules: any} {
    this.getFromStorage();
    return {
      order: this.cart_items_order,
      storage: this.cart_items_storage,
      schedules: this.cart_schedules_storage
    };
  }

  public syncCart(): Promise<{items: ICartItem[]} | any> {
    this.getFromStorage();

    let cartItems = [];

    const cartItemsKeys = Object.keys(this.cart_items_storage);

    if (cartItemsKeys.length) {
      cartItemsKeys.forEach(k => {
        const innerItem = this.cart_items_storage[k];
        cartItems.push({
          item_uuid: innerItem.ProductItem.uuid,
          price_uuid:
            (this.selectedPriceValues[innerItem.ProductItem.uuid] || {} as IPriceValue).uuid ||
              innerItem.PriceValue.uuid,
          quantity: innerItem.quantity,
        });
      });
    }

    this.syncing = true;

    return this.transport
      .syncCart(cartItems, this.translate.currentLang)
      .toPromise()
      .then(
        e => {
          console.log('HERE s MY E', e)
          this.srcServerCart = e;
          const serverCart = this.serverCart;
          serverCart.cart_items = e.items;
          serverCart.promocodes = e.promocodes;
          serverCart.certificate = e.certificate;
          serverCart.total_cost = e.total_cost;
          serverCart.total_items = this.cart_items.length;
          serverCart.payment_method = e.payment_method;
          serverCart.service_fee = e.service_fee;
          this.certificate = e.certificate;
          this.total_cost = e.total_cost;

          this.compareBySchedules();

          this.restrictionIsValid = true;

          this.syncing = false;
          this.cartSync$.next();
          return e;
        },
        () => {
          this.syncing = false;
        }
      );
  }

  /* public setCartPaymentMethod(method: any) { //deprecated
     return this.transport.setCartPaymentMethod(method)
       .toPromise()
       .then((e) => { this.payment_method = e; return Promise.resolve(e); });
   } */

  private calculate() {
    const cert = this.certificate;
    const certValue = cert ? parseInt(cert.PriceModifier.value, 10) : 0;

    this.total_items = 0;
    this.total_cost = 0;
    this.total_nominal_cost = 0;
    this.service_fee = 0;
    this.total_privileges_amount = 0;
    this.nominalCost = 0;
    this.promocode_discounts = {};


    // if (!Object.keys(this.cart_items_storage).length) return;

    Object.keys(this.cart_items_storage).forEach(key => {

      const item: ICartItem = this.cart_items_storage[key];

      const {ProductItem, PriceValue} = item;
      const privilegesPrice: IPriceValue = this.selectedPriceValues[ProductItem.uuid];
      const basePrice: number = this.getCartItemBasePrice(item);

      this.total_items += item.quantity;

      // this.total_cost += (privilegesPrice || {} as IPriceValue).amount || item.total_cost;

      // ================КОЛХОЗ===================
      if (!item.nominal_price) {
        item.nominal_price = 0;
      }

      // =========================================

      // console.log('total is', this.total_cost, 'addition', item.nominal_price, item.service_fee, item.quantity)
      // console.log('was sevice fee', this.service_fee, item.service_fee)
      this.service_fee += (item.service_fee * item.quantity);

      // console.log('was nominal cost', this.total_nominal_cost, basePrice)
      this.total_nominal_cost += basePrice * item.quantity;


      if (privilegesPrice && !privilegesPrice.is_base_price) {
        this.total_privileges_amount += basePrice - privilegesPrice.amount;
      }

      if (!item.nominal_price) {
        item.nominal_price = item.PriceValue.amount;
      }

      this.nominalCost += item.nominal_price * item.quantity;

      if ( item.applied_promocodes && item.applied_promocodes.length > 0 && this.serverCart.promocodes ) {

        this.promocode_discounts[item.applied_promocodes[0]] = {
          code: this.serverCart.promocodes.find(x => x.uuid === item.applied_promocodes[0]).code,
          total:
            R.pathOr(0, [item.applied_promocodes[0], 'total'], this.promocode_discounts) +
            (item.nominal_price - item.price) * item.quantity,
        };
        // item.nominal_price -= +this.promocode_discounts[item.applied_promocodes[0]];
      }

      // console.log('was total cost', this.total_cost, '', item, item.price)
      const value = item.price ? (item.price + item.service_fee) : item.nominal_price;
      this.total_cost += (value * item.quantity) ;

    });

    this.discountValue = this.total_nominal_cost + this.service_fee - this.total_cost;




    this.promocode_list = R.values(this.promocode_discounts).sort((a, b) =>
      a.code.localeCompare(b.code)
    );


    console.log('PROMO', this.promocode_list)
    // ЭТОТ БЛОК ОТВЕЧАЕТ ЗА ВЫЧЕТ СКИДОК ПО ПРОМОКОДУ
    // if (this.promocode_list.length > 0 && this.promocode_list[0].total) {
    //   this.discountValue -= this.promocode_list[0].total;
    // }

    // this.currCertNominal = certValue >= this.total_cost ? certValue - this.total_cost : 0;
    this.currCertNominal = certValue >= this.total_cost ? certValue - this.total_cost : 0;

  }

  private loadServerCart(): Promise<any> {
    return this.transport
      .getCart()
      .toPromise()
      .then((e: any) => {
        console.log('MY E', e)
        this.srcServerCart = e;
        const serverCart = this.serverCart;
        serverCart.cart_items = e.items;
        serverCart.promocodes = e.promocodes;
        serverCart.certificate = e.certificate;
        serverCart.total_cost = e.total_cost;
        serverCart.total_items = this.cart_items.length;
        serverCart.payment_method = e.payment_method;
        serverCart.service_fee = e.service_fee;
        this.certificate = e.certificate;
        this.cart_currency = e.currency;
        this.logger.l('serverCart', e);
        return serverCart;
      });
  }

  public reloadCart() {
    this.loadServerCart().then(e => {
      this.getFromStorage();
      this.getAllowedPaymentMethods();
    });
  }

  public moveToPay(url) {
    document.location.href = url;
  }

  private showPromocodeError() {
    this.translate
      .get('ERRORS')
      .toPromise()
      .then(errors => {
        this.info.showError(errors['PROMOCODE_UNEXPECTED']());
      });
  }

  public addPromocode(promo: string) {
    return this.transport.applyPromocode(promo).pipe(
      tap(validationResult => {
        if (validationResult.status === 'OK') {
          this.syncCart();
        } else {
          this.showPromocodeError();
        }
      }),
      pipe(
        catchError(err => {
          // this.showPromocodeError();
          return throwError(err);
        })
      )
    );
  }
  public removePromocode(code: string): void {
    this.transport.removePromocode(code).subscribe(() => this.syncCart());
  }

  public emitCartChanges() {
    this.cartOutputSource.next({
      items: this.cart_items_order.map(el => this.cart_items_storage[el]),
      certificate: this.certificate,
      total_cost: this.total_cost,
      total_items: this.total_items,
      payment_method: this.payment_method,
      service_fee: this.service_fee,
    });
  }

  public getAllowedPaymentMethods() {
    if (this.certificate && !this.certificate['allow_overpayment']) {
      return of([]);
    }

    this.transport
      .getCartPaymentMethod()
      .toPromise()
      .then(methods => {
        this.payment_methods = methods;
        // deprecated
        /* if (methods && methods.length && !this.payment_method) {
         this.setCartPaymentMethod({'payment_method': this.payment_methods[0].uuid});
       } */
      });
  }

  public checkout(data: IContacts): Promise<any> {
    this.logger.w('checkout -->');
    this.logger.l(data);
    return new Promise((resolve, reject) => {
      if (this.certificate) {
        if (this.total_cost > parseInt(this.certificate.PriceModifier.value, 10)) {
          this.translate
            .get('ERRORS')
            .toPromise()
            .then(err => {
              this.info.showError(err['GIFT_INSUFFICIENT_FUNDS']());
            });
          reject();
          return;
        }
      }
      const errors = this.getErrorItems();
      if (errors && errors.length) {
        this.translate
          .get('ERRORS')
          .toPromise()
          .then(err => {
            this.info.showError(err['ORDER_HAS_SOME_POSITIONS_WITH_BAD_RESERVE']());
          });
        reject();
        return;
      }
      return this.transport
        .newCartOrder({ ...data, lang: this.translate.currentLang })
        .toPromise()
        .then(success => {
          this.logger.l('newCartOrder -->');
          this.logger.l(success);
          // this.cartCheckoutSource.next(success);
          // this.dropCart();
          // this.clearStorage();
          resolve(success);
          //this.moveToPay(success.payment.redirect.value);
        })
        .catch(error => {
          this.logger.w(error);
          return reject(error);
        });
    });
  }

  public getCartPaymentMethod(): Observable<any> {
    return this.transport.getCartPaymentMethod();
  }

  private saveToLocalStorage() {
    this.storage.setItem('cart_items_storage', JSON.stringify(this.cart_items_storage));
    this.storage.setItem('cart_items_order', JSON.stringify(this.cart_items_order));
    this.storage.setItem('cart_schedules_storage', JSON.stringify(this.cart_schedules_storage));
    this.storage.setItem('priceValuesData', JSON.stringify(this.priceValuesData));
    this.storage.setItem('priceValues', JSON.stringify(this.priceValues));
    this.storage.setItem('selectedPriceValues', JSON.stringify(this.selectedPriceValues));
  }

  public updateSchedulesStorage(updatedItem: ICartItem, method: 'add' | 'remove') {
    const storage = this.cart_schedules_storage;
    const schedule = updatedItem.ProductItem.schedule || updatedItem.meta.Schedule;
    const Event = schedule.Event || updatedItem.meta.Event;
    const updatedItemUUID = this.getStorageKey(updatedItem.ProductItem, updatedItem.PriceValue);

    const updatedEvent = storage.find(_s => {
      return _s.Schedule.uuid === schedule.uuid;
    });

    if (!updatedEvent) {
      storage.unshift({
        // NearestSchedule: Event.NearestSchedule,
        Schedule: schedule,
        Event,
        items: [updatedItem],
      });
      return;
    }
    const _updatedItemIndex = updatedEvent.items.findIndex(
      _item => _item.local_uuid === updatedItemUUID
    );

    switch (method) {
      case 'add':
        if (!schedule.has_geometry) {
          if (_updatedItemIndex > -1) {
            updatedEvent.items[_updatedItemIndex] = updatedItem;
          } else {
            updatedEvent.items.unshift(updatedItem);
          }
        } else {
          updatedEvent.items.unshift(updatedItem);
        }
        break;
      case 'remove':
        // const findIndex = updatedEvent.items.findIndex((_item) => {
        //   return _item.local_uuid === updatedItem.local_uuid;
        // });
        if (schedule.has_geometry || updatedItem.quantity === 0) {
          updatedEvent.items.splice(_updatedItemIndex, 1);
        } else {
          updatedEvent.items[_updatedItemIndex] = updatedItem;
        }
        if (updatedEvent.items.length === 0) {
          this.removeSchedule({ Schedule: schedule }, updatedItem);
        }
        break;
    }
  }

  private compareBySchedules() {
    const schedules = [];

    this.srcServerCart.items.map(item => {
      const { Event, PriceCategory, Schedule, Place, PriceValues } = item.meta;
      const { PriceValue } = item;
      PriceValue.PriceCategory = PriceValue.PriceCategory || PriceCategory;

      const key = this.getStorageKey(item.ProductItem, PriceValue);
      let cartItem = item;
      item.local_uuid = key;
      item.schedule = Schedule;

      const _schedule = schedules.find(t => {
        return t.Schedule.uuid === Schedule.uuid;
      });
      if (_schedule) {
        if (_schedule.Schedule.has_geometry) {
          _schedule.items.unshift(item);
        } else {
          const ticketIndex = _schedule.items.findIndex(_item => {
            return key === this.getStorageKey(_item.ProductItem, _item.PriceValue);
          });
          if (ticketIndex > -1) {
            const ticket = _schedule.items[ticketIndex];
            ticket.quantity += 1;
            ticket.total_cost = ticket.quantity * (ticket.price || ticket.PriceValue.amount);
            cartItem = ticket;
          } else {
            _schedule.items.unshift(item);
          }
        }
      } else {
        schedules.unshift({
          Schedule,
          Event,
          Place,
          PriceValues,
          PriceValue,
          PriceCategory,
          items: [item],
        });
      }
      if (!item.is_reserved && this.cart_items) {
        this.cart_items.filter(_item => {
          if (_item.schedule && item.schedule && _item.schedule.uuid === item.schedule.uuid) {
            _item.is_reserved = false;
          }
        });
      }
      this.cart_items_storage[key] = cartItem;
    });
    this.cart_schedules_storage = schedules;
    this.calculate();
    this.saveToLocalStorage();
    this.cartUpdateSchedules$.next();
  }

  public getEventItems(event_id, values = false): ICartItem[] | any {
    const schedulesStorage = this.cart_schedules_storage;
    let cart_items: ICartItem[] = [];
    this.event_total_cost = 0;
    let _quantity = 0;
    if (schedulesStorage && schedulesStorage.length) {
      const schedules = schedulesStorage.filter(s => {
        return s.Schedule.uuid === event_id;
      });

      if (schedules && schedules.length) {
        schedules.map(s => {
          if (s.items && s.items.length) {
            const _items: ICartItem[] = s.items.map(_item => {
              const basePrice: IPriceValue = _item.meta._helperBasePrice;
              const price: number =
                (basePrice || {} as IPriceValue).price ||
                _item.price ||
                _item.PriceValue.amount;
              this.event_total_cost += price * _item.quantity;
              _quantity += _item.quantity;

              return {..._item, price};
            });
            cart_items = cart_items.concat(s.items);
          }
        });
      }
    }

    if (values) {
      return {
        cart_items,
        quantity: _quantity,
        price: {
          ...(cart_items[0] ? cart_items[0].PriceValue : null),
          amount: this.event_total_cost,
        },
      };
    }
    return cart_items;
  }

  public toggleCertNominalMessage() {
    if (this.certificate) {
      const certValue = parseInt(this.certificate.PriceModifier.value, 10);

      if (this.prev_total_cost > certValue && certValue >= this.total_cost) {
        this.translate
          .get('SUCCESS')
          .toPromise()
          .then(success => {
            this.info.showSuccess(success['GIFT_INSUFFICIENT_FUNDS']());
          });
      }
    }
  }

  public getCurrency(): TCurrency {
    if (this.cart_currency) {
      return this.cart_currency;
    } else if (this.cart_items && this.cart_items[0] && this.cart_items[0].currency) {
      return this.cart_items[0].currency;
    } else if (this.cart_items_storage && this.cart_items_order && this.cart_items_order[0]) {
      return this.cart_items_storage[this.cart_items_order[0]].PriceValue.amount_currency;
    }
    return 'rub';
  }

  public getUnreservedItems(): ICartItem[] {
    if (this.srcServerCart && this.srcServerCart.items) {
      return this.srcServerCart.items.filter(item => !item.is_reserved);
    } else {
      return [];
    }
  }

  public getBasePrice(prices: IPriceValue[], price_values?: string[]): IPriceValue {
    let _prices = [...prices];
    if (price_values) {
      _prices = _prices.filter(p => price_values.indexOf(p.uuid) >= 0);
    }
    return _prices.find(p => !!p.is_base_price);
  }

  public getCartItemBasePrice(item: ICartItem): number {
    const basePrice: IPriceValue = item.meta._helperBasePrice || {};

    return basePrice.price || item.nominal_price || item.price || item.PriceValue.amount;
  }
}
