import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, ObservableInput, Subscription } from 'rxjs';
import { IMediaInfo, IProductTicket } from '../../../types/Entities';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { propOr, prop, is } from 'ramda';
import { CartService } from '../../../services/cart.service';
import { IGeometryPlaceView } from '../../../types/ISchema';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeAnimationHide, fadeAnimationShow } from '../../../animations';
import { ResizedEvent } from 'angular-resize-event';

export interface IHidePopupOptions {
  transition?: boolean;
  emitExpanded?: boolean;
}

@Component({
  selector: 'app-hall-schema-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.less'],
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('fadeTrigger', [
      transition(':enter', [
        useAnimation(fadeAnimationShow, { params: { timings: '400ms ease-in-out' } }),
      ]),
      transition(':leave', [
        useAnimation(fadeAnimationHide, { params: { timings: '400ms ease-in-out' } }),
      ]),
    ]),
    trigger('imageTrigger', [
      transition(':enter', [
        useAnimation(fadeAnimationShow, { params: { timings: '200ms ease-in-out' } }),
      ]),
      transition(':leave', [
        useAnimation(fadeAnimationHide, { params: { timings: '200ms ease-in-out' } }),
      ]),
    ]),
  ],
})
export class HallSchemaPopupComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions = Array<Subscription>();
  private hideTimer = null;
  public hasPlaceView = false;
  public placeView: any;
  public seat: string;
  public row: string;
  public fragment: string;
  public sector: string;
  public price: any;
  public priceCategoryName: any;
  private hideTime: number;
  public isDesktop: boolean;
  private ticket: any;
  public place_image: IMediaInfo;
  private mouseMoveListener;
  public show = false;
  public expandedImage = false;
  public showImage = false;
  public placeImageClicked = false;
  private setPositionTimer;
  public imageWidth = 190;
  public imageHeight = 126;
  public imageWidthExpanded = this.imageWidth * 2;
  public imageHeightExpanded = this.imageHeight * 2;
  private placeViewTransitionEnd = true;

  @Input('offset') parentOffset: { x: number; y: number };
  @Input('onHover') hover$: Observable<any>;
  @Input('onClick') click$: Observable<any>;
  @Input('onUnhover') unHover$: Observable<any>;
  @Input('onToucMove') toucMove$: Observable<any>;
  @Input('onPositionChange') positionChange$: Observable<any>;
  @Input('hidePopup') hidePopup$: Observable<IHidePopupOptions>;
  @Input('show_price_categories') show_price_categories_names: boolean | void;
  @Input() place_view: IGeometryPlaceView;
  @Output() toggleExpandImage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickPlaceView: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('mobInfo', { static: false }) mobInfoRef: ElementRef;
  @ViewChild('placeView', { static: false }) placeViewRef: ElementRef;
  @ViewChild('placeInfoDiv', { static: false }) placeInfoRef: ElementRef;

  @HostBinding('attr.class') class = 'schema-popup';

  @HostBinding('class.schema-popup_top-2') get classTop2() {
    return this.cartService.certificate;
  }

  constructor(
    private deviceService: DeviceDetectorService,
    private translateService: TranslateService,
    private _elemRef: ElementRef,
    private cartService: CartService,
    private renderer: Renderer2
  ) {
    this.isDesktop = this.deviceService.isDesktop();
    this.hideTime = this.isDesktop ? 5000 : 1800;
  }

  ngOnInit() {
    this.hideRefs();
    this.subscriptions.push(
      this.cartService.removeFromCart$.subscribe(cartItem => {
        if (
          this.ticket &&
          cartItem.ProductItem &&
          cartItem.ProductItem.uuid === this.ticket.placeData.ticket.uuid
        ) {
          this.hideRefs().then(() => {
            this.showImage = false;
            this.placeImageClicked = false;
            this.ticket = null;
            if (this.expandedImage) {
              this.expandedImage = false;
              this.toggleExpandImage.emit(false);
            }
            if (this.place_image) {
              this.place_image = null;
              this.clickPlaceView.emit(null);
            }
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnChanges(changes) {
    if (changes['click$'] && this.click$) {
      this.subscriptions.push(
        this.click$.subscribe(td => {
          this.display(td).then(() => {
            this.placeImageClicked = !!this.place_image;
            if (this.placeImageClicked) {
              this.clickPlaceView.emit(td);
            } else {
              this.clickPlaceView.emit(null);
            }
          });
        })
      );
    }
    if (changes['hover$'] && this.hover$) {
      this.subscriptions.push(
        this.hover$.subscribe(td => {
          if (this.placeImageClicked) {
            return;
          }
          this.display(td);
        })
      );
    }
    if (changes['unHover$'] && this.unHover$) {
      this.subscriptions.push(
        this.unHover$.subscribe(() => {
          if (this.placeImageClicked) {
            return;
          }
          this.hideRefs(false);
        })
      );
    }
    if (changes.positionChange$ && this.positionChange$) {
      this.subscriptions.push(
        this.positionChange$.subscribe(() => {
          this.hideRefs();
          if (this.expandedImage) {
            this.toggleExpandImage.emit(false);
            this.expandedImage = false;
          }
          if (this.place_image) {
            this.clickPlaceView.emit(null);
            this.place_image = null;
          }
          this.ticket = null;
          this.placeImageClicked = false;
        })
      );
    }
    if (changes.hidePopup$ && this.hidePopup$) {
      this.subscriptions.push(
        this.hidePopup$.subscribe((opt: IHidePopupOptions) => {
          const _opt = { transition: true, emitExpanded: true, ...(opt || {}) };
          this.hideRefs(_opt.transition);
          if (this.expandedImage) {
            this.expandedImage = false;
            if (_opt.emitExpanded) {
              this.toggleExpandImage.emit(false);
            }
          }
          this.ticket = null;
          this.placeImageClicked = false;
        })
      );
    }
  }

  private display(t: any): Promise<any> {
    return new Promise<any>(resolve => {
      if (
        this.expandedImage ||
        (this.ticket &&
          this.ticket.placeData.ticket.uuid === t.placeData.ticket.uuid &&
          t.state === this.ticket.state)
      ) {
        resolve();
        return;
      }
      this.setValues(t);
      const showHide = () => {

        console.log('SOMETHING WITH T', t);

        this.ticket = t;
        if (t.state) {
          if (this.place_image && this.placeViewRef) {
            this.placeViewRef.nativeElement.style.display = 'block';
          }
          this.setPosition().then(() => {
            this.showRefs();
            clearTimeout(this.hideTimer);
            if (!this.place_image) {
              this.hideTimer = setTimeout(() => {
                this.hideRefs();
              }, this.hideTime);
            }
          });
        }
        this.showImage = !!this.place_image;
        resolve();
      };
      this.hideRefs(false).then(() => {
        showHide();
      });
    });
  }

  private setValues(t) {
    if (t.state) {
      const lang = this.translateService.currentLang;
      const { Place } = t.placeData.ticket;
      const meta = Place.meta;

      this.seat = this.getTranslated(lang, t.placeData.ticket.Place.name);
      this.row = this.getTranslated(lang, meta.row.name);

      const frag = this.getTranslated(lang, meta.fragment.name);
      this.sector = this.getTranslated(lang, prop('name', meta.sector));
      this.place_image = null;
      if (this.place_view) {
        if (this.place_view.place_map && this.place_view.place_map[Place.uuid]) {
          this.place_image = this.place_view.place_views[
            this.place_view.place_map[Place.uuid][0]
          ] as IMediaInfo;
        }
      }
      if (!this.sector) {
        this.sector = frag;
      }

      if (frag && frag !== this.sector) {
        this.fragment = frag;
      } else {
        this.fragment = undefined;
      }
      this.price = this.cartService.getBasePrice(t.placeData.priceValues);
      this.priceCategoryName = this.price.PriceCategory.name;
    } else {
      this.place_image = null;
    }
  }

  private setPosition(): Promise<any> {
    return new Promise<any>(resolve => {
      const t = this.ticket;
      if (t) {
        const el_bb = this._elemRef.nativeElement.getBoundingClientRect();
        const parent_container_bb = this._elemRef.nativeElement.parentNode.getBoundingClientRect();

        console.log('SETTING POSITION LIKE THIS:', el_bb, parent_container_bb, t.bb);

        const tX = t.bb.left;
        const tY = t.bb.top;
        const gap = t.bb.height;
        let x = tX + t.bb.width / 2 - el_bb.width / 2;
        let y = tY - el_bb.height - gap;

        if (this.expandedImage && this.isDesktop) {
          const $placeInfo = this.placeInfoRef.nativeElement;
          y = tY - ($placeInfo.clientHeight + this.imageHeightExpanded) - gap;
        }

        if (x + el_bb.width > parent_container_bb.left + parent_container_bb.width) {
          x = parent_container_bb.left + parent_container_bb.width - el_bb.width;
        }
        if (x < parent_container_bb.left) {
          x = parent_container_bb.left;
        }
        if (y < 0) {
          y = tY + t.bb.height + gap;
        }
        this._elemRef.nativeElement.style.left = x + 'px';
        this._elemRef.nativeElement.style.top = y + 'px';
        if (!this.isDesktop) {
          const $mobInfo = this.mobInfoRef.nativeElement;
          $mobInfo.style.top = `${parent_container_bb.height +
            parent_container_bb.top -
            $mobInfo.clientHeight -
            8}px`;
        }
        resolve();
      }
    });
  }

  public onResized(e: ResizedEvent) {
    if (!this.expandedImage && this.placeViewTransitionEnd) {
      this.setPosition();
    }
  }

  private showRefs() {
    const $el = this._elemRef.nativeElement;
    if (!$el.classList.contains('transition')) {
      $el.classList.add('transition');
    }
    $el.classList.add('active');
    this.show = true;
    if (this.mobInfoRef) {
      if (!this.mobInfoRef.nativeElement.classList.contains('transition')) {
        this.mobInfoRef.nativeElement.classList.add('transition');
      }
      this.mobInfoRef.nativeElement.classList.add('active');
    }
  }

  private hideRefs(trans = true): Promise<any> {
    clearTimeout(this.hideTimer);
    return new Promise<any>(resolve => {
      const $el = this._elemRef.nativeElement;
      if (
        !$el.classList.contains('active') &&
        !(this.mobInfoRef ? this.mobInfoRef.nativeElement.classList.contains('active') : false)
      ) {
        resolve();
        return;
      }
      if (this.placeViewRef) {
        this.placeViewRef.nativeElement.style.display = 'block';
      }
      $el.classList.remove('transition');
      $el.classList.remove('transition-out');
      if (this.mobInfoRef) {
        this.mobInfoRef.nativeElement.classList.remove('transition');
      }
      const onTransitionEnd = () => {
        $el.removeEventListener('transitionend', onTransitionEnd);
        $el.classList.remove('transition-out');
        if (this.mobInfoRef) {
          this.mobInfoRef.nativeElement.classList.remove('transition');
        }
        resolve();
      };
      if (trans && !$el.classList.contains('transition-out')) {
        $el.classList.add('transition-out');
        $el.addEventListener('transitionend', onTransitionEnd);
      }
      $el.classList.remove('active');
      if (this.mobInfoRef) {
        const $mob = this.mobInfoRef.nativeElement;
        if (trans && !$mob.classList.contains('transition')) {
          $mob.classList.add('transition');
        }
        $mob.classList.remove('active');
      }
      if (!trans && this.placeViewRef) {
        this.placeViewRef.nativeElement.style.display = 'none';
      }
      if (!trans) {
        resolve();
      }
    });
  }

  private checkPositionValidity(): boolean {
    const $placeInfo = this.placeInfoRef.nativeElement;
    const el_bb = this._elemRef.nativeElement.getBoundingClientRect();
    const placeInfo_bb = $placeInfo.getBoundingClientRect();
    const parent_container_bb = this._elemRef.nativeElement.parentNode.getBoundingClientRect();
    const sizeX = this.imageWidthExpanded;
    const sizeY = placeInfo_bb.height + this.imageHeightExpanded;
    return !(
      el_bb.x < 0 ||
      el_bb.x + sizeX > window.innerWidth ||
      el_bb.y < 0 ||
      el_bb.y + sizeY > parent_container_bb.top + parent_container_bb.height
    );
  }

  public toggleImagePopup(e) {
    e.stopPropagation();
    this.toggleExpandImage.emit(!this.expandedImage);
    this.expandedImage = !this.expandedImage;
    if (this.isDesktop && this.expandedImage) {
      if (!this.checkPositionValidity()) {
        this.setPosition();
      }
    }
    if (this.isDesktop && this.placeViewRef) {
      this.placeViewTransitionEnd = false;
      const onTransitionEnd = () => {
        this.placeViewTransitionEnd = true;
        this.placeViewRef.nativeElement.removeEventListener('transitionend', onTransitionEnd);
      };
      this.placeViewRef.nativeElement.addEventListener('transitionend', onTransitionEnd);
    }
  }

  public hideImage(e) {
    e.stopPropagation();
    this.clickPlaceView.emit(null);
    this.placeImageClicked = false;
    this.hideRefs().then(() => {
      this.showImage = false;
      if (this.expandedImage) {
        this.expandedImage = false;
        this.toggleExpandImage.emit(false);
      }
    });
  }

  collapseImage(e) {
    e.stopPropagation();
    this.expandedImage = false;
    this.toggleExpandImage.emit(false);
  }

  private getTranslated(lang: string, k: any) {
    if (is(Object, k)) {
      return propOr(k['ru'], lang, k);
    } else {
      return k;
    }
  }
}
