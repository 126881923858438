import { GlobalService } from '../services/global.service';
import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

export interface IBackground {
  src: string;
  theme?: string;
  target?: 'body' | 'wrap';
}

@Directive({
  selector: '[appBackground]',
})
export class BackgroundDirective implements OnInit, OnChanges, OnDestroy {
  @Input() appBackground: IBackground;

  constructor(public global: GlobalService) {}

  ngOnInit(): void {
    if (this.appBackground) {
      this.global.background$.next(this.appBackground);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appBackground && !changes.appBackground.firstChange) {
      this.global.background$.next(changes.appBackground.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.global.background$.next(null);
  }
}
