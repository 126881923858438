import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { TranslateComponent } from '../../components/translate/translate.component';
import { ControlMessagesComponent } from '../../components/control-messages/control-messages.component';
import { InfiniteScrollComponent } from '../../components/infinite-scroll/infinite-scroll.component';
import { InfoComponent } from '../../components/info/info.component';
import { ConfirmPopupComponent } from '../../components/confirm-popup/confirm-popup.component';
import { CalendarLentaComponent } from '../../components/calendar-lenta/calendar-lenta.component';
import { DocComponent } from '../../components/doc/doc.component';
import { InfoPopupComponent } from '../../components/info-popup/info-popup.component';
import { FocusInputDirective } from '../../directives/focus-input.directive';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { ScrollComponent } from '../../components/scroll/scroll.component';
import { NativeElementControlDirective } from '../../directives/native-element-control.directive';
import { CalendarLentaItemComponent } from '../../components/calendar-lenta/calendar-lenta-item/calendar-lenta-item.component';
import { MinpricePipe } from '../../pipes/minprice/minprice.pipe';
import { TabsComponent } from '../../components/tabs/tabs.component';
import { TabsTabComponent } from '../../components/tabs/tabs-tab/tabs-tab.component';
import { SessionsComponent } from '../../components/sessions/sessions.component';
import { NavWrapperComponent } from '../../components/nav-wrapper/nav-wrapper.component';
import { GlobalService } from '../../services/global.service';
import { InfoService } from '../../services/info.service';
import { httpInterceptorProviders } from '../../http-interceptors';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { LoggerService } from '../../services/logger.service';
import { StorageService } from '../../services/storage.service';
import { TransportService } from '../../services/transport.service';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from '../../components/loader/loader.component';
import { MultilangFieldPipe } from '../../pipes/multilang/multilang_field.pipe';
import { MandatetimePipe } from '../../pipes/mandatetime/mandatetime.pipe';
import { DatecustomPipe } from '../../pipes/datecustom/datecustom.pipe';
import { DurationPipe } from '../../pipes/duration/duration.pipe';
import { MoneyPipe } from '../../pipes/money/money.pipe';
import { NumerativePipe } from '../../pipes/numerative/numerative.pipe';
import { RatePipe } from '../../pipes/rate/rate.pipe';
import { PlusMinusComponent } from '../../components/plus-minus/plus-minus.component';
import { PlusMinusListComponent } from '../../components/plus-minus-list/plus-minus-list.component';
import { EmptyTicketsComponent } from '../../components/empty-tickets/empty-tickets.component';
import { PaymentMethodFilterPipe } from '../../components/payment_method/payment_method_filter.pipe';
import { ModeofoperationPipe } from '../../pipes/modeofoperation/modeofoperation.pipe';
import { PlaceMetaPipe } from '../../place-meta.pipe';
import { BackgroundDirective } from '../../directives/background.directive';
import { LDatePipe } from '../../pipes/l-date/l-date.pipe';
import { MaxInputValueDirective } from '../../validators/value-directives/max-input-value.directive';
import { EqualValidatorDirective } from '../../validators/directives/equal-validator.directive';
import { PhoneInputValueDirective } from '../../validators/value-directives/phone-input-value.directive';
import { AlertComponent } from '../../components/alert/alert.component';
import {MetricService} from '../../services/metric.service';
import {PlatformService} from '../../services/platform.service';
import {WarningPopupComponent} from '../../components/warning-popup/warning-popup.component';

import {WarningCovidComponent} from '../../components/warning-covid/warning-covid.component';
import { WarningPopupService } from 'src/app/services/warning-popup.service';
import { ExcursionPopupService } from 'src/app/services/excursion-popup.service';
import { ExcursionPopupComponent } from 'src/app/components/excursion-popup/excursion-popup.component';
import {SelectComponent} from '../../components/select/select.component';
import { CartItemPricePipe } from '../../pipes/cart-item-price.pipe';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

const declarations = [
  LoaderComponent,
  TranslateComponent,
  ControlMessagesComponent,
  InfiniteScrollComponent,
  InfoComponent,
  ConfirmPopupComponent,
  PlusMinusListComponent,
  DocComponent,
  InfoPopupComponent,
  FocusInputDirective,
  PlusMinusComponent,
  ClickOutsideDirective,
  ScrollComponent,
  AlertComponent,
  NativeElementControlDirective,
  EmptyTicketsComponent,
  MinpricePipe,
  TabsComponent,
  TabsTabComponent,
  SessionsComponent,
  NavWrapperComponent,
  MultilangFieldPipe,
  PaymentMethodFilterPipe,
  MandatetimePipe,
  DatecustomPipe,
  LDatePipe,
  DurationPipe,
  ModeofoperationPipe,
  MoneyPipe,
  NumerativePipe,
  RatePipe,
  FocusInputDirective,
  PlaceMetaPipe,
  SafeHtmlPipe,
  BackgroundDirective,
  MaxInputValueDirective,
  EqualValidatorDirective,
  PhoneInputValueDirective,
  WarningPopupComponent,
  WarningCovidComponent,
  ExcursionPopupComponent,
  SelectComponent,
  CartItemPricePipe
];

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  declarations,
  imports: [
    CommonModule,
    InfiniteScrollModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DeviceDetectorModule,
    AngularSvgIconModule,
  ],
  exports: [...declarations],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        ...httpInterceptorProviders,
        GlobalService,
        InfoService,
        httpInterceptorProviders,
        {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG,
        },
        {
          provide: MESSAGE_FORMAT_CONFIG,
          useValue: {
            locales: ['ru', 'en', 'hy'],
            biDiSupport: true,
          },
        },
        // {
        //   provide: CalendarDateFormatter, useClass: class CustomDateFormatter extends CalendarDateFormatter {
        //     public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
        //       return new Intl.DateTimeFormat(locale, {weekday: 'short'}).format(date);
        //     }
        //   }
        // },
        LoggerService,
        TranslateService,
        TranslateStore,
        StorageService,
        TransportService,
        MoneyPipe,
        SafeHtmlPipe,
        MetricService,
        PlatformService,
        WarningPopupService,
        ExcursionPopupService
      ],
    };
  }
}
