import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IMultiLangField} from '../../types/Entities';
import { from, Subject, Subscription } from 'rxjs';
import { first, flatMap, pluck, takeUntil } from 'rxjs/operators';
import {GlobalService} from '../../services/global.service';
import { ExcursionPopupService, IExcursionPopupShowHide } from 'src/app/services/excursion-popup.service';
import { Observable } from 'rxjs/index';
import { map } from 'rxjs/internal/operators';

@Component({
  selector: 'app-excursion-popup',
  templateUrl: './excursion-popup.component.html',
  styleUrls: ['./excursion-popup.component.less']
})
export class ExcursionPopupComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() srcHtml: string;

  public popupData: IExcursionPopupShowHide;
  public content: any[];
  private destroy$ = new Subject();

  private currentPosition = 0;

  private dataStream: Observable<any>;
  private dataSub: Subscription;

  private dataHeader;
  private dataText;

  closeLabel = 'Закрыть';

  constructor(
    public service: ExcursionPopupService,
    public global: GlobalService
  ) {
  }

  ngOnInit() {

    this.service.showHide$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {




        if (data) {
          this.content = data.info;

          this.currentPosition = 0;

          if (this.content.length > 0) {
            this.dataHeader = this.content[this.currentPosition].title;
            this.dataText = this.content[this.currentPosition].text.ru.replace(/<a[^>]*>/g, '');

            if (data.info.length > 1) {
              this.closeLabel = 'Далее';
            }
          }



        //   this.dataStream = from(data.info);
        //
        //   this.dataSub = this.dataStream.pipe(
        //     first(),
        //     map((el) => {
        //       console.log('NUMNUM', el)
        //       this.dataHeader = el.title;
        //       this.dataText = el.text;
        //     })
        //   ).subscribe();
        }


        // console.log('HERE IS POPUP DATA', this.content);
        //
        if (data && data.id === this.id) {
          this.popupData = data;
        }
        this.global.toggleBodyOverflow(!(data && data.show));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  nextMessage(): void {

    // ВОТ ЭТО БЫЛО В ОРИГИНАЛЕ
    // this.service.hide({id: 'excursion', info: []});
    ++this.currentPosition;
    if (this.currentPosition === this.content.length) {

      this.service.hide({id: 'excursion', info: []});

    } else {
      this.dataHeader = this.content[this.currentPosition].title;
      this.dataText = this.content[this.currentPosition].text.ru.replace('<a ', '<a class="ahref" ');
      if (this.currentPosition === this.content.length - 1) {
        this.closeLabel = 'Закрыть';
      }
    }
  }
}
