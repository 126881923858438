import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger.service';
import { CartService } from '../../services/cart.service';
import {WarningPopupService} from '../../services/warning-popup.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-gift-activate',
  templateUrl: './gift-activate.component.html',
  styleUrls: ['./gift-activate.component.less'],
})
export class GiftActivateComponent implements OnInit, AfterViewInit {
  @ViewChild('certInputRef', { static: false }) certInputRef: ElementRef;

  logoImage = '';
  certificate = '';
  viewStap = 'inp'; // result
  certificateForm: any;

  // validity_range: any = ['2019-02-01 00:00:00'];
  validity_range: any = [];

  constructor(
    private transport: TransportService,
    public global: GlobalService,
    public cart: CartService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private logger: LoggerService,
    private warningPopupService: WarningPopupService
  ) {
    this.certificateForm = this.formBuilder.group({
      // 'test': ['', [Validators.required, ValidationService.numberValidator]],
      certificate: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.logoImage = this.global.logoImage;

    this.route.queryParams.subscribe(params => {
      if (params.number) {
        this.certificateForm.patchValue({
          certificate: params.number,
        });
      }
    });
  }

  ngAfterViewInit() {
    // this.certInputRef.nativeElement.focus();
  }

  onActivate() {
    // if (this.global.settings.widget_settings.showCovidMessages) {
    //   this.warningPopupService.show({id: 'covid'});
    //   const sub: Subscription = this.warningPopupService.showHide$.subscribe(data => {
    //     if (data && !data.show) {
    //       console.log('sub');
    //       sub.unsubscribe();
    //     }
    //   });
    //   return;
    // }
    this.activate();
  }

  activate() {
    if (!this.certificateForm.value.certificate) {
      return;
    }
    this.global.showHideLoader.next(true);
    this.logger.l(this.certificateForm.value.certificate);

    this.transport.certificateActivate(this.certificateForm.value.certificate).subscribe(
      success => {
        this.cart.reloadCart();
        this.logger.l('Successfully Completed');
        this.logger.l(success);
        // this.global.activeSertificate = success;
        // sessionStorage.setItem('activeSertificate', JSON.stringify(this.global.activeSertificate));
        // this.global.activeSertificate.validity_range.replace(/[)(\]["]/g, '')
        //   .split(/\s*,\s*/)
        //   .map((x) => this.validity_range.push(x));

        // this.validity_range = success.validity_range.replace(/[)(\]["]/g, '').split(/\s*,\s*/);
        this.validity_range = success.validity_range;
        // .map((x) => this.validity_range.push(x));

        this.viewStap = 'result';
        this.global.showHideLoader.next(false);
      },
      error => {
        // this.cart.reloadCart();
        this.logger.l('Error Completed');
        this.logger.l(error);
        this.global.showHideLoader.next(false);

        if (error.message && error.message === 'BAD_CERTIFICATE') {
          this.global.showHideInfoPopup$.next({ id: 'isActivatedPopup', show: true });
        }
      }
    );
  }
}
