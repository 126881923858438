import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  setItem(key, val, local = false) {
    if (local) {
      this[key] = val;
      return;
    }
    try {
      localStorage.setItem(key, val);
    } catch (e) {
      this[key] = val;
    }
  }

  removeItem(key, local = false) {
    if (local) {
      delete this[key];
      return;
    }
    try {
      localStorage.removeItem(key);
    } catch (e) {
      delete this[key];
    }
  }

  getItem(key, local = false) {
    if (local) {
      return this[key];
    }
    let val;
    try {
      val = localStorage.getItem(key);
    } catch (e) {
      val = this[key];
    }
    return val;
  }


}
