import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { propOr, is } from 'ramda';
import { GlobalService } from "../../services/global.service";

@Pipe({
  name: 'multilang',
  pure: false,
})
export class MultilangFieldPipe implements PipeTransform {
  constructor(public translate: TranslateService, public global: GlobalService) {}

  transform(k: any): string {
    const lang = this.translate.currentLang;
    if (is(Object, k)) {
      const val = k[lang];
      // return propOr(k['ru'], lang, k);
      return val && typeof val === 'string' && val.length > 0 ?
        val : k[this.global.defaultLang] || k[this.global.initDefaultLang];
    } else {
      return k;
    }
  }
}
