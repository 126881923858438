import { Component, Input, OnInit } from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {IWidgetSettings} from '../../types/Entities';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() pos: string;
  @Input() theme = '1';
  public settings: IWidgetSettings;
  constructor(
    public global: GlobalService
  ) {}

  ngOnInit() {
    this.global.settings$.subscribe(s => {
      this.settings = s.widget_settings;
    });
  }
}
