import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';

interface IFnData {
  id: any;
  show: boolean;
}

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.less'],
})
export class InfoPopupComponent implements OnInit, OnDestroy {
  @Input('id') id;
  @Input('title') title;
  @Input('text') text;
  @Input() mode: string;
  public show = false;
  private subscription: Subscription;

  constructor(public global: GlobalService) {}

  ngOnInit() {
    this.subscription = this.global.showHideInfoPopup$.subscribe((data: IFnData) => {
      if (data.id !== this.id) return;

      this.show = data.show;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
