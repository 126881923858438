import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';
import { prop, isNotNil } from 'ramda';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-hall-schema-container',
  template: `
    <app-hall-schema
      [geometry]="geometry"
      [tickets_data]="ticketsData"
      [onSelect]="selectSubject"
      [schedule]="schedule"
      [selected-tickets]="selectedPlaces"
      [parentSetSize$]="setSize$"
      style="position:relative">
    </app-hall-schema>

    <app-hall-schema-sidebar [schedule]="schedule"></app-hall-schema-sidebar>
  `,
  styleUrls: ['./hall_schema_container.component.less'],
})
export class HallSchemaContainerComponent implements OnInit, OnChanges {
  public schedule: any;
  public ticketsData: any;
  public geometry: any;
  public selectSubject: Subject<any>;
  public selectedPlaces: any;
  public setSizeSource = new ReplaySubject<boolean>();
  public setSize$ = this.setSizeSource.asObservable();

  @Input() headerSize: number = 0;

  constructor(
    private api: TransportService,
    private _elemRef: ElementRef,
    public global: GlobalService,
    private cartService: CartService
  ) {}

  @Input('onSelect')
  set setSelectSubject(cb: Subject<any>) {
    this.selectSubject = cb;
  }

  @Input('schedule')
  set setSchedule(maybeSchedule: any) {
    if (!maybeSchedule) {
      return;
    }
    if (prop('uuid', maybeSchedule)) {
      this.schedule = maybeSchedule;

      this.loadData();
    } else {
      this.api.getSchedule(maybeSchedule).subscribe(schedule => {
        this.schedule = schedule;
        this.global.mapInfo.next(schedule);
        this.loadData();
      });
    }
  }

  private loadData() {
    this.global.showHideLoader.next(true);
    combineLatest([
      this.api.getGeometry(this.schedule.hall_geometry_uuid),
      this.api.getTickets(this.schedule.uuid),
    ])
      .pipe(
        finalize(() => {
          this.global.showHideLoader.next(false);
        })
      )
      .subscribe(([geometry, ticketsData]) => {
        this.geometry = geometry;
        this.ticketsData = ticketsData;
      });
  }

  ngOnInit() {
    this.setSize();
    // this.selectedPlaces = this.cartService.cart_items.filter((el) => isNotNil(el.ProductItem.Place)).map((el) => el.ProductItem.Place);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.headerSize) {
      this.setSize();
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(ev) {
    this.setSize(true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(ev) {
    this.setSize();
  }

  setSize(isOrient = false) {
    this._elemRef.nativeElement.style.height = `${window.innerHeight - this.headerSize}px`;
    this._elemRef.nativeElement.style.top = `${this.headerSize}px`;
    this.setSizeSource.next(isOrient);
  }
}
