import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertsMiniService {

  private showAlert = false;

  constructor() { }

  public switchAlert(): void {
    this.showAlert = !this.showAlert;
  }
  public getAlertState(): boolean {
    return this.showAlert;
  }
}
