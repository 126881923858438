import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'promocode-popup',
  templateUrl: './promocode-popup.component.html',
  styleUrls: ['./promocode-popup.component.less'],
})
export class PromocodePopupComponent implements OnInit, AfterViewInit {
  @ViewChild('promocode', { static: false }) promocodeInput: ElementRef;

  @Input() promocode: string;
  @Output() success: EventEmitter<string> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  values: Array<string>;
  form = new FormGroup({});

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    this.promocodeInput.nativeElement.focus();
  }

  initForm() {
    this.form = this.fb.group({
      PromoCode: new FormControl('', Validators.required),
    });
    this.form.patchValue({ PromoCode: this.promocode });
  }
  onConfirmClick() {
    this.promocode = this.form.get('PromoCode').value;
    this.success.emit(this.promocode);
  }

  onCancelClick() {
    this.cancel.emit();
  }

  onOverlayClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.onCancelClick();
  }
}
